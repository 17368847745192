<template>
  <div>
    <a-card title="账户列表管理" style="margin: 20px">
      <div class="Walletbox">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">所属机构</div>
            <!-- 所属机构 -->
            <DepartmentChoose :department-code.sync="form.departmentCode"
                              @change="getcustaccountPage"></DepartmentChoose>
          </div>
          <!-- 客户编号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">客户编号</div>-->
          <!--            <a-input v-model.trim="form.custnum" type="number" placeholder="请输入客户编号" />-->
          <!--          </div>-->

          <!-- 账号 -->
          <div class="inputbox">
            <div class="lefttitle">钱包编号</div>
            <a-input type="number" controls="false" v-model.trim="form.accountNo" placeholder="请输入客户账号"/>
          </div>
          <!-- 客户名称 -->
          <div class="inputbox">
            <div class="lefttitle">客户名称</div>
            <a-input v-model.trim="form.accountName" placeholder="请输入客户名称"/>
          </div>
          <!-- 客户类型 -->
          <div class="inputbox">
            <div class="lefttitle">客户类型</div>
            <a-select v-model.trim="form.accountProperty" placeholder="请选择客户类型" style="width:200px"
                      :allowClear="true">
              <a-select-option value="1">个人</a-select-option>
              <a-select-option value="2">企业</a-select-option>
            </a-select>
          </div>
          <!-- 客户证件号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">证件号</div>-->
          <!--            <a-input v-model.trim="form.certnum" placeholder="请输入证件号" />-->
          <!--          </div>-->
          <!--          &lt;!&ndash; 户名 &ndash;&gt;-->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">户名</div>-->
          <!--            <a-input placeholder="请输入户名" v-model.trim="form.accountName" />-->
          <!--          </div>-->
          <!-- 渠道编号 -->
          <div class="inputbox">
            <div class="lefttitle">渠道</div>
            <a-select v-model.trim="form.channelNo" placeholder="请选择渠道" style="width:200px" :allowClear="true">
              <a-select-option v-for="(item,index) in channelList" :key="index" :value="item.channelNo">{{
                  item.channelName
                }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 应用编号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">应用编号</div>-->
          <!--            <a-input type="number" v-model.trim="form.appNo" placeholder="请输入应用编号" />-->
          <!--          </div>-->
          <!-- 手机号 -->
          <div class="inputbox">
            <div class="lefttitle">手机号</div>
            <a-input type="number" v-model.trim="form.mobile" placeholder="请输入手机号"/>
          </div>
          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <!-- 开户按钮区域 -->
        <div class="Controls">
          <a-button-group>
            <!-- 开户 -->
            <a-button @click="Openanaccount" icon="folder-open" class="inquire" type="normal"
                      v-if="$authority('openAnAccount')">开户
            </a-button>
            <a-button @click="openAccSubDailogClick" icon="folder-open" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('openAccSub')">开分户
            </a-button>
            <a-button @click="accCloseDailogClick" icon="close" class="inquire" type="danger"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('accClose')">销户
            </a-button>
            <a-button @click="editAccount" icon="edit" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('editAccountAuth')">变更
            </a-button>
            <a-button @click="bindBankCard" icon="credit-card" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('bindBankAuth')">绑卡
            </a-button>
            <a-button @click="openAccView" class="inquire" icon="eye" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('openAccView')">查看
            </a-button>

            <a-popover>
              <a-button @click="Loanapplication" class="inquire" icon="bank"
                        :disabled="!selectedRows?.accountNo"
                        v-if="$authority('loanApplicationF')">授信
              </a-button>
              <template #content>
                申请贷款
              </template>
            </a-popover>

          </a-button-group>
          <a-button @click="view()" icon="clock-circle" class="inquire" type="primary"
                    :disabled="!selectedRows?.accountNo"
                    v-if="$authority('Accountview')">交易明细
          </a-button>
          <a-popover>
            <template slot="content">
              余额同步
            </template>
            <a-button @click="Balancesynchronization" icon="sync" class="inquire" type="normal"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('balanceSynchronization')"></a-button>
          </a-popover>

          <a-button-group style="float:right">

            <withdraw-btn
                v-if="selectedRows?.a0"
                :app-no="selectedRows?.appNo"
                :channel-no="selectedRows?.channelNo"
                :account-no="selectedRows?.a0?.accountNo"
                @ok="Balancesynchronization">
              <a-button :disabled="!selectedRows?.accountNo">提现</a-button>
            </withdraw-btn>

            <guarantee-deposit-btn
                v-if="selectedRows?.a0"
                :account-no-a6="selectedRows?.a2?.accountNo"
                :account-no-a1="selectedRows?.a0?.accountNo"
                :app-no="selectedRows?.appNo"
                :channel-no="selectedRows?.channelNo"
                @ok="Balancesynchronization">
              <a-button :disabled="!selectedRows?.accountNo">保证金缴纳</a-button>
            </guarantee-deposit-btn>

            <guarantee-withdraw-btn
                v-if="selectedRows?.a2"
                :account-no-a6="selectedRows?.a2?.accountNo"
                :account-no-a1="selectedRows?.a0?.accountNo"
                :app-no="selectedRows?.appNo"
                :channel-no="selectedRows?.channelNo"
                @ok="Balancesynchronization">
              <a-button :disabled="!selectedRows?.a2">保证金释放</a-button>
            </guarantee-withdraw-btn>
            <a-button @click="paymentAuth" class="inquire"
                      :disabled="!selectedRows?.accountNo"
                      v-if="$authority('PaymentAuth')">小额打款认证
            </a-button>
          </a-button-group>

        </div>

        <!-- 表格 -->
        <!-- table 区域 -->

        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :scroll="{ x: 2100, }" :rowKey="(record, index) => `${record.id}`"
                     :row-selection="{
                selectedRowKeys: selectedRowKeys,
                hideDefaultSelections: false,
                onChange: onSelectChange,
              }" :columns="columns" @change="changeSorter" :customRow="clickrow" :data-source="data"
                     :pagination="false">
              <a-button slot="acct-detail" slot-scope="text, record" @click="view(record)" type="link">{{
                  text
                }}
              </a-button>

            </a-table>
          </div>
        </div>
        <!-- 分页部分 -->
        <div class="paginationBox">
          <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
            首页
          </a-button>
          <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
                        show-size-changer :page-size="form.pageSize" @change="pagechange"
                        @showSizeChange="onShowSizeChange"
                        :item-render="itemRender">

          </a-pagination>
          <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
                    class="Trailingpage">
            尾页
          </a-button>
        </div>
      </div>

    </a-card>

    <!-- 开户弹窗 -->
    <a-modal title="选择开户类型" :maskClosable="true" @cancel="Openanaccountvisible = false" :centered="true"
             :visible="Openanaccountvisible" :footer="null">
      <div class="Openanaccount">
        <!--个人开户  -->

        <div style="display: flex; justify-content: space-between; width: 100%; gap: 10px;">
          <div class="personage" style="flex: 1; text-align: center; cursor: pointer;"
               @click="Individualaccountopening">个人开户
          </div>
          <div class="personage" style="flex: 1; text-align: center; cursor: pointer;"
               @click="selfServiceAccountOpening(7)">个人自助
          </div>
        </div>


        <!-- 企业开户 -->
        <div style="display: flex; justify-content: space-between; width: 100%; gap: 10px;">
          <div class="personage" style="flex: 1; text-align: center; cursor: pointer;" @click="CorporateAccountOpening">企业开户</div>
          <div class="personage" style="flex: 1; text-align: center; cursor: pointer;"
               @click="selfServiceAccountOpening(8)">企业自助</div>

        </div>
        <div class="personage" v-if="$authority('innerAccOpen')" @click="innerAccountOpening">内部户开户</div>

      </div>
    </a-modal>

    <a-modal title="自助开户邀请" :maskClosable="true" @cancel="selfAccountVisible = false" :centered="true"
             :visible="selfAccountVisible" okText="生成二维码" cancelText="取消" @ok="selfAccountOpen">
      <a-form-model :model="selfForm" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="业务类型">
          {{ busiTypeName }}
        </a-form-model-item>
        <!-- 渠道编号 -->
        <a-form-model-item label="渠道">
          <a-select v-model="selfForm.channel_no" @change="channel_nochange" placeholder="请选择核心企业"
                    style="width: 100%">
            <a-select-option v-for="item in companylist" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="机构归属">
          <DepartmentChoose :department-id.sync="selfForm.departmentId" :department-name.sync="selfForm.departmentName"
                            :department-code.sync="selfForm.departmentCode"></DepartmentChoose>
        </a-form-model-item>
        <a-form-model-item label="有效期">
          <a-select v-model="selfForm.validDays">
            <a-select-option :value="1">1天</a-select-option>
            <a-select-option :value="3">3天</a-select-option>
            <a-select-option :value="7">7天</a-select-option>
            <a-select-option :value="14">14天</a-select-option>
          </a-select>
        </a-form-model-item>

      </a-form-model>
      <QRConfirm :url="qrConfirmUrl" :qr-type="qrType" :valid-through="qrValidThrough"
                 :display.sync=qrConfirmShow :busi_intro="busiTypeName"
                 :username="this.form.account_name"
                 @close="selfAccountVisible=false"></QRConfirm>
    </a-modal>


    <a-modal
        title="是否确定开分户"
        :visible="openAccSubDailog"
        :confirm-loading="false"
        @ok="openAccSub"
        @cancel="openAccSubCancel"
    >
      <a-descriptions bordered>
        <a-descriptions-item label="账户名称" :span="3">
          {{ selectedRows.accountName }}
        </a-descriptions-item>
        <a-descriptions-item label="账号" :span="3">
          {{ selectedRows.accountNo }}
        </a-descriptions-item>

      </a-descriptions>
    </a-modal>


    <!-- 选择贷款类型弹窗 -->
    <a-modal title="选择贷款类型" :maskClosable="true" @cancel="loanvisible = false" :centered="true"
             :visible="loanvisible"
             :footer="null">
      <div class="Openanaccount">
        <!--个人贷款  -->

        <div class="personage" @click="Personalloan">个人贷款</div>

        <!-- 企业贷款 -->
        <div class="firm" @click="Enterpriseloan">企业贷款</div>
      </div>
    </a-modal>
    <!-- 小额保证弹框  -->
    <PaymentAuthDialog v-if="visibleDailog" :selectedAccount="selectedRows" :type="'list'"
                       @OnPaymentChange="OnPaymentChange">
    </PaymentAuthDialog>
    <!-- 绑定银行卡组件 -->
    <BindBankCard :visible="visibleBindBankCard" @handleBind="handleBind"
                  :selectedAccount="selectedRows"></BindBankCard>
    <QRConfirm :url="cancelConfirmUrl" :display.sync="cancelConfirmShow" busi_intro="电子钱包注销"
               :username="selectedRows?.accountName"></QRConfirm>
  </div>
  <!-- 账户管理 -->
</template>
<script>
// import QRCode from 'qrcode'
import {handleChannelNo} from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import Balance from '@/view/components/Balance.vue'
import PaymentAuthDialog from '@/view/components/PaymentAuthDialog.vue'
import BindBankCard from '../Components/BindBankCard.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import QRConfirm from "@/view/components/QRConfirm.vue";
import GuaranteeDepositBtn from "@/view/components/GaranteeDepositBtn.vue";
import GuaranteeWithdrawBtn from "@/view/components/GaranteeWithdrawBtn.vue";
import WithdrawBtn from "@/view/components/WithdrawBtn.vue";
import moment from "moment";

export default {
  name: 'accountList',
  components: {
    WithdrawBtn,
    GuaranteeWithdrawBtn,
    GuaranteeDepositBtn,
    QRConfirm,
    DepartmentChoose,
    WalletInfo,
    DeptInfo,
    Balance,
    PaymentAuthDialog,
    BindBankCard
  },


  data() {
    return {
      qrType: "",
      qrConfirmUrl: "",
      qrConfirmShow: false,
      qrValidThrough: undefined,
      labelCol: {span: 6},
      wrapperCol: {span: 16},
      // 核心企业列表
      companylist: [],
      // 控制绑定银行卡弹框
      visibleBindBankCard: false,
      visibleDailog: false,
      openAccSubDailog: false,
      // 搜索表单数据
      loanvisible: false, // 贷款弹窗
      selectedRowKeys: [],
      selectedRows: {}, //
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      Countdown: 60,
      show: true,
      cancelConfirmShow: false,
      cancelConfirmUrl: undefined,
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
              `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
              Number(index + 1)
              }`,
          align: 'center',
          width: 60,
          fixed: 'left'
        },
        {
          title: '客户类型',
          dataIndex: 'accountProperty',
          align: 'center',
          ellipsis: true,
          width: 60,
          fixed: 'left',
          customRender: (text) => text === "1" ? "个人" : "企业"
        },
        {
          title: '账户名称',
          dataIndex: 'accountName',
          align: 'center',
          fixed: 'left',
          width: 260,
          scopedSlots: {customRender: 'acct-detail'}
        },
        {
          title: '活期',
          dataIndex: 'a0',
          align: 'center',
          sorter: true,
          width: 220,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (item) => {
            if (!item) {
              return <div></div>
            }

            return <div>
              <WalletInfo showAccountNo={false} accountName={item.accountName} accountNo={item.accountNo}
                          accountClass={item.accountClass} balance={item.balance} details={[]}></WalletInfo>
            </div>
          },
        },
        {
          title: '冻结',
          dataIndex: 'a6',
          align: 'left',
          sorter: true,
          width: 280,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (text, record,) => {

            if (!record.arrList) {
              return <div></div>;
            }

            const a6Accounts = record.arrList.filter(account => account.accountClass === '6');
            const a7Accounts = record.arrList.filter(account => account.accountClass === '8');

            return (
                <div>
                  {a6Accounts.length > 0 && (
                      <WalletInfo
                          showAccountNo={false}
                          accountName={a6Accounts[0].accountName}
                          accountNo={a6Accounts[0].accountNo}
                          accountClass={a6Accounts[0].accountClass}
                          balance={a6Accounts[0].balance}
                      />
                  )}
                  {a7Accounts.length > 0 && (
                      <a-popover title="分户信息" trigger="click">
                        <template slot="content">
                          <div style="max-height: 200px; max-width: 300px; overflow-y: auto;">
                            {a7Accounts.map((account, index) => (
                                <WalletInfo
                                    key={index}
                                    num={index + 1}
                                    showAccountNo={false}
                                    accountName={account.accountName}
                                    accountNo={account.accountNo}
                                    accountClass={account.accountClass}
                                    balance={account.balance}
                                />
                            ))}

                          </div>
                        </template>
                        <a-button type="link" icon="up-circle"></a-button>
                      </a-popover>
                  )}
                </div>
            );
          },
        },
        {
          title: '保证',
          dataIndex: 'a2',
          align: 'center',
          sorter: true,
          width: 220,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (item) => {
            if (!item) {
              return <div></div>
            }
            return <div>
              <WalletInfo showAccountNo={false} accountName={item.accountName} accountNo={item.accountNo}
                          accountClass={item.accountClass} balance={item.balance}></WalletInfo>
            </div>
          },
        },
        {
          title: '贷款',
          dataIndex: 'a7',
          align: 'center',
          sorter: true,
          width: 220,
          ellipsis: true,
          sortDirections: ['descend'],
          customRender: (item) => {
            if (!item) {
              return <div></div>
            }
            return <div>
              <WalletInfo showAccountNo={false} accountName={item.accountName} accountNo={item.accountNo}
                          accountClass={item.accountClass} balance={item.balance}></WalletInfo>
            </div>
          },
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          align: 'center',
          width: 120,
          customRender: (text) => {
            let starizedText = text.replace(/^(.{3}).+(.{4}$)/, "$1****$2")
            return <a-tooltip>
              <template slot="title"> {{text}} </template>
              {starizedText} </a-tooltip>
          }
        },
        {

          title: '证件号',
          dataIndex: 'certnum',
          align: 'center',
          width: 150,
          customRender: (text) => {
            if (!text) return text
            let starizedText = text.replace(/^(.{4}).+(.{4}$)/, "$1****$2")
            return <a-tooltip>
              <template slot="title"> {{text}} </template>
              {starizedText} </a-tooltip>
          }
        },
        {
          title: '状态',
          dataIndex: 'accountStatus',
          align: 'center',
          width: 60,
          customRender: (text) => {
            if (Number(text == 0)) {
              return '正常'
            } else if (Number(text == 1)) {
              return '删除'
            } else if (Number(text == 2)) {
              return '挂失'
            } else if (Number(text == 3)) {
              return '锁定'
            } else if (Number(text == 4)) {
              return '销户'
            } else if (Number(text == 5)) {
              return '不动户'
            } else if (Number(text == 6)) {
              return '睡眠户'
            } else if (Number(text == 7)) {
              return '止付'
            } else if (Number(text == 8)) {
              return '证件过期'
            } else {
              return text != null ? text : '---'
            }
          },
        },
        {
          title: '开户日期',
          dataIndex: 'createTime',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return text?.substring(0, 10) || ""
          },
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
        },
        {
          title: '机构',
          dataIndex: 'departmentId',
          align: 'center',
          ellipsis: true,
          customRender: (text) => {
            return <DeptInfo dept-id={text}/>
          },
        }
      ],
      // 表格数据
      data: [],
      total: 0, // 总数
      channelList: [],
      selfForm: {
        type: undefined,
        departmentId: undefined,
        departmentName: undefined,
        departmentCode: undefined,
        channel_no: undefined,
        channel_name: undefined,
        validDays: 14
      },
      form: {
        sortField: '',
        sortOrder: 'desc',
        pageNo: 1,
        pageSize: 10,
        //departmentId: undefined, //部门id
        departmentCode: '', //部门id
        custnum: '', // 客户编号
        custname: '', // 客户名称
        certnum: '', // 证件号
        accountNo: '', // 账号
        accountName: '', // 户名
        channelNo: undefined, // 渠道编号
        appNo: '', //应用编号
        mobile: '', // 手机号
        accountProperty: '',//客户类型
      },
      // 控制开户弹窗显示
      Openanaccountvisible: false,
      selfAccountVisible: false,
    }
  },
  computed: {
    busiTypeName() {
      return this.selfForm.type === 7 ? "稠州自助开户-信息采集（个人）" : "稠州自助开户-信息采集（企业）"
    }
  },
  created() {
    this.form.departmentCode = this.$session.getItem("companyCode");
    this.channelList = JSON.parse(sessionStorage.getItem("coreCompanyList"))
    this.getChannelList();
  },
  mounted() {
    // 调用获取数据
    // if (sessionStorage.getItem('msgInfo')) {
    //   this.form = this.$store.state.searchMsgInfo
    // }
    this.getcustaccountPage()
  },
  // deactivated() {
  // sessionStorage.removeItem('msgInfo')
  // },
  // destroyed() {
  // console.log('destroyed');
  // sessionStorage.removeItem('msgInfo')
  // },
  methods: {
    changeSorter(pagination, filters, sorter) {
      this.form.sortOrder = 'desc'
      if (sorter?.order === 'descend') { // 指定字段倒序排序
        this.form.sortField = sorter?.field || 'a0'
      } else { // 默认排序
        this.form.sortField = ''
      }
      this.form.sortOrder = sorter?.order === 'ascend' ? 'asc' : 'desc'
      this.getcustaccountPage()
    },
    // 获取table列表
    async getcustaccountPage() {
      let data = JSON.parse(JSON.stringify(this.form))
      const res = await this.api.accountListPageApi(data)
      // console.log(res.data.records, 'data');
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      res.data.records.forEach((item) => {
        this.modifyTree(item)
      })
      this.data = res.data.records
      // console.log(res.data.records);
    },
    // 树形表格数据
    modifyTree(item) {
      let arr = []

      // 先将父节点数据放到数组
      let objData = {
        accountName: item.accountName,
        accountNo: item.accountNo,
        accountClass: item.accountClass,
        balance: item.balance
      }
      arr.push(objData)
      if (item.childAccounts && item.childAccounts.length > 0) {

        item.childAccounts.forEach(obj => {
          let childrenObj = {
            accountName: obj.accountName,
            accountNo: obj.accountNo,
            accountClass: obj.accountClass,
            balance: obj.balance
          }
          // 再将子节点数据放到数组里
          arr.push(childrenObj)
        })
      }
      item.arrList = arr

      item.a0 = arr.filter(acct => acct.accountClass === '0')[0]
      item.a2 = arr.filter(acct => acct.accountClass === '2')[0]
      item.a6 = arr.filter(acct => acct.accountClass === '6')[0]
      item.a7 = arr.filter(acct => acct.accountClass === '7')[0]
      item.a8 = arr.filter(acct => acct.accountClass === '8')[0]

      delete item.childAccounts

    },
    // 点击重置表单
    reset() {

      this.form = {
        // departmentId: undefined, //部门id
        departmentCode: undefined, //部门id
        custnum: '', // 客户编号
        custname: '', // 客户名称

        accountNo: '', // 账号
        accountName: '', // 户名
        channelNo: '', // 渠道编号

        appNo: '', //应用编号
        mobile: '', // 手机号
        pageNo: 1,
        pageSize: 10,
      }
      this.getcustaccountPage()
    },

    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
          this.getcustaccountPage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getcustaccountPage()
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    // 个人贷款
    Personalloan() {
      this.$router.push('/Financingmanagement/Personalloan')
      // this.$store.commit('saveLoanpersonalinformation', this.selectedRows)
    },
    // 企业贷款
    Enterpriseloan() {
      this.$router.push('/Financingmanagement/Enterpriseloan')
    },
    // 开户
    Openanaccount() {
      this.Openanaccountvisible = true
    },
    // 修改
    edit() {
    },
    // 余额同步
    async Balancesynchronization() {
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.selectedRows.channelNo,
        account_no: this.selectedRows.accountNo,
        relation_acct: this.selectedRows.accountRelation,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('余额已同步')
      this.selectedRows.availBalance = res.data.detail_list.find(i => i.account_no === this.selectedRows.accountNo)?.avail_balance
      await this.getcustaccountPage()
    },
    async accCloseDailogClick() {
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      this.$confirm({
        title: "确定注销吗",
        content: () => {
          const items = this.selectedRows.arrList.map(item => {
            return <li>{item.accountNo}</li>
          })
          return <div>
            将注销【{this.selectedRows?.accountName}】名下钱包
            <ol>
              {items}
            </ol>
          </div>
        },
        onOk: async () => {
          const res = await this.api.custOpenAccTaskSave({
            channelNo: this.selectedRows.channelNo,
            appNo: this.selectedRows.appNo,
            taskType: 9,
            name: this.selectedRows.accountName,
            idNo: this.selectedRows.id_no,
            mobile: this.selectedRows.mobile,
            accountNo: this.selectedRows.accountNo,
            exData: {
              "户名":this.selectedRows.accountName,
              "账号": this.selectedRows.accountNo,
            },
            reqData:{
              channelNo: this.selectedRows.channelNo,
              appNo: this.selectedRows.appNo,
              accountNo: this.selectedRows.accountNo,

            }
          });
          if (res.code !== 200) {
            this.$message.error(res.info);
            return;
          }

          this.cancelConfirmUrl = res.data
          this.cancelConfirmShow = true
        },
        onCancel() {
        },
      });


    },
    // A2账户开户
    async openAccSubDailogClick() {

      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      this.openAccSubDailog = true;

    },
    async openAccSubCancel() {
      this.openAccSubDailog = false;
    },
    async openAccSub() {

      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }
      this.openAccSubDailog = false;
      const res = await this.api.openAccSub({
        channel_no: this.selectedRows.channelNo,
        account_no: this.selectedRows.accountNo,
        account_name: this.selectedRows.accountName,
        account_class: 8,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }

      this.$message.success('分户开户成功')

      await this.getcustaccountPage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getcustaccountPage(),
          this.selectedRowKeys = [],
          this.selectedRows = {}
    },
    // 贷款申请
    Loanapplication() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      // 个人
      if (Number(this.selectedRows.accountProperty) == 1) {
        this.$router.push({
          name: "Personalloan",
          params: {
            custnum: this.selectedRows.custnum,
            type: 'add'
          }
        })
      }
      // 企业
      if (Number(this.selectedRows.accountProperty) == 2) {
        this.$router.push({
          name: 'Enterpriseloan',
          params: {
            custnum: this.selectedRows.custnum,
            type: 'add',
          },
        })
      }
      this.$store.commit('saveWalletinformation', this.selectedRows)
    },
    // 小额打款认证
    paymentAuth() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      this.visibleDailog = true
    },
    // 绑定银行卡
    bindBankCard() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      this.visibleBindBankCard = true
    },
    handleBind(obj) {
      this.visibleBindBankCard = obj.bool
      if (obj.type === 'success') {
        // 绑定银行卡成功，重新刷新页面
        this.getcustaccountPage()
      }
    },
    // 修改账户
    editAccount() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      // 存储条件到vuex
      let storeData = {
        id: this.selectedRows.id,
        channel_no: this.selectedRows.channelNo,
        app_no: this.selectedRows.appNo,
        account_no: this.selectedRows.accountNo,
        accountName: this.selectedRows.accountName,
        custnum: this.selectedRows.custnum,
      }
      this.$store.commit('saveAccountEditData', storeData)
      if (this.selectedRows.accountProperty === '1') {
        // 个人
        this.$router.push('/wallet/IndividualAccountEdit')
      } else {
        // 企业
        this.$router.push('/wallet/CorporateAccountEdit')
      }
    },

    // 查看开户信息
    openAccView() {
      // 首先要选择一个账户
      if (!this.selectedRows?.accountNo) {
        this.$message.warning('请先选择账户')
        return
      }

      if (this.selectedRows.accountProperty === '1') {
        // 个人
        this.$router.push({
          path: "/wallet/openAccPersonalInfo",
          query: {
            id: this.selectedRows.id,
          },
        });
      } else {
        // 企业
        this.$router.push({
          path: "/wallet/openaccEnterpriseInfo",
          query: {
            id: this.selectedRows.id,
          },
        });
      }
    },
    OnPaymentChange(bool) {
      this.visibleDailog = bool
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRows?.length > 0) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = selectedRows[1]

      // 每次训中都要清空上次输入得数据
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            if (id == this.selectedRowKeys[0]) {
              this.selectedRowKeys = []
              this.selectedRows = {}
            } else {
              this.selectedRowKeys = []
              this.selectedRowKeys.push(id)
              this.selectedRows = record
            }
          }
        }
      }
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getcustaccountPage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    pagechange(val) {
      this.form.pageNo = val
      this.getcustaccountPage()
    },
    // 点击查看
    view(row) {
      if (row) {
        this.selectedRows = row
        this.selectedRowKeys = [row.id]
      } else if (this.selectedRows.length === 0 || !this.selectedRows) {
        this.$message.warning('请先选择账户')
        return
      }
      // 进入详情页存储筛选条件 
      // this.$store.commit('saveSearchMsgInfo', this.form)
      this.$store.commit('saveAccountmanagementinfo', this.selectedRows)
      this.$router.push('/wallet/accountview')
    },
    // 个人开户
    Individualaccountopening() {
      this.Openanaccountvisible = false
      this.$router.push('/wallet/Individualaccountopening')
    },
    // 个人自助开户
    selfServiceAccountOpening(type) {
      this.Openanaccountvisible = false
      this.selfAccountVisible = true
      this.selfForm.type = type
    },

    async selfAccountOpen() {

      // this.personalSelfAccountOpenvisible = false;

      const res = await this.api.custOpenAccTaskSave({
        channelNo: this.selfForm.channel_no,
        appNo: this.selfForm.app_no,
        departmentId: this.selfForm.departmentId,
        departmentCode: this.selfForm.departmentCode,
        taskType: this.selfForm.type,
        validDays: this.selfForm.validDays,
        exData: {
          "业务类型": this.busiTypeName,
          "办理渠道": this.selfForm.channel_name,
          "所属组织": this.selfForm.departmentName
        }
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.qrConfirmUrl = "?t=" + res.data
      this.qrValidThrough = moment().add(this.selfForm.validDays, 'day').format("YYYY年MM月DD日 HH:mm")

      this.qrType = this.selfForm.type === 7 ? "SelfAccountOpen" : "selfEntAccOpen"
      this.qrConfirmShow = true

    },
    // 企业开户
    CorporateAccountOpening() {
      this.Openanaccountvisible = false
      this.$router.push('/wallet/CorporateAccountOpening')
    },
    //内部户开户
    innerAccountOpening() {
      this.Openanaccountvisible = false
      this.$router.push('/wallet/InnerAccountOpen')
    },
    // 获取核心企业列表
    async getChannelList() {
      const res = await this.api.coreCompanyInfoApi();
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }

      this.companylist = []
      res.data.forEach((item) => {
        this.companylist.push({
          label: item.channelName + "（" + item.channelNo + "）",
          value: item.channelNo,
          info: item,
        });
      });
      if (this.companylist.length >= 1) {
        this.form.channel_no = this.companylist[0]?.value
        this.channel_nochange(this.form.channel_no)
      }
    },
    // 渠道变更
    channel_nochange(val) {
      this.companylist.forEach((item) => {
        if (Number(val) === Number(item.value)) {
          this.selfForm.app_no = item.info.appNo;
          this.selfForm.channel_name = item.info.channelName;
          this.selfForm.channel_no = item.value
        }
      });
    },
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

.Walletbox {
  width: 100%;

  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 280px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .lefttitle {
        margin-right: 8px;
      }

      input {
        width: 200px;
      }
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }
  }

  .Controls {
    padding-top: 10px;
    margin-bottom: 20px;

    button {
      min-width: 64px;
    }

    .edit {
      background-color: #d7d7d7;
      color: #fff;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

  }

  .paginationBox {
    display: flex;
    justify-content: flex-end;

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  // 开户弹框
}

.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

// 保证金
.Securitydeposit,
.SecuritydepositRelease {
  padding-bottom: 20px;

  .userinfo {
    padding: 20px;
    background-color: #f5f8ff;
    min-height: 130px;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }

    .blane {
      margin-bottom: 0px;
    }

    margin-bottom: 30px;
  }

  .Payoverbox {
    display: flex;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;
    margin-bottom: 20px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .Payover {
      .ant-input {
        position: relative;
        width: 220px;
        padding-right: 0px !important;
      }

      .ant-input-suffix {
        position: absolute;
        width: 40px;
        right: 0px;
        top: 0;
        margin-top: 16px;
        margin-left: 20px;
        height: 32px;
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .codebox {
    display: flex;
    display: flex;
    padding-right: 0px;
    // justify-content: space-between;
    align-items: center;
    padding-right: 30px;

    width: 100%;
    padding-left: 25px;

    .left-title {
      flex: 0 0 100px;
      text-align: left;
    }

    .code {
      width: 110px;
      margin-right: 10px;
    }
  }
}

.SecuritydepositSuccessful {
  h3 {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .info {
    padding: 20px;
    width: 100%;
    min-height: 90px;
    background-color: #f5f8ff;

    div {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
