<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>{{ title }}</span>
            <!--            <span>交易记录</span>-->
          </div>
          <div class="right">
            <a-form-model layout="inline">
              <a-form-model-item label="机构" v-if="departmentPicker">
                <DepartmentChoose :department-code.sync="form.departmentCode" @change="handleQuery"></DepartmentChoose>
              </a-form-model-item>
              <a-form-model-item label="出资人" v-if="lenderPicker">
                <lender-select :value.sync="form.lenderId" :department-code="form.departmentCode"
                               default-first
                               @change="onSearchLenderChange" allow-clear></lender-select>
              </a-form-model-item>
              <a-form-model-item label="融资人" v-if="showBorrowerPicker">
                <borrower-select
                    :value.sync="form.borrowerId" :department-code="form.departmentCode"
                    default-first :lender-id="form.lenderId"
                    @change="onSearchBorrowerChange" allow-clear></borrower-select>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </template>
      <div class="loan">
        <!-- 头部筛选区域 -->
        <div class="search-box">
          <a-form-model layout="inline">
            <a-form-model-item label="融资人">
              <a-input v-model.trim="form.borrowerAccountName" placeholder="请输入融资人名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="收款人">
              <a-input v-model.trim="form.receiverAccountName" placeholder="请输入收款人名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="交易状态">
              <a-select placeholder="请选择交易状态" v-model.trim="form.payStatus" allowClear style="width: 150px">
                <a-select-option v-for="(status, value) in nbiDict.statusConfig" :key="value" :value="value">
                  <a-tag :color="status.color">{{ status.label }}</a-tag>
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" @click="handleQuery" icon="search">查询</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="action-box">
          <slot name="action-box"></slot>
        </div>
        <div class="table-box">
          <a-table :columns="columns" :data-source="tableData" :row-key="(record) => record.id" :pagination="false"
                   bordered size="small" :scroll="{x:1600}">
            <template slot="action" slot-scope="text, record">
              <a-button type="link" size="small" v-if=" $authority('transDetail') " @click="detail(record)"
                        aria-label="明细" icon="unordered-list">
              </a-button>
            </template>
          </a-table>
          <LcfsPagination :page-no.sync="pagination.pageNo" :page-size.sync="pagination.pageSize"
                          :total="total" :fn="fetchData"></LcfsPagination>
        </div>
      </div>

    </a-card>
  </div>
</template>

<script>
import {dict_rawdata as busiDict} from "@/utils/bizDict/rawdata";
import {nbiDict} from "@/utils/bizDict/nbiDict.js";
import Balance from "../../components/Balance.vue";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import WalletInfo from "../../components/WalletInfo.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import ChannelInfo from "@/view/components/ChannelInfo.vue";
import LcfsPagination from "@/view/components/LcfsPagination.vue";
import LenderSelect from "@/view/nbi/lender/lender-select.vue";
import BorrowerSelect from "@/view/nbi/borrower/borrower-select.vue";

export default {
  name: 'transRecord',
  props: {
    transType: String,
    borrowerId: Number,
    lenderId: Number,
    transName: String,
    departmentPicker: Boolean,
    lenderPicker: Boolean,
    borrowerPicker: Boolean,
  },
  emits: ['update:borrowerId', 'update:lenderId'],
  components: {
    BorrowerSelect,
    LenderSelect,
    ChannelInfo,
    Balance,
    WalletInfo,
    LcfsPagination,
    DepartmentChoose,
  },
  computed: {
    busiDict() {
      return busiDict
    },
    showBorrowerPicker() {
      return this.borrowerPicker && (!this.lenderPicker || this.form.lenderId)
    }
  },
  data() {
    return {
      title: this.transName ?? "交易记录",
      nbiDict,
      labelCol: {span: 6},
      wrapperCol: {span: 10},
      registerVisible: false,
      lenderList: [],
      tableData: [],
      detailData: {},

      total: 0,
      pagination: {pageNo: 1, pageSize: 10},
      form: {
        transType: this.transType,
        departmentCode: undefined,

        lenderId: this.lenderId,
        lenderAccountName: "",
        lenderAccountNo: "",

        borrowerId: this.borrowerId,
        borrowerAccountName: "",
        borrowerAccountNo: "",

        receiverId: this.receiverId,
        receiverAccountName: "",
        receiverAccountNo: "",

        payStatus: "",
      },
      columns: [
        {
          title: 'No',
          align: 'center', width: 70, fixed: 'left',
          customRender: (text, record, index) =>
              `${Number(this.pagination.pageNo - 1) * Number(this.pagination.pageSize) + Number(index + 1)}`
        },
        {
          title: '流水号', dataIndex: 'txnNo',
          align: 'center', width: 240, fixed: 'left',
        },
        {
          title: '交易时间', dataIndex: 'payTime',
          align: 'center', width: 190,
          customRender: (text) => text ? this.$moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
        },
        {
          title: '交易金额', dataIndex: 'payAmount', key: 'payAmount',
          align: 'center', width: 160,
          customRender: (text) => {
            return <Balance amount={text} dcflag="c"></Balance>
          }
        },
        {
          title: "交易状态", dataIndex: "payStatus",
          align: "center", float: 'right', width: 90,
          customRender: (text, record) => {
            const statusConfig = nbiDict.statusConfig[text] || nbiDict.statusConfig.default;
            return (
                <span>
                  {text !== '1' ? (
                      <a-tooltip title={record.payResult}>
                        <a-tag color={statusConfig.color}>{statusConfig.label}</a-tag>
                      </a-tooltip>
                  ) : <a-tag color={statusConfig.color}>{statusConfig.label}</a-tag>}

                  {statusConfig.showButton && text === '4' && (
                      <a-button
                          type="link"
                          icon="reload"
                          onClick={() => this.retryOnFailure(record)}
                          style={{marginLeft: '8px'}}
                      />
                  )}
                </span>
            );
          }
        },
        {
          title: '出资人', dataIndex: 'lenderAccountNo',
          align: 'center',
          customRender: (text, record) =>
              <WalletInfo accountName={record.lenderAccountName} accountNo={record.lenderAccountNo}
                          showAccountName={true} showAccountNo={false}/>
        },
        {
          title: '收款人', dataIndex: 'receiverAccountNo', align: 'center', customRender: (text, record) =>
              <WalletInfo accountName={record.receiverAccountName} accountNo={record.receiverAccountNo}
                          showAccountName={true} showAccountNo={false}/>
        },
        {
          title: '融资人', dataIndex: 'borrowerAccountNo', align: 'center', customRender: (text, record) =>
              <WalletInfo accountName={record.borrowerAccountName} accountNo={record.borrowerAccountNo}
                          showAccountName={true} showAccountNo={false}/>
        },
        {
          title: '渠道', dataIndex: 'channelNo', align: 'center', width: 60,
          customRender: (text) => <ChannelInfo channel-no={text}/>
        },
        {
          title: "机构",
          dataIndex: "departmentId",
          align: "center",
          customRender: (text) => <DeptInfo dept-id={text} width="120"/>
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: {customRender: 'action'}
        }
      ],
    };
  },
  created() {
  },
  mounted() {
  },
  activated() {
  },
  methods: {
    retryOnFailure: async function (data) {
      const res = await this.api.transactionRetryOnFailureApi(data.txnNo)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('重试成功')
    },
    // 查询
    async handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      await this.fetchData()
    },
    onSearchLenderChange(lender) {
      this.form.lenderName = lender?.lenderName
      this.$emit("update:lenderId", lender?.id)
      if (!this.showBorrowerPicker) {
        this.fetchData()
      }
    },
    onSearchBorrowerChange(borrower) {
      this.$emit("update:borrowerId", borrower?.id)
      this.form.borrowerAccountName = borrower?.accountName
      this.fetchData()
    },
    async detail(row) {
      await this.$router.push({
        path: '/nbi/trans/detail',
        query: {
          txnNo: row.txnNo,
        }
      })
    },


    // 获取table列表
    async fetchData() {
      // 检查必填项
      const res = await this.api.transactionListApi({...this.form, ...this.pagination})
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.total = res.data.total
      this.pagination.pageNo = res.data.current
      this.pagination.pageSize = res.data.size

      this.tableData = res.data.records
      return true
    },

    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>

<style lang="less" scoped>
.top-title {
  .left {
    float: left;
  }

  .right {
    float: right;
  }
}

.search-box {
  display: flex;
  flex-wrap: wrap;
}

.action-box {
  padding-top: 10px;
  margin-bottom: 20px;
}
</style>
