<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>订单导入</span>
          </div>
          <div class="right">
            <!-- 请选择机构 -->
            <!-- 所属机构 -->
            <DepartmentChoose :department-code.sync="departmentCode" @change="departmentChange" ></DepartmentChoose>
            <!--选择电商-->
            <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择电商" @change="mchNochange"
                      :filter-option="filterOption" :show-search="true" v-model.trim="form.mchNo"
                      style="min-width: 200px;">
              <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.value">
                <span><a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br/><a-tag color="blue"
                                                                                                   v-if="item.fatherName">控</a-tag>{{
                    item.fatherName || ""
                  }}</span>
              </a-select-option>
            </a-select>
          </div>
        </div>
      </template>
      <!-- 抖音  快手 视频号-->
      <div class="trill">
        <div class="trilllist">
          <!-- 快手 -->
          <div class="item" v-for="(item, ind) in datalist" :key="ind">
            <div class="img-box">
              <img :src="item.url" alt=""/>
              <!-- <span>{{ item.spName }} </span> -->
              <div class="img-name">
                <div class="top">
                  <span>{{ item.shopName }}</span>
                </div>
                <div class="bottom">
                  <span>店铺ID：</span>
                  <span>{{ item.shopNo }}</span>
                </div>
              </div>
              <div class="control-btn" v-if="item.spName == '快手' && $authority('importKs')">
                <a-button type="primary" @click="orderEntry(item.shopNo)">导入订单</a-button>
              </div>
            </div>
            <div class="accredit">
              <div style="min-width: 120px;">
                <span>
                  <!-- 已授权 -->
                  <span v-if="Computeauthorizationexpiration(
                    item.authTime, item.status
                  ) == '授权' && item.spName == '快手'">已在{{ item.authTime }}授权
                  </span>
                  <span v-else-if="Computeauthorizationexpiration(
                    item.authTime, item.status
                  ) == '授权过期' && item.spName == '快手'">授权过期
                  </span>
                  <span v-else-if="Computeauthorizationexpiration(
                    item.authTime, item.status
                  ) == '未授权' && item.spName == '快手'">未授权
                  </span>
                  <!-- 未授权时，快手显示去授权按钮 -->
                  <a-button size="small" style="margin-left: 10px" v-if="item.spName == '快手' &&
                    Computeauthorizationexpiration(
                      item.authTime, item.status
                    ) == '未授权'
                    " class="inquire" @click="getKsAuthorizedAddress(item.shopNo)" type="primary">
                    去授权
                  </a-button>
                </span>
              </div>

            </div>
            <div class="accredittime">
            </div>
          </div>
        </div>
        <div class="footertishi">提示：需优先选择机构电商信息后加载数据</div>
      </div>

      <!-- 查询 -->
      <div class="formbox">
        <!-- 流水号 -->
        <div class="inputbox">
          <div class="lefttitle">流水号</div>

          <a-input v-model.trim="form.sysSerialNo" placeholder="请输入流水号"/>
        </div>

        <!-- 操作类型 -->
        <div class="inputbox">
          <div class="lefttitle">操作类型</div>
          <a-select placeholder="请选择操作类型" v-model.trim="form.operationType" style="width: 180px">
            <a-select-option v-for="item in operationTypelist" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 数据来源 -->
        <div class="inputbox">
          <div class="lefttitle">数据来源</div>
          <a-select placeholder="请选择数据来源" v-model.trim="form.dataSource" style="width: 180px">
            <a-select-option v-for="item in dataSourcelist" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 操作状态 -->
        <div class="inputbox">
          <div class="lefttitle">操作状态</div>
          <a-select placeholder="请选择操作状态" v-model.trim="form.operationStatus" style="width: 180px">
            <a-select-option v-for="item in operationStatuslist" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 操作时间 -->
        <div class="inputbox" style="width: 340px">
          <div class="lefttitle" style="width: 60px">操作时间</div>
          <a-range-picker style="width: 240px" value-format="YYYY-MM-DD HH:mm:ss" v-model="time"
                          v-decorator="['rangetime', { initialValue: [$moment().startOf('day'), $moment().endOf('day')] }]"
                          :default-value="[$moment().startOf('day'), $moment().endOf('day')]"
                          :default-picker-value="[$moment().startOf('day'), $moment().endOf('day')]"
                          :placeholder="['开始时间', '结束时间']"
                          @change="onOk" @ok="onOk"/>
        </div>
        <!-- 查询 -->

        <a-button class="inquire" @click="search" type="primary">
          查询
        </a-button>
        <!-- 重置 -->
        <a-button @click="reset" class="reset">重置</a-button>
      </div>
      <!-- 导入box -->
      <div class="Toleadinto">
        <!-- <a-button class="inquire" @click="search" type="primary">
          导入快手订单
        </a-button> -->
        <!--  -->

        <a-button class="inquire" @click="Orderentry" type="primary" v-if="$authority('importOriginalOrder')">
          导入抖音原始订单文件
        </a-button>
        <a-button class="inquire" @click="entryPDDOrder" v-if="$authority('importOriginalOrder')" type="primary">
          导入拼多多原始订单文件
        </a-button>
        <a-button class="inquire" @click="entryCommonOrder" v-if="$authority('importOriginalOrder')" type="primary">
          导入通用原始订单文件
        </a-button>

        <a-button class="inquire" @click="capitalOrderSync" type="primary" v-if="$authority('importOriginalOrder')">
          生成资金类订单
        </a-button>
        <!-- <a-button class="inquire" type="primary">
          导入爬虫结果文件
        </a-button> -->
      </div>
      <div class="tablebox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table ref="table" :rowKey="(record, index) => `${record.id}`" :columns="columns" :data-source="data"
                   size="small" :pagination="false">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
          首页
        </a-button>
        <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
                      show-size-changer :page-size="form.pageSize" @change="pagechange"
                      @showSizeChange="onShowSizeChange"
                      :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
                  class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>
    <!-- 订单导入 -->
    <a-modal :maskClosable="true" :title="title" :centered="true" :visible="Orderentryvisible"
             @cancel="Orderentryvisible = false">

      <!-- 订单导入 -->
      <div class="Orderentry" v-if="tempVisible">
<!--        <a :href="tempUrl" target="_blank">通用模板下载</a>-->
        <a-button type="link" size="small" @click="downLoadTemplate('TMPL_ORDER_IMPORT')">通用模板下载</a-button>
        <div style="height: 20px;"></div>
      </div>

      <div class="Orderentry">

        <a-upload :fileList="fileList" :remove="handleRemove" :customRequest="beforeUpload">
          <a-button>
            <a-icon type="upload"/>
            Upload
          </a-button>
        </a-upload>
      </div>
      <!-- 缴纳 -->
      <template v-slot:footer>
        <!-- 释放 -->
        <div class="custom-footer">
          <a-button type="primary" @click="confirm">确认导入</a-button>
          <a-button @click="Orderentryvisible = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import urlConfig from "@/utils/urlConfig";
import axios from "axios";
import {contractDocTemp} from "@/utils/contractDocTemp";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";

export default {
  name: 'orderEntryPage',
  components: {DepartmentChoose},
  data() {
    return {
      patl: '',
      value: "1",
      tempUrl: "1",
      importUrl: "/busi/oriorders/douyin/excel/imports?mchNo=",
      value1: "",
      fileList: [],
      binarylist: [],
      Orderentryvisible: false,
      tempVisible: false,
      institutiontrees: [],
      title: "原始订单导入",
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0, // 数量
      time: [this.$moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"), this.$moment().endOf('day').format("YYYY-MM-DD HH:mm:ss")],
      mchNolist: [], // 电商列表
      // 电商授权消息
      datalist: [],

      departmentId: undefined,
      departmentCode: undefined,
      form: {
        pageSize: 10,
        sysSerialNo: "",
        pageNo: 1,
        startTime: "",
        endTime: "",
        mchNo: undefined, // 电商编号
        // 操作状态
        operationStatus: undefined,
        // 数据来源
        dataSource: undefined,
        // 操作类型
        operationType: undefined,
      },
      // 操作类型：
      operationTypelist: [
        {
          label: "创建",
          value: "0",
        },
        {
          label: "修正",
          value: "1",
        },
        {
          label: "导入",
          value: "2",
        },
      ],
      // 	数据来源
      dataSourcelist: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "文件导入 ",
          value: "0",
        },
        {
          label: "爬虫文件导入",
          value: "1",
        },
        {
          label: "接口查询",
          value: "2",
        },
        {
          label: "消息订阅",
          value: "3",
        },
        {
          label: "爬虫文件导入（结算）",
          value: "4",
        },
        {
          label: "爬虫文件导入（待结算)",
          value: "5",
        },
      ],
      // 操作状态
      operationStatuslist: [
        {
          label: "处理中 ",
          value: "0",
        },
        {
          label: "处理成功",
          value: "1",
        },
        {
          label: "处理失败",
          value: "2",
        },
      ],
      columns: [
        {
          title: "No",
          dataIndex: "No",
          customRender: (text, record, index) =>
              `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
              Number(index + 1)
              }`,
          align: "center",
          width: 40,
        },
        {
          title: "流水号",
          dataIndex: "sysSerialNo",
          align: "center",
          width: 190,
          ellipsis: true,
          customRender: (text) => (text ? text : "---"),
        },
        {
          title: "操作类型",
          dataIndex: "operationType",
          align: "center",
          width: 80,
          customRender: (text) => {
            if (Number(text) == 0) {
              return "创建";
            } else if (Number(text) == 1) {
              return "修正";
            } else if (Number(text) == 2) {
              return "导入";
            } else {
              return text;
            }
          },
        },
        {
          title: "数据来源",
          dataIndex: "dataSource",
          align: "center",
          width: 80,
          customRender: (text) => {
            if (Number(text) == 0) {
              return "文件导入";
            } else if (Number(text) == 1) {
              return "爬虫文件导入";
            } else if (Number(text) == 2) {
              return "接口查询";
            } else if (Number(text) == 3) {
              return "消息订阅";
            } else if (Number(text) == 4) {
              return "爬虫文件导入（结算）";
            } else if (Number(text) == 5) {
              return "爬虫文件导入（待结算)";
            } else {
              return text;
            }
          },
        },
        {
          title: "操作状态",
          dataIndex: "operationStatus",
          align: "center",
          width: 80,
          customRender: (text) => {
            if (Number(text) == 0) {
              return "处理中";
            } else if (Number(text) == 1) {
              return "处理成功";
            } else if (Number(text) == 2) {
              return "处理失败";
            } else {
              return text;
            }
          },
        },
        {
          title: "操作信息",
          dataIndex: "operationResultMsg",
          align: "center",
          customRender: (text) => (text ? text : "---"),
          ellipsis: true,
        },
        {
          title: "操作人",
          dataIndex: "creater",
          align: "center",
          width: 80,
          customRender: (text) => (text ? text : "---"),
        },
        {
          title: "操作时间",
          dataIndex: "createTime",
          align: "center",
          width: 170,
          customRender: (text) => {
            return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        // {
        //   title: "销售平台编号",
        //   dataIndex: "spNo",
        //   align: "center",
        //   customRender: (text) => (text ? text : "---"),
        // },
        {
          title: "销售平台",
          dataIndex: "spName",
          align: "center",
          width: 200,
          customRender: (text) => (text ? text : "---"),
        },
      ],
      data: [],
    };
  },
  created() {
    this.getbusiOriginalOrdersOperationApi();
    this.departmentId = this.$session.getItem("companyId");
    this.getmchshopsApi();

    // 获取店铺授权列表
    this.getfindAuthoShop();
    this.getTemp();

  },
  methods: {
    filterOption(input, option) {
      let val = input?.trim().toLowerCase()
      if (val.length < 1) return false;
      const children = option.componentOptions.children[0].children.map(m => m.text).filter(m => m)

      return (
          children.find(m => m.toLowerCase().indexOf(val) >= 0)
      );
    },

    async getTemp() {
      const res = await this.api.downloadImage("TMPL_ORDER_IMPORT")
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.tempUrl = res.data;
    },

    // 下载模板
    async downLoadTemplate(file_no) {
      const res = await this.api.downloadImage(file_no)
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      const link = document.createElement('a');
      let Url = res.data.replace("http://", "https://");
      link.href = Url;
      link.download = 'template.xlsx'; // 文件名
      link.click();
    },

    async getfindAuthoShop() {
      // findAuthoShopApi
      if (!this.form.mchNo) return
      const res = await this.api.findAuthoShopApi({
        // departmentId: this.departmentId,
        mchNo: this.form.mchNo,
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      // await res.data.forEach((item) => {
      //   this.api.downloadImage(item.fileNo).then((blb) => {
      //     item.imageUrl = URL.createObjectURL(blb)
      //   })
      // }),

      // setTimeout(() => {
      //
      // }, 800)
      this.datalist = res.data;
    },

    // 获取快手授权地址
    async getKsAuthorizedAddress(value) {

      const res = await this.api.getKsAuthorizedAddressApi(value);

      if (res.code !== 200) {
        this.$message.warning(res.info);
        return;
      }
      window.open(res.data);
    },
    //
    Computeauthorizationexpiration(time, status) {
      // 判断是否授权，无time表示未授权
      if (!time) {
        return "未授权";
      }
      //判断是否失效
      if (status === '1') {
        return '授权过期'
      } else if (status === '0') {
        return '授权'
      }

    },

    onOk(value) {
      this.form.startTime = value[0];
      this.form.endTime = value[1];
    },
    // 所选机构触发
    departmentChange() {
      this.getmchshopsApi();
      this.getfindAuthoShop();
    },
    // 改变电商列表触发
    mchNochange() {
      this.getfindAuthoShop();
      this.getbusiOriginalOrdersOperationApi();
    },
    // 获取店铺信息列表
    async getmchshopsApi() {
      this.mchNolist = [];
      const res = await this.api.mchshopsApi({
        pageNo: 1,
        pageSize: 10000,
        mchType: 1, //默认融资电商
        departmentCode: this.departmentCode,
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      // mchNolist
      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.mchName,
        value: x.mchNo
      }))
    },
    // 获取table列表， 导入记录
    async getbusiOriginalOrdersOperationApi() {
      if (!this.form.mchNo){
        this.$message.error("请选择电商");
        return // 只能查指定电商的数据
      }

      this.form.startTime = this.time[0]
      this.form.endTime = this.time[1]
      const res = await this.api.busiOriginalOrdersOperationApi(this.form);

      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }

      this.total = res.data.total;
      this.form.pageNo = res.data.current;
      this.form.pageSize = res.data.size;

      this.data = res.data.records;
    },
    async beforeUpload(info) {

      this.fileList.push(info.file);
    },
    //删除上传文件
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();

      newFileList.splice(index, 1);

      this.fileList = newFileList;
    },
    // 抖音原始订单导入
    Orderentry() {
      this.title = "抖音原始订单导入";
      if (this.form.mchNo == '') {
        this.$message.warning('请先选择电商')
        return
      }
      this.patl = 'dy'
      this.Orderentryvisible = true;
      this.tempVisible = false;
      this.importUrl = "/busi/oriorders/douyin/excel/imports?mchNo=";
    },
    //拼多多订单导入
    entryPDDOrder() {
      this.title = "拼多多原始订单导入";
      if (this.form.mchNo == '') {
        this.$message.warning('请先选择电商')
        return
      }
      this.patl = 'pdd'
      this.Orderentryvisible = true;
      this.tempVisible = false;
      this.importUrl = "/busi/oriorders/douyin/excel/imports?mchNo=";
    },
    //通用订单导入
    entryCommonOrder() {
      this.title = "通用原始订单导入";
      if (this.form.mchNo == '') {
        this.$message.warning('请先选择电商')
        return
      }
      this.patl = 'other'
      this.Orderentryvisible = true;
      this.tempVisible = true;
      this.importUrl = "/busi/oriorders/common/excel/imports?mchNo=";
    },
    // 确认导入
    async confirm() {
      // 判断文件不能为空excelImportsApi
      if (this.fileList.length == 0) {
        this.$message.warning("清先选择文件");
        return;
      }
      let mchNo = this.form.mchNo;
      const plat = this.patl
      const formData = new FormData();
      this.fileList.forEach((item) => {
        formData.append("files", item);
      });
      const importUrl = this.importUrl;
      const res = await axios.post(
          urlConfig.busiURL + importUrl
          + mchNo + "&plat=" + plat,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.$session.getItem("token"), // 替换YOUR_TOKEN为实际的Token值
            },
          }
      );

      if (res.data.code !== 200) {
        this.$message.error(res.data.info);
        return;
      }
      this.$message.success("导入成功");
      this.fileList = [];
      this.Orderentryvisible = false;
      this.tempVisible = false;
      this.getbusiOriginalOrdersOperationApi()
    },

    async capitalOrderSync() {
      if (!this.form.mchNo) {
        this.$message.warn("请先选择一家电商")
      }
      // 生成资金类订单
      let res = await this.api.capitalOrderSync(this.form.mchNo)
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.$message.info(res.info);
    },
    pagechange(val) {
      this.form.pageNo = val;
      this.getbusiOriginalOrdersOperationApi();
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize;
      this.getbusiOriginalOrdersOperationApi();
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
    // 搜索
    search() {
      this.form.pageNo = 1
      this.getbusiOriginalOrdersOperationApi();
    },
    // 重置

    reset() {
      (this.form = {
        departmentId: undefined,
        departmentCode: undefined,
        pageSize: 10,
        sysSerialNo: "",
        pageNo: 1,
        startTime: "",
        endTime: "",
        // 操作状态
        operationStatus: undefined,
        // 数据来源
        dataSource: undefined,
        // 操作类型
        operationType: undefined,
      }),
          (this.time = []);
      this.getbusiOriginalOrdersOperationApi();
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
          this.getbusiOriginalOrdersOperationApi()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getbusiOriginalOrdersOperationApi()
    },
    // 导入订单
    orderEntry(shopNo) {
      let data = {
        shopNo,
        queryType: 1,
        statusList: [4, 5, 6],
        limit: 22
      }
      let res = this.api.ksEntryApi(data)
      if (res.code == 200) {
        this.$message.success(res.info)
      } else {
        this.$message.error(res.info)
      }
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

.trill {
  padding-top: 20px;
  width: 100%;
  padding: 0 44px;
  border-bottom: 1px solid #d7d7d7;
  height: 230px;

  .trilllist {
    // padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .item {
      width: 420px;
      padding: 18px 30px;
      min-height: 162px;
      border-radius: 6px;
      box-shadow: 0 0 3px #d7d7d7;

      .img-box {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        position: relative;


        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 25px;

        }

        .img-name {


          .top {
            display: flex;
            align-items: center;

            span {
              font-size: 18px;
              font-weight: bold;
            }

            // span:nth-child(2) {
            //   display: inline-block;
            //   width: 120px;
            //   white-space: nowrap;
            //   overflow: hidden;
            //   text-overflow: ellipsis;
            // }

          }
        }

        .control-btn {
          position: absolute;
          right: 0px;
        }

      }

      .accredit {
        margin-bottom: 10px;
      }

      .accredit,
      .accredittime {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          &:first-child {
            margin-right: 3px;
          }

          // &:last-child {}
        }
      }
    }
  }

  .footertishi {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    color: #969696;
  }
}

.formbox {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
  display: flex;
  padding-left: 30px;
  align-items: center;

  button {
    margin-bottom: 20px;
  }

  .inputbox {
    width: 260px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 180px;
    }
  }

  button {
    margin-bottom: 20px;
  }

  .reset {
    width: 64px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Toleadinto {
  padding-left: 30px;

  button {
    margin-bottom: 20px;
  }
}

.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 0 32px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  padding: 0 32px;
  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
      width: 260px;
    }
  }
}
</style>
