<script>

import WalletInfo from "@/view/components/WalletInfo.vue";

export default {
  name: 'lender-select',
  components: {WalletInfo},
  emits: ['change', 'update:value'],
  props: {
    departmentCode: String,
    disabled: Boolean,
    value: Number,
    defaultFirst: {
      type: Boolean,
      default: true
    },
    allowClear: Boolean
  },
  data() {
    return {
      pagination: {
        pageSize: 1000,
        pageNo: 1,
      },
      innerValue: this.value,
      searchForm: {
        departmentCode: this.departmentCode
      },
      options: [],
    }
  },
  methods: {
    onChange(id) {
      const lender = this.options.find(i => i.id === id)
      // value.sync
      this.$emit("update:value", id)
      // @change
      this.$emit("change", lender)
    },
    async fetchData() {
      // 检查必填项
      const res = await this.api.lenderPageApi({
        ...this.searchForm,
        ...this.pagination
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.options = res.data.records
      // 默认选中第一个
      if (this.defaultFirst && !this.innerValue && this.options && this.options.length > 0) {
        this.innerValue = this.options[0].id
        this.onChange(this.options[0].id)
      }
      return true
    },
  },
  async mounted() {
    if(this.options.length === 0){
      await this.fetchData()
    }
  },
  watch: {
    departmentCode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchForm.departmentCode = newVal
        this.fetchData()
      }
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.innerValue = newVal
      }
    },
  }
}
</script>

<template>
  <a-select class="lender-select" v-model="innerValue" placehoder="请选择出资人" :allowClear="allowClear"
            @change="onChange">
    <a-select-option v-for="item in options" :key="item.id" :value="item.id">
      <a-tag color="cyan">资</a-tag>
      <span>{{ item.lenderName }}</span>
      <span style="float:right;">
      <WalletInfo :account-no="item.accountNo" :account-name="item.accountName" :account-class="item.accountClass"
                  :show-balance="false" :show-account-no="false"></WalletInfo>
      </span>
    </a-select-option>
  </a-select>
</template>

<style scoped lang="less">
.lender-select {
  min-width: 300px;

  /deep/ .ant-select {
    width: 100%;
  }
  /deep/ .ant-select-selection-selected-value {
    width: 100% !important;
  }
}
</style>