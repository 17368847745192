<template>
  <a-modal class="QRConfirm" :keyboard="false" :maskClosable="false" :centered="true"
           :visible="display" :body-style="bodyStyle" :mask-style="maskStyle" :footer="false" :closable="false"
           :zIndex="2000">
    <div class="qr-wrapper">
      <div class="upper">{{ busi_intro }}</div>
      <div class="middle">
        <canvas ref="qrConfirmCanvas"></canvas>
      </div>
      <div class="downer">
        <p v-if="username">您好，<span>{{ username }}</span></p>
        <p>请使用<span>微信</span>扫码，并根据提示办理</p>
        <p class="valid" v-if="validThrough">有效期至：<span>{{ validThrough }}</span>
          <a-button type="link" @click='onBack' icon="reload"></a-button>
        </p>
      </div>
    </div>
    <div style="text-align: center" class="custom-footer">
      <a-button type="primary" @click='onClose' icon="check">完成</a-button>
    </div>
  </a-modal>
</template>
<script>
import qrcode from "qrcode";

export default {
  name: "QRConfirm",
  emits: ["close"],
  props: {
    url: String,
    qrType: String,
    display: Boolean,
    busi_intro: String,
    username: String,
    validThrough: String,
  },
  data: function () {
    return {
      baseurl: "",
      bodyStyle: {
        backgroundColor: "transparent",
      },
      maskStyle: {
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      },
    };
  },
  created: async function () {

  },
  watch: {
    url(newVal, oldVal) {
      this.createQRCode()
    }
  },
  methods: {
    createQRCode() {
      let dict = this.qrType || "data_collection_confirm"; // 使用动态值或默认值
      console.log("Request Dict:", dict);

      // 调用接口并处理响应
      this.api.downLoadTemplateApi([dict])
          .then(records => {
            if (records.code !== 200) {
              this.$message.warning(records.info || "操作失败");
              return; // 提前退出
            }

            const targetData = records?.data?.[dict]; // 根据动态 key 获取对应数据
            if (!targetData) {
              this.$message.error(`数据格式异常，未找到 ${dict} 信息`);
              return;
            }

            // 动态获取 constValue 并确保有默认值
            this.baseurl = targetData.constValue || "";
            console.log("Base URL:", this.baseurl);

            // 检查 baseurl 和 url 是否有效
            if (!this.baseurl || !this.url) {
              this.$message.error("二维码数据缺失，请检查 baseurl 或 url 是否有效");
              return;
            }

            // 生成二维码
            this.generateQRCode();
          })
          .catch(error => {
            console.error("API 调用失败:", error);
            this.$message.error("接口调用失败，请稍后再试");
          });
    },

    generateQRCode() {
      // 使用 $nextTick 确保 DOM 更新完成
      this.$nextTick(() => {

        qrcode.toCanvas(
            this.$refs.qrConfirmCanvas,
            this.baseurl + this.url, {
              width: 250,
              height: 250,
            }, (error) => {
              if (error) {
                this.$message.error("无法获取二维码，请联系管理员")
                console.error("二维码生成失败", error);
              }
            });
      })
    },
    onBack() {
      this.$emit("update:display", false);
    },
    onClose() {
      this.$emit("update:display", false);
      this.$emit("close")
    }
  }
}


</script>
<style scoped lang="less">
/deep/ .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

div.qr-wrapper {
  width: 350px;
  height: auto;
  margin: 0 auto;
  display: block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #004786;
  background-color: #004786;

  div.upper {
    height: 90px;
    width: 100%;
    color: #eaeaea;
    font-size: 22px;
    text-align: center;
    line-height: 90px;
  }

  div.middle {
    height: auto;
    padding: 20px 20px 0 20px;
    width: 100%;
    background-color: white;

    canvas {
      display: block;
      margin: 0 auto;
    }
  }

  div.downer {
    background-color: white;
    padding: 15px;
    text-align: center;

    span {
      font-weight: bolder;
      color: #1890ff;
    }

    .valid {
      overflow: visible;
      font-size: 0.9em;
      span {
        color: #494949;
      }
    }
  }
}

.custom-footer {
  text-align: center;
  padding: 30px 0;
}

</style>