<template>
  <!-- 归集协议理列表页 -->
  <div>
    <a-card title="归集协议管理" style="margin: 20px">
      <!-- 头部筛选区域 -->
      <div class="selected-box">

        <!-- 机构ID -->
        <div class="selected-box-item">
          <span class="left-item">机构ID</span>
          <DepartmentChoose :department-code.sync="selectedData.departmentCode"
                            @change="handleQuery"></DepartmentChoose>
        </div>
        <!-- 收款账号 -->
        <div class="selected-box-item">
          <span class="left-item">归集账号</span>
          <a-input v-model.trim="selectedData.oppAccountNo" placeholder="请输入归集账号"></a-input>
        </div>

        <!-- 付款账号 -->
        <div class="selected-box-item">
          <span class="left-item">代扣账号</span>
          <a-input v-model.trim="selectedData.accountNo" placeholder="请输入代扣账号"></a-input>
        </div>


        <div class="selected-box-item" style="display: block">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="table-box">
        <div class="table-box-handle">
          <a-button type="primary" v-if="$authority('payWithholdAdd')">
            <router-link :to="{ name: 'payWithholdAdd'}" target="_self" rel="opener">
              <a-icon type="book"/>
              签约
            </router-link>
          </a-button>

          <a-popover>
            <template slot="content">
              归集选中的账号的全部余额。若要指定余额，请点击每条记录后的归集按钮
            </template>
            <a-button type="danger" @click="showKeyConfirm" v-if="$authority('payWithholdDeal')"
                      icon="transaction"
                      :disabled="selectedRowKeys.length===0">
              归集选中({{ selectedRowKeys.length }})
            </a-button>
          </a-popover>

          <a-button type="link" @click="showLumpedRecord()">
            <a-icon type="history"></a-icon>
            归集记录
          </a-button>

        </div>
        <div class="table-main">
          <a-table size="small" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: getCheckboxProps,
          }"
                   :customRow="clickrow" :columns="columns" :data-source="mergedData" :pagination="false"
                   row-key="id">
            <template slot="oppAccountSelect" slot-scope="text, record">
              <div v-if="record.options.length>0" @click.stop>
                <a-select v-model="record.id" style="width: 100%"
                          @change="mergeChildIntoParent(record, record.id)">
                  <a-select-option v-for="item in record.options" :key="item.id" :value="item.id">
                    <WalletInfo :account-no="item.oppAccountNo" :account-name="item.oppAccountName"
                                :show-withdraw-btn="record.oppAccountClass==='0'"
                                :account-class="item.oppAccountClass"
                                show-account-name enable-query-balance :show-account-no="false"></WalletInfo>
                  </a-select-option>
                </a-select>
              </div>
              <WalletInfo v-else :account-no="record.oppAccountNo" :account-name="record.oppAccountName"
                          :account-class="record.oppAccountClass"
                          :show-withdraw-btn="record.oppAccountClass==='0'"
                          :channel-no="record.channelNo"
                          show-account-name enable-query-balance :show-account-no="false"
              ></WalletInfo>
            </template>

            <template slot="action1" slot-scope="text, record">
              <a-button type="link" icon="transaction" v-if="$authority('payWithholdDeal')&& !isDisabled(record)"
                        @click.stop="handleSingle(record)">
                <a-icon type="network" theme="twoTone" two-tone-color="blue"/>
                归集
              </a-button>
              <a-button type="link" @click.stop="showLumpedRecord(record)">
                <a-icon type="history"/>
                记录
              </a-button>
            </template>
            <template slot="action2" slot-scope="text, record">
              <a-button type="link" @click.stop="details(record)"
                        v-if="$authority('payWithholdDetail') ">
                <a-icon type="eye"/>
              </a-button>
              <a-button type="link" @click.stop="showDeleteConfirm(record)"
                        v-if="$authority('payWithholdDel')">
                <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
      <lcfs-pagination :page-no.sync="pagination.pageNo" :page-size.sync="pagination.pageSize" :total="total"
                       :fn="payWithholdContractList"></lcfs-pagination>
      <!-- 按钮弹框 -->
      <div class="del-dialog">
        <a-modal
            title="确认删除"
            :visible="isModalVisible"
            @ok="handleOk"
            @cancel="handleCancel"
        >
          <p>确定要删除这条记录吗？</p>
        </a-modal>
      </div>


      <div class="del-dialog">
        <a-modal
            :width="1000"
            title="确认归集"
            :visible="isModalKeyVisible"
            @ok="handleKeyOk"
            @cancel="handleKeyCancel"
        >
          <p style="font-size: 20px">
            即将归集{{ selectedRowKeys.length }}个账户资金，请确认。
          </p>
          <p style="font-size: 20px" v-if="selectedRows.length < selectedRowKeys.length">
            其中{{ selectedRowKeys.length - selectedRows.length }} 条记录不在本页，无法展示
          </p>
          <p v-for="(item,index) in selectedRows" :key="index">
            <WalletInfo :account-name="item.accountName" :account-no="item.accountNo"
                        :show-account-name="true" :show-account-no="false"
                        :account-class="item.accountClass"></WalletInfo>
            <a-icon type="arrow-right"></a-icon>
            <WalletInfo :account-name="item.oppAccountName" :account-no="item.oppAccountNo"
                        :show-account-name="true" :show-account-no="false"
                        :account-class="item.oppAccountClass"></WalletInfo>
          </p>

        </a-modal>
      </div>


      <div class="history-dialog">
        <a-modal title="归集记录" :visible="isModalLumpedVisible" @ok="handlePayWithholdItemOk"
                 @cancel="handlePayWithholdItemOk" width="75%">
          <div class="selected-box">
            <!-- 付款账号 -->
            <div class="selected-box-item">
              <span class="left-item">代扣账号</span>
              <a-input v-model.trim="selectedItemData.accountNo" placeholder="请输入代扣账号"></a-input>
            </div>
            <!-- 收款账号 -->
            <div class="selected-box-item">
              <span class="left-item">归集账号</span>
              <a-input v-model.trim="selectedItemData.oppAccountNo" placeholder="请输入归集账号"></a-input>
            </div>
            <div class="selected-box-item">
              <span class="left-item">归集结果</span>
              <a-select v-model.trim="selectedItemData.payStatus" placeholder="全部">
                <a-select-option value=""> 全部</a-select-option>
                <a-select-option value="1"> 成功</a-select-option>
                <a-select-option value="0"> 失败</a-select-option>
              </a-select>
            </div>
            <div class="selected-box-item" v-if="$authority('mchPayFee')">
              <span class="left-item">服务费收取状态</span>
              <a-select v-model.trim="selectedItemData.received" placeholder="全部">
                <a-select-option value=""> 全部</a-select-option>
                <a-select-option value="1"> 成功</a-select-option>
                <a-select-option value="0"> 失败</a-select-option>
                <a-select-option value="-1"> 未收取</a-select-option>
              </a-select>
            </div>

            <div class="selected-box-item">
              <span class="left-item">归集时间</span>
              <a-range-picker v-model.trim="selectedRange" valueFormat="YYYY-MM-DD"/>
            </div>


            <div class="selected-box-item" style="display: block">
              <a-button type="primary" @click="handleselectedItemDataQuery">查询</a-button>
              <a-button style="margin-left: 20px" @click="handleselectedItemDataReset">重置</a-button>
              <a-button type="primary" @click="handleselectedItemDataExport">导出</a-button>
            </div>
          </div>
          <template>
            <div class="table-main">
              <a-table :columns="columnItems" :pagination="false" :data-source="itemData"
                       :row-key="record => record.id" :scroll="{x:1000}">
                <template slot="amount" slot-scope="text,record">
                  <template v-if="record.payStatus === 1">
                    <template v-if="record.received && record.receivableFee">

                      <Balance :amount="text"/>
                      <span class="secondary">
                      净：
                      <Balance inline :amount="Number(text) - Number(record.receivableFee)" dcflag="d"/>
                      </span>
                    </template>
                    <template v-else>
                      <Balance :amount="text" dcflag="d"/>
                    </template>
                  </template>
                  <template v-else>
                    <Balance :amount="text" dcflag="c"/>
                  </template>

                </template>
              </a-table>
            </div>
            <lcfs-pagination :page-no.sync="itemPagination.pageNo" :page-size.sync="itemPagination.pageSize"
                             :total="itemTotal" :fn="getPayWithholdItem"></lcfs-pagination>
          </template>
        </a-modal>
      </div>
      <a-modal
          title="签约详情"
          :width="1000"
          :visible="ismodalDetailsVisible"
          @ok="ismodalDetailsVisible=false"
          @cancel="ismodalDetailsVisible=false"
      >

        <div class="container">
          <a-row>
            <a-col :span="12">
              <div class="container-item">
                <a-row>
                  <a-col :span="8">代扣账户:</a-col>
                  <a-col :span="16">{{ detail.accountName }}</a-col>
                </a-row>
              </div>
              <div class="container-item">
                <a-row>
                  <a-col :span="8">代扣账号:</a-col>
                  <a-col :span="16">{{ detail.accountNo }}</a-col>
                </a-row>
              </div>

            </a-col>
            <a-col :span="12">

              <div class="container-item">
                <a-row>
                  <a-col :span="8">归集账户:</a-col>
                  <a-col :span="16">{{ detail.oppAccountName }}</a-col>
                </a-row>
              </div>
              <div class="container-item">
                <a-row>
                  <a-col :span="8">归集账号:</a-col>
                  <a-col :span="16">{{ detail.oppAccountNo }}</a-col>
                </a-row>
              </div>

            </a-col>
          </a-row>

          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="手续费">
              <a-table :columns="feeColumns" :data-source="feeList" size="small" :pagination="false" row-key="id">
              </a-table>
            </a-tab-pane>

          </a-tabs>


        </div>
      </a-modal>
    </a-card>
    <a-modal
        title="确认归集"
        :visible="isModalCollectionVisible"
        @ok="handleSingleOk"
        :width="600"
        @cancel="handleSingleCancel"
    >
      <a-form-model v-if="formData" inline :model="formData" :label-col="{span: 7}"
                    :wrapper-col="{span: 13}">
        <a-form-model-item label="代扣账户" align="right">
          <WalletInfo :accountName="formData.accountName"
                      :showAccountName="true"
                      :showAccountNo="false"
                      :accountNo="formData.accountNo"
          ></WalletInfo>
        </a-form-model-item>
        <a-form-model-item label="归集账户" align="right">
          <WalletInfo :accountName="formData.oppAccountName"
                      :showAccountName="true"
                      :showAccountNo="false"
                      :accountNo="formData.oppAccountNo"
          ></WalletInfo>
        </a-form-model-item>
        <a-form-model-item label="归集金额">
          <input-balance v-model="formData.amount" :max="formData.maxAmount"
                         placeholder="请输入归集金额"></input-balance>
        </a-form-model-item>

        <a-form-model-item label="摘要">
          <a-textarea :rows="2" v-model="formData.remark" placeholder="(可选)"/>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>

</template>

<script>
import WalletInfo from '@/view/components/WalletInfo.vue'
import {message} from "ant-design-vue";
import Balance from "@/view/components/Balance.vue";
import dayjs from "dayjs";
import authority from "@/utils/authority";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import LcfsPagination from "@/view/components/LcfsPagination.vue";

const data = []

export default {
  name: 'payWithhold',
  props: {},
  components: {LcfsPagination, InputBalance, DepartmentChoose, WalletInfo, Balance},
  data() {
    return {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
      // 绑定时间范围
      selectedRange: [],
      // 表格数据
      data,
      mergedData: [],
      // 默认展开所有子级
      expandedRowKeys: [],
      accountNames: '',
      itemData: [],
      detail: {},
      feeList: [],

      selectedItemData: {
        withholdId: '',
        accountNo: '',
        oppAccountNo: '',
        payStatus: '1',
        received: '',
        // 开始时间
        startTime: '',
        // 结束时间
        endTime: '',
        pageItemNo: 1,
        // 一页显示多少条
        pageItemSize: 10,
      },
      expandRowByClick: false,
      feeColumns: [
        {
          title: '收费账户',
          dataIndex: 'feeAccountNo',
          key: 'feeAccountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.feeAccountName} accountNo={text} showAccountName={true}
                               showAccountNo={false}/>
          }
        }, {
          title: '服务费规则',
          dataIndex: 'feeRuleName',
          key: 'feeRuleName',
          align: 'center'
        }, {
          title: '服务费规则编号',
          dataIndex: 'feeRuleNo',
          key: 'feeRuleNo',
          align: 'center'
        },

      ],
      columnItems: [
        {
          title: '归集时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          ellipsis: false,
          customRender: (text) => {
            return text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        },
        {
          title: '代扣账户',
          dataIndex: 'accountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.accountName} accountNo={text} showAccountName={true}
                               showAccountNo={false}/>
          }
        },
        {
          title: '归集账户',
          dataIndex: 'oppAccountNo',
          key: 'oppAccountNo',
          align: 'center',
          customRender: (text, record) => {
            return <WalletInfo accountName={record.oppAccountName} accountNo={text} showAccountName={true}
                               showAccountNo={false}/>
          }
        },
        {
          title: '归集金额',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
          ellipsis: true,
          width: 200,
          scopedSlots: {customRender: 'amount'},
        },
        {
          title: '结果',
          dataIndex: 'payStatus',
          key: 'payStatus',
          align: 'center',
          ellipsis: true,
          width: 60,
          customRender: (text, record) => {
            return (
                <span>{text === 0 ?
                    (
                        <a-tooltip title={record.failMsg}>
                          <span style={{color: 'red'}}>失败</span>
                        </a-tooltip>
                    ) : (
                        <span style={{color: 'green'}}>成功</span>
                    )}</span>
            );
          }
        },
        {
          title: '摘要',
          dataIndex: 'remark',
          key: 'remark',
          align: 'center',
          ellipsis: true
        },
        ...(authority('mchPayFee') ? [
          {
            title: '服务费',
            key: 'serviceFeeStatusAmount',
            align: 'center',
            width: 160,
            customRender: (text, record) => {
              // 默认显示的金额内容
              const amountContent = (record.received === '1' || record.receivableFee === 0) ?
                  <Balance amount={record.receivableFee} dcflag="d"/> :
                  <Balance amount={record.receivableFee} dcflag="c"/>
              // 判断是否需要显示刷新按钮
              const showReloadButton = (record.received === '0' || record.received === '-1') && record.receivableFee > 0;

              return (
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {amountContent}
                    {showReloadButton && (
                        <a-button type="link" onClick={() => this.withholdRetryServiceFee(record)}>
                          <a-icon type="reload"/>
                        </a-button>
                    )}
                  </div>
              );
            }
          }
        ] : [])
      ],
      columns: [
        {
          title: 'No',
          customRender: (text, record, index) =>
              `${Number(this.pagination.pageNo - 1) * Number(this.pagination.pageSize) +
              Number(index + 1)
              }`,
          align: 'center',
          key: 'sort',
        },
        {
          title: '代扣账户',
          dataIndex: 'accountNo',
          align: 'left',
          customRender: (text, record) => {
            return (
                <div>
                  <WalletInfo
                      accountName={record.accountName}
                      showAccountName={true}
                      showAccountNo={false}
                      accountNo={text}
                      accountClass={record.accountClass}
                      enableQueryBalance
                  />
                </div>
            );
          }

        },
        {
          title: '归集账户',
          dataIndex: 'oppAccountNo',
          key: 'oppAccountNo',
          align: 'left',
          scopedSlots: {customRender: 'oppAccountSelect'},

        },
        {
          title: "签约状态",
          dataIndex: "checkStatus",
          align: "center",
          customRender: (text, record) => {
            if (record.oppAccountNo) {
              if (text === '1') {
                return <a-tag color="green">已签约</a-tag>
              } else {
                return <span>
              <a-tag color="red">未签约</a-tag>
              <a-button
                  type="link"
                  icon="reload"
                  onClick={() => this.handleApprovalRefresh(record)}
                  style={{marginLeft: '8px'}}
              ></a-button>
              </span>
              }
            }

          },
        },
        {
          title: "小额认证",
          dataIndex: "auth",
          align: "center",
          customRender: (text, record) => {
            if (record.oppAccountNo) {
              if (text === 1) {
                return <a-tag color="green">已认证</a-tag>
              } else {
                return <span>
                  <a-tag color="red">未认证</a-tag>
                  <a-button
                      type="link"
                      icon="reload"
                      onClick={() => this.handleAuthenticationRefresh(record)}
                      style={{marginLeft: '8px'}}
                  ></a-button>
                  </span>
              }
            }
          },
        },
        {
          title: "操作",
          dataIndex: "action1",
          align: "center",
          fixed: 'right',
          scopedSlots: {customRender: 'action1'},
        },
        ...(!this.$authority("payWithholdDetail") && !this.$authority("payWithholdDel")) ? [] : [
          {
            title: "管理",
            dataIndex: "action2",
            align: "center",
            fixed: 'right',
            scopedSlots: {customRender: 'action2'},
          },
        ]
      ],
      selectedRowKeys: [],
      formData: undefined,
      // 筛选参数
      selectedData: {
        // 渠道编号
        channelNo: undefined,
        // 机构ID
        departmentCode: undefined,
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',

        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
      },
      pagination: {
        // 当前页
        pageNo: 1,
        // 一页显示多少条
        pageSize: 10,
      },
      // 总数
      total: 0,
      itemPagination: {
        pageNo: 1,
        pageSize: 10,
      },
      // 总数
      itemTotal: 0,
      isShowDelDialog: false,
      isModalVisible: false,
      isModalKeyVisible: false,
      isModalCollectionVisible: false,
      isModalLumpedVisible: false,
      ismodalDetailsVisible: false,
      currentRecord: null,
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    // if (sessionStorage.getItem('msgInfo')) {
    //   this.selectedData = this.$store.state.searchMsgInfo
    // }
    // 获取列表
    this.payWithholdContractList()
  },
  activated() {
  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    async retryServiceFee(record) {
      this.api.retryServiceFeeApi([record.id]).then(res => {
        if (res.code == 200) {
          this.$message.success('重试成功')
          this.payOrdersList()
        } else {
          this.$message.error(res.info)
        }
      })

    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    // 获取列表函数
    async payWithholdContractList() {
      let data = JSON.parse(JSON.stringify(this.selectedData))
      let res = await this.api.payWithholdContractList({...this.pagination, ...data})
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.data = res.data.records
      this.total = res.data.total
      this.mergeData()
    },
    mergeChildIntoParent(parent, childId) {
      parent.options.forEach(child => {
        if (child.id === childId) {
          // merge child info into parent
          if (child) {
            Object.assign(parent, child)
          }
        } else {
          // remove siblings from selectedRowKyes
          if (this.selectedRowKeys.some(id => id === child.id)) {
            this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== child.id);
          }
        }
      })
    },
    mergeData() {
      this.mergedData = this.data.map(item => {
        const options = item.children ?? []
        const selectedId = options.map(child => child.id).find(id => {
          return this.selectedRowKeys.some(selectedRowKeys => id === selectedRowKeys)
        })
        let firstId = options[0]?.id
        const record = {...item, options}
        delete record.children // remove this to avoid tree rendering
        this.mergeChildIntoParent(record, selectedId ?? firstId)
        return record
      })
    },
    // 查询
    handleQuery() {
      this.pagination.pageNo = 1
      this.selectedRowKeys = []
      this.payWithholdContractList()
    },
    // 查询
    handleselectedItemDataQuery() {
      this.itemPagination.pageNo = 1
      this.selectedRowKeys = []
      this.getPayWithholdItem()
    },

    // 导出
    async handleselectedItemDataExport() {
      if (this.selectedRange.length != 0) {
        this.selectedItemData.startTime = this.selectedRange[0] + " 00:00:00"
        this.selectedItemData.endTime = this.selectedRange[1] + " 23:59:59"
      }
      let param = {
        withholdId: this.selectedItemData.withholdId,
        accountNo: this.selectedItemData.accountNo,
        oppAccountNo: this.selectedItemData.oppAccountNo,
        payStatus: this.selectedItemData.payStatus,
        received: this.selectedItemData.received,
        startTime: this.selectedItemData.startTime,
        endTime: this.selectedItemData.endTime,
      }

      const rsp = await this.api.payWithholdContractItemExport(param)
      this.$uploadfile.ExcelDownload("归集明细记录", rsp);


    },

    // 重置
    handleReset() {
      this.selectedRowKeys = []
      this.pagination.pageNo = 1
      this.pagination.pageSize = 10
      this.selectedData = {
        // 机构ID
        departmentId: '',
        // 电商编号
        mchNo: '',
        // 电商名称
        mchName: '',
        // 收款账号
        oppAccountNo: '',
        // 收款户名
        oppAccountName: '',
        // 渠道编号
        channelNo: '',
        // 应用编号
        appNo: '',
        // 付款账号
        accountNo: '',
        //付款户名
        accountName: '',
      }
      this.payWithholdContractList()
    },
    // 重置
    handleselectedItemDataReset() {
      this.selectedItemData = {
        oppAccountNo: '',
        accountNo: '',
      }
      this.showLumpedRecord()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //父级禁止选中
    getCheckboxProps: record => ({
      props: {
        disabled: record.auth !== 1 || record.checkStatus !== '1',
        name: record.name,
      },
    }),
    isDisabled(record) {
      return record.auth !== 1 || record.checkStatus !== '1'
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            if (this.isDisabled(record)) return
            let id = Number(record.id);
            if (this.selectedRowKeys.indexOf(id) >= 0) {
              this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
            } else {
              this.selectedRowKeys.push(id)
            }
          }
        }
      }
    },
    showDeleteConfirm(record) {
      this.currentRecord = record;
      this.isModalVisible = true;
    },

    async showLumpedRecord(record) {
      if (record) {
        this.currentRecord = record;
        this.selectedItemData.withholdId = this.currentRecord.id;
      }
      this.itemPagination.pageNo = 1
      await this.getPayWithholdItem()
      this.isModalLumpedVisible = true;
    },


    async getPayWithholdItem() {
      if (this.selectedRange.length !== 0) {
        this.selectedItemData.startTime = this.selectedRange[0] + " 00:00:00"
        this.selectedItemData.endTime = this.selectedRange[1] + " 23:59:59"
      }
      let param = {
        ...this.itemPagination,
        withholdId: this.selectedItemData.withholdId,
        accountNo: this.selectedItemData.accountNo,
        oppAccountNo: this.selectedItemData.oppAccountNo,
        payStatus: this.selectedItemData.payStatus,
        received: this.selectedItemData.received,
        startTime: this.selectedItemData.startTime,
        endTime: this.selectedItemData.endTime,
      }

      const rsp = await this.api.payWithholdContractItemList(param)
      if (rsp.code == 200) {
        this.itemData = rsp.data.records
        this.pageItemNo = rsp.data.current
        this.pageItemSize = rsp.data.size
        this.itemTotal = rsp.data.total
      } else {
        this.itemData = []
        this.$message.error(rsp.info)
      }

    },

    async details(record) {
      const rsp = await this.api.payWithholdDetailApi(record.id)
      if (rsp.code === 200) {
        this.detail = rsp.data
        this.feeList = []
        if (rsp.data.feeRuleNo) {
          this.feeList.push({
            id: 'rule1',
            feeAccountClass: rsp.data.feeAccountClass,
            feeAccountName: rsp.data.feeAccountName,
            feeAccountNo: rsp.data.feeAccountNo,
            feeRuleName: rsp.data.feeRuleName,
            feeRuleNo: rsp.data.feeRuleNo,
          })
        }

        if (rsp.data.feeRuleNo2) {
          this.feeList.push({
            id: 'rule2',
            feeAccountClass: rsp.data.feeAccountClass2,
            feeAccountName: rsp.data.feeAccountName2,
            feeAccountNo: rsp.data.feeAccountNo2,
            feeRuleName: rsp.data.feeRuleName2,
            feeRuleNo: rsp.data.feeRuleNo2,
          })
        }

      } else {
        this.$message.error(rsp.info)
      }
      this.ismodalDetailsVisible = true;
    },


    async withholdRetryServiceFee(record) {
      this.api.payWithholdRetryServiceFee(record.id).then(res => {
        if (res.code == 200) {
          this.$message.success('重试成功')
          this.showLumpedRecord()
        } else {
          this.$message.error(res.info)
        }
      })
    },

    handlePayWithholdItemOk() {
      this.isModalLumpedVisible = false
      this.selectedItemData = {}
    },

    handleOk() {
      // 执行删除操作
      this.data = this.data.filter(item => item.key !== this.currentRecord.key);
      this.api.payWithholdContractDelete(this.currentRecord.id).then(res => {
        if (res.code == 200) {
          this.$message.success('删除成功')
          this.payWithholdContractList()
        } else {
          this.$message.error(res.info)
        }
      })
      this.isModalVisible = false;

    },
    handleCancel() {
      this.isModalVisible = false;
      this.currentRecord = null;
    },


    // 一键归集
    showKeyConfirm() {
      if (this.selectedRowKeys.length === 0) {
        return message.warning('请选择需要归集的记录!')
      }

      this.isModalKeyVisible = true;
    },

    handleBatch(ids) {
      return this.api.payWithholdContractOneKeyCollection(ids).then(res => {
        this.handleCollectionResponse(res, 'isModalKeyVisible');
      });
    },

    handleSingleOk() {

      this.$confirm({
        title: "归集金额确认",
        width: 1000,
        content: () => {
          return <a-descriptions column={2} bordered size="small">
            <a-descriptions-item label="代扣账号">
              {this.formData.accountName}
            </a-descriptions-item>
            <a-descriptions-item label="归集账号">
              {this.formData.oppAccountName}
            </a-descriptions-item>
            <a-descriptions-item label="归集金额">
              <Balance amount={Number(this.formData.amount)} align-right={false}></Balance>
            </a-descriptions-item>
          </a-descriptions>
        },
        okText: "确定",
        onOk: () => {
          return this.api.payWithholdContractCollection(this.formData).then(res => {
            this.handleCollectionResponse(res, 'isModalCollectionVisible');
          });
        },
        onCancel: () => {
        }
      })
    },

    handleCollectionResponse(res, modalVisibleKey) {
      if (res.code === 200) {
        this.showResultMessageBox(res.data);
        this[modalVisibleKey] = false;
        this.payWithholdContractList();
      } else {
        this.$message.error(res.info);
      }
    },

    showResultMessageBox(data) {
      this.$msgbox({
        type: "info",
        customClass: 'msg-box-wide',
        message: this.renderResultDescriptions(data),
        callback: () => {
        }
      });
    },

    renderResultDescriptions(data) {
      return (
          <a-descriptions title="归集结果" bordered column={2}>
            <a-descriptions-item label="成功">
              {data.successNum ? <a-tag color="green">{data.successNum}笔</a-tag> : <span></span>}
            </a-descriptions-item>
            <a-descriptions-item label="失败">
              {data.failfulNum ? <a-tag color="red">{data.failfulNum}笔</a-tag> : <span></span>}
            </a-descriptions-item>
            <a-descriptions-item label="归集金额" span={2}>
              <Balance amount={data.successTotalAmount}/>
            </a-descriptions-item>
            {data.totalFeeAmount ?
                <a-descriptions-item label="归集（净）">
                  <Balance amount={data.successTotalAmount - data.totalFeeAmount}/>
                </a-descriptions-item> : <template></template>}
            {data.totalFeeAmount ?
                <a-descriptions-item label="技术服务费">
                  <Balance amount={data.totalFeeAmount} dcflag="c"/>
                </a-descriptions-item> : <template></template>}
            {data.failfulNum ?
                <a-descriptions-item label="失败明细">
                  {data.failfulAccount}
                </a-descriptions-item> : <template></template>}
          </a-descriptions>
      );
    },


    async handleSingle(record) {
      let maxAmount = 0
      try {
        const res = await this.api.accountqueryBalancedApi({
          channel_no: record.channelNo,
          account_no: record.accountNo,
        })
        maxAmount = res.data.detail_list[0].cur_balance
        if (!maxAmount) {
          return this.$message.warn(`余额不足:${record.accountName}`)
        }
      } catch (e) {
      }
      this.formData = {
        id: record.id,
        accountName: record.accountName,
        accountNo: record.accountNo,
        oppAccountName: record.oppAccountName,
        oppAccountNo: record.oppAccountNo,
        amount: String(maxAmount),
        maxAmount
      }
      this.isModalCollectionVisible = true
    },


    handleSingleCancel() {
      this.isModalCollectionVisible = false;
    },

    handleKeyOk() {
      return this.handleBatch(this.selectedRowKeys);
    },

    handleKeyCancel() {
      this.isModalKeyVisible = false;
    },
    //签约
    handleApprovalRefresh(record) {
      this.api.payWithholdContractApproval(record.id).then(res => {
        if (res.code == 200) {
          this.$message.success('签约成功')
          this.isModalVisible = false;
          this.payWithholdContractList()
        } else {
          this.$message.error(res.info)
        }
      })
    },
    //认证
    handleAuthenticationRefresh(record) {
      this.api.payWithholdContractAuthentication(record.id).then(res => {
        if (res.code == 200) {
          this.$message.success('认证成功')
          this.isModalVisible = false;
          this.payWithholdContractList()
        } else {
          this.$message.error(res.info)
        }
      })
    },
  },

  computed: {
    selectedRows() {
      // TODO: pagination break this
      return this.selectedRowKeys.map(id => this.mergedData.find(item => item.id === id)).filter(row => row)
    },

  }
}
</script>

<style lang="less" scoped>
.selected-box {
  display: flex;
  flex-wrap: wrap;

  /deep/ .selected-box-item {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    width: 280px;
    margin-bottom: 20px;
    align-items: center;

    .left-item {
      margin-right: 15px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select-selection--single {
      width: 200px;
    }
  }
}

.table-box {
  .table-box-handle {
    margin-bottom: 20px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

@media print {
  @page {
    size: landscape; // 强制横向打印
    margin: 0
  }

  body {
    margin: 0.5cm;
  }

  .no-print {
    display: none;
  }
}

.del-dialog {

  /deep/ .ant-modal {
    //min-width: 1000px !important;

    .ant-modal-content {
      width: 100% !important;
    }

    .ant-row {
      display: flex;
      align-items: center;
    }
  }
}

.control-box {
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 5px;
  }


}

.control-box.canot-click {
  display: flex;
  align-items: center;
  pointer-events: none;
}


.container {
  .container-item {
    min-height: 20px;
    margin-bottom: 10px;
    // font-weight: bold;
    color: #2b2b2b;


    .ant-input,
    .ant-select,
    .ant-select-selection--single {
      width: 200px;
      height: 24px;
    }

    .ant-col-8,
    .ant-col-6,
    .ant-col-4 {
      text-align: right;
      padding-right: 20px;
      font-weight: bold;
    }
  }
}

.secondary {
  font-size: 0.8em;
  color: #6a6a6a;
}
</style>
