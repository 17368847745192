<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>原始订单</span>
          </div>
          <div class="right">
            <!-- 所属机构 -->
            <DepartmentChoose :department-code.sync="form.departmentCode" @change="departmentIdchange"></DepartmentChoose>
            <!--选择电商-->
            <a-select class="dianshang" placeholder="请选择电商" :dropdownMatchSelectWidth="false" v-model.trim="form.mchNo"
              :filter-option="filterOption" :show-search="true" @change="mchChange"
              style="min-width: 300px;margin-right:50px">
              <a-select-option v-for="item  in mchNolist" :title="item.label" :key="item.value" :value="item.value">
                <span><a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br /><a-tag color="blue"
                    v-if="item.fatherName">控</a-tag>{{ item.fatherName || "" }}</span>
              </a-select-option>
            </a-select>
          </div>
        </div>
      </template>

      <!-- 查询 -->
      <p class="tishi">提示：需优先选择机构电商信息后加载数据</p>
      <div class="formbox">
        <!-- 渠道编号 -->
        <div class="inputbox">
          <div class="lefttitle">渠道</div>
          <!-- <a-input v-model.trim="form.channelNo" placeholder="请输入渠道编号" /> -->
          <a-select placeholder="请选择渠道" v-model.trim="form.channelNo" style="width: 200px">
            <a-select-option v-for="(item, index) in channelList" :key="'channle' + index" :value="item.channelNo">
              {{ item.channelName }}
            </a-select-option>
          </a-select>
        </div>
        <!-- 时间 -->
        <div class="inputbox">
          <div class="lefttitle">下单日期</div>
          <a-range-picker valueFormat="YYYY-MM-DD" v-model.trim="time" @change="onChange" style="width: 200px" />
        </div>


        <!-- 订单编号 -->
        <div class="inputbox">
          <div class="lefttitle">订单编号</div>
          <a-input v-model.trim="form.orderNo" placeholder="请输入订单编号" />
        </div>
        <!-- 订单类型 -->
<!--        <div class="inputbox">-->
<!--          <div class="lefttitle">订单类型</div>-->
<!--          <a-select placeholder="请选择订单类型" v-model.trim="form.orderType" style="width: 200px">-->
<!--            <a-select-option v-for="item in orderTypelist" :key="item.value" :value="item.value"> {{ item.label }}-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </div>-->
        <!-- 订单状态-->
        <div class="inputbox">
          <div class="lefttitle">订单状态</div>
          <a-select placeholder="请选择订单状态" v-model.trim="form.orderStatus" style="width: 200px">
            <a-select-option v-for="item in orderStuatslist" :key="item.value" :value="item.value"> {{ item.label }}
            </a-select-option>

          </a-select>
        </div>
        <!-- 结算状态 -->
        <div class="inputbox">
          <div class="lefttitle">结算状态</div>
          <a-select v-model.trim="form.settleStatus" placeholder="请选择结算状态" style="width: 200px">
            <a-select-option v-for="item in settleStatuslist" :key="item.value" :value="item.value"> {{ item.label }}
            </a-select-option>

          </a-select>
        </div>
        <!-- 汇总订单编号 -->
        <div class="inputbox">
          <div class="lefttitle">汇总订单编号</div>
          <a-input v-model.trim="form.collectOrdersNo" placeholder="请输入汇总订单编号" />
        </div>

        <!-- 店铺ID -->
        <div class="inputbox">
          <div class="lefttitle">店铺ID</div>
          <a-input v-model.trim="form.shopId" placeholder="请输入店铺ID" />
        </div>
        <!-- 查询 -->

        <a-button class="inquire" @click="search" type="primary">
          查询
        </a-button>
        <!-- 重置 -->
        <a-button @click="reset" class="reset">重置</a-button>
        <!-- 售后状态 -->
        <!-- <div class="inputbox">
          <div class="lefttitle">售后状态</div>
          <a-select class="dianshang" placeholder="请选择售后状态" v-model.trim="form.orderType" style="width: 200px">
            <a-select-option value="jack">
              Jack
            </a-select-option>
            <a-select-option value="lucy">
              Lucy
            </a-select-option>
            <a-select-option value="disabled" disabled>
              Disabled
            </a-select-option>

            <a-select-option value="Yiminghe">
              yiminghe
            </a-select-option>
          </a-select>
        </div> -->
      </div>

      <!-- 导入box -->
      <div class="Toleadinto">
        <a-button class="inquire" @click="derive" type="primary" v-if="$authority('originalOrderOrderExport')">
          订单导出
        </a-button>
        <a-button class="inquire" @click="view" type="primary" v-if="$authority('originalOrderView')"> 查看 </a-button>
<!--        <a-button class="inquire" @click="Modifyhistoryview" type="primary"-->
<!--          v-if="$authority('originalOrderRevisionRecord')">-->
<!--          修改记录查看-->
<!--        </a-button>-->
        <a-button class="inquire" @click="add" type="primary" v-if="$authority('orgOrdeAdd')">新增</a-button>
        <a-button class="inquire" @click="update" type="primary" v-if="$authority('orgOrdeUpdate')">修改</a-button>
      </div>
      <div class="tablebox">
        <!-- 表格 -->
        <div class="tableBody">
          <a-table size="small" ref="table" :scroll="{ x: 2200, }" :rowKey="(record, index) => `${record.id}`" paginati
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: true,
              onChange: onSelectChange,
            }
              " :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false">
          </a-table>
          <div>仅展示90天内的订单</div>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
          class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>

    <div>
      <a-modal
          v-model="addVisible"
          :title="addForm.id ? '原始订单修改' : '原始订单新增'"
          :centered="true"
          @ok="addhandleOk"
          @cancel="addhandlecancel"
      >
        <a-form :form="addForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @>
          <a-form-item label="电商">
            <a-select class="dianshang" placeholder="请选择电商" :dropdownMatchSelectWidth="false" v-model.trim="addForm.mchNo"
                      :filter-option="filterOption" :show-search="true"
                      style="min-width: 300px;margin-right:50px">
              <a-select-option v-for="item  in mchNolist" :title="item.label" :key="item.value" :value="item.value">
                <span><a-tag color="purple">融</a-tag>{{ item.value }}-{{ item.label }}<br/><a-tag color="blue"
                                                                                                   v-if="item.fatherName">控</a-tag>{{ item.fatherName || "" }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="下单时间">
            <a-date-picker valueFormat="YYYY-MM-DD" v-model="addForm.orderTime"/>
          </a-form-item>

          <a-form-item label="订单编号">
            <a-input placeholder="请输入订单编号" v-model.trim="addForm.orderNo"/>
          </a-form-item>

          <a-form-item label="订单金额">
            <a-input-number placeholder="请输入订单金额" v-model.trim="addForm.orderAmount"/>
          </a-form-item>


        </a-form>

      </a-modal>
    </div>
  </div>

</template>

<script>
import {handleChannelNo} from '@/utils/commonMethods'
import Balance from '@/view/components/Balance.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";


export default {
  name: 'originalOrderPage',
  components: {
    DepartmentChoose,
    Balance
  },
  data() {
    return {
      value1: '',
      selectedRowKeys: [],
      addVisible: false,
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      institutiontrees: {},
      time: [], // 时间
      total: 0, // 数量
      mchNolist: [],
      channelList: JSON.parse(this.$session.getItem('coreCompanyList')),
      addForm: {
        id: undefined,
        orderNo: "",
        orderTime: null,
        mchNo: "",
        orderAmount: 0.00,
      },
      form: {
        mchNo: undefined, //电商编号
        departmentCode: '', //机构id
        channelNo: undefined, // 渠道编号
        shopId: '', // 店铺id
        orderNo: '', // 订单编号
        orderType: undefined, // 订单类型
        orderStatus: undefined, // 订单状态
        settleStatus: undefined, // 结算状态
        collectOrdersNo: '', // 汇总订单编号
        pageSize: 10,
        pageNo: 1,
        startTime: '',
        endTime: '',
      },
      // 订单状态list
      orderTypelist: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "普通订单",
          value: 0,
        },
        {
          label: "秒杀订单",
          value: 1,
        },
        {
          label: "预售订单",
          value: 2,
        },
        {
          label: "虚拟商品订单",
          value: 3,
        },
        {
          label: "分销订单",
          value: 4,
        },
        {
          label: "赊销订单",
          value: 5,
        },
        {
          label: "电子券（poi核销）",
          value: 6,
        },
        {
          label: "三方核销",
          value: 7,
        },
      ], orderStuatslist: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "待支付",
          value: 0,
        },
        {
          label: "已支付",
          value: 1,
        },
        {
          label: "待发货",
          value: 2,
        },
        {
          label: "已发货",
          value: 3,
        },
        {
          label: "已完成",
          value: 4,
        },
        {
          label: "已关闭",
          value: 5,
        }
      ],
      // 结算状态
      settleStatuslist: [{
        label: "全部",
        value: ""
      }, {
        label: "未结算",
        value: "0",
      },
      {
        label: "已结算",
        value: "1",
      },],
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
          width: 75,
          ellipsis: true,
        },
        {
          title: '订单编号',
          dataIndex: 'orderNo',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
          ellipsis: true,
        },
        {
          title: '下单时间',
          dataIndex: 'orderTime',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
          // width: 100,
          ellipsis: true
        },
        {
          title: '商品',
          dataIndex: 'goodsName',
          align: 'center',
          width: 200,
          ellipsis: true,
        },
        // {
        //   title: '订单类型',
        //   dataIndex: 'orderType',
        //   align: 'center',
        //   customRender: (text) => {
        //     if (Number(text) == 0) {
        //       return '普通订单'
        //     } else if (Number(text) == 1) {
        //       return '秒杀订单'
        //     } else if (Number(text) == 2) {
        //       return '预售订单'
        //     } else if (Number(text) == 3) {
        //       return '资金类订单'
        //     } else if (Number(text) == 4) {
        //       return '分销订单'
        //     } else if (Number(text) == 5) {
        //       return '赊销订单'
        //     } else if (Number(text) == 6) {
        //       return '电子券（poi核销）'
        //     } else if (Number(text) == 7) {
        //       return '"三方核销'
        //     } else if (Number(text) == 8) {
        //       return '"虚拟商品订单'
        //     }
        //   },
        //   width: 90
        // },
        {
          title: '订单金额',
          dataIndex: 'orderAmount',
          align: 'center',
          // customRender: (text) => (text ? text : '---'),
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '预计结算额',
          dataIndex: 'settleAmount',
          align: 'center',
          // customRender: (text) => (text ? text : '---'),
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return '待支付'
            } else if (Number(text) == 1) {
              return '已支付'
            } else if (Number(text) == 2) {
              return '待发货'
            } else if (Number(text) == 3) {
              return '已发货'
            } else if (Number(text) == 4) {
              return '已完成'
            } else if (Number(text) == 5) {
              return '已关闭'
            }
          },
          width: 90

        },
        {
          title: '售后状态',
          dataIndex: 'refundStatus',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return '未退款'
            }
            if (Number(text) == 1) {
              return '已退款'
            }
          },
          width: 90,
          ellipsis: true
        },
        {
          title: '结算状态',
          dataIndex: 'settleStatus',
          align: 'center',
          customRender: (text) => {
            if (Number(text) == 0) {
              return '未结算'
            }
            if (Number(text) == 1) {
              return '已结算'
            }
          },
          width: 90,
          ellipsis: true

        },
        {
          title: '汇总订单',
          dataIndex: 'collectOrdersNo',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
        },
        {
          title: '店铺ID',
          dataIndex: 'shopNo',
          align: 'center',
          customRender: (text) => (text ? text : '---'),
          width: 120,
          ellipsis: true
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
          width: 160
        },
      ],
      data: [],
    }
  },
  created() {
    this.getmchshopsApi()

  },
  mounted() {
    // if (sessionStorage.getItem('msgInfo')) {
    //   this.form = this.$store.state.searchMsgInfo
    //   this.time = this.form.time
    // }
    this.getoriorderPage()
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    filterOption(input, option) {
      let val = input?.trim().toLowerCase()
      if (val.length < 1) return false;
      const children = option.componentOptions.children[0].children.map(m => m.text).filter(m => m)

      return (
          children.find(m => m.toLowerCase().indexOf(val) >= 0)
      );
    },
    // 获取所属机构下拉框数据
    async departmentIdchange() {
      await this.getmchshopsApi()
      // 检查当前列表是否包含已选中店铺，如果有就不变，否则刷新
      if (!this.mchNolist.find(i => i.value === this.form.mchNo)) {
        // 默认选中第一家 或清空，然后
        this.form.mchNo = this.mchNolist[0]?.value || ""
        this.mchChange()
      }
    },
    // 选中的电商变了
    mchChange() {
      this.getoriorderPage()
    },
    // 获取店铺信息列表
    async getmchshopsApi() {
      this.mchNolist = []

      const res = await this.api.mchshopsApi({
        pageNo: 1,
        pageSize: 10000,
        mchType: 1, //默认融资电商
        departmentCode: this.form.departmentCode,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      // mchNolist
      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.mchName,
        value: x.mchNo
      }))

      // console.info(this.mchNolist)
    },
    // 获取table列表
    async getoriorderPage() {
      this.data = []
      if (!this.form.mchNo) {
        this.$message.info("请选择电商")
        return
      }
      let data = JSON.parse(JSON.stringify(this.form))
      delete data.departmentId
      const res = await this.api.oriordersApi(data)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },
    pagechange(val) {
      this.form.pageNo = val
      this.getoriorderPage()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getoriorderPage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      // if (selectedRows.length > 1) {
      //   //获取选中的数据的key
      //   var selectNumber = this.selectedRowKeys[1]
      //   //清空选中的key
      //   this.selectedRowKeys = []
      //   //选中的数据的key重新赋值给selectedRowKeys
      //   this.selectedRowKeys.push(selectNumber)
      // }
      // //获取选中的数据详细信息
      // this.selectedRows = selectedRows[0]
      // console.log(this.selectedRows, this.selectedRowKeys);
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectedRows.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getoriorderPage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getoriorderPage()
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (idToFind == {}) return
      if (dataObj.coreDepartment.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

    search() {
      this.form.pageNo = 1
      this.getoriorderPage()
    },
    onChange(value) {
      this.form.startTime = value[0] + " 00:00:00"
      this.form.endTime = value[1] + " 23:59:59"
    },

    // 重置
    reset() {
      this.form = {
        departmentCode: '', //机构id
        channelNo: '', // 渠道编号
        shopId: '', // 店铺id
        orderNo: '', // 订单编号
        orderType: undefined, // 订单类型
        settleStatus: undefined, // 结算状态
        collectOrdersNo: '', // 汇总订单编号
        pageSize: 10,
        pageNo: 1,
      }
      this.time = ''
      this.form.departmentCode = this.$session.getItem('companyCode')
      this.getoriorderPage()
    },
    // 订单导出
    async derive() {
      // if (this.selectedRows.length == 0 || !this.selectedRows) {
      //   this.$message.warning('请先选择账户')
      //   return
      // }
      if (!this.form.mchNo) {
        return this.$message.warning('请先选择电商')
      }
      let arr = []
      this.selectedRows.forEach((item) => {
        arr.push(item.id)
      })
      // 不根据departmentId
      let data = JSON.parse(JSON.stringify(this.form))
      delete data.departmentId
      const res = await this.api.oriorderexportsApi({
        // pageNo: this.form.pageNo,
        // pageSize: this.form.pageSize,
        ...data,
        mchNo: this.form.mchNo,
        ids: arr,

      })

      this.$uploadfile.ExcelDownload('原始销售', res)
    },
    //订单新增
    add() {
      this.addVisible = true
    },
    //订单新增
    update() {

      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择一笔订单')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('只能选择一笔订单')
        return
      }
      this.addVisible = true
      this.addForm.id = this.selectedRows[0].id
      this.addForm.orderNo = this.selectedRows[0].orderNo
      this.addForm.orderTime = this.selectedRows[0].orderTime
      this.addForm.mchNo = this.selectedRows[0].mchNo
      this.addForm.orderAmount = this.selectedRows[0].orderAmount
      console.log(this.addForm)
    },

    addhandlecancel: async function () {
      this.addVisible = false;
      this.addForm ={}
    },
    addhandleOk: async function () {

      console.log('addhandleOk', this.addForm);
      let res
      if (this.addForm.id) {
        res = await this.api.oriordersUpdate(this.addForm);
      } else {
        this.addForm.orderTime = this.addForm.orderTime + " 00:00:00"
        res = await this.api.oriordersAdd(this.addForm);
      }

      if (res.code == 200) {
        this.addVisible = false;
        this.addForm ={}
      } else {
        return this.$message.error("新增失败");
      }


    },
    // 查看
    view() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择一笔订单')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('只能选择一笔订单')
        return
      }
      // 存储筛选条件
      this.form.time = this.time
      // this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/orders/ViewOriginalOrder',
        query: {
          id: this.selectedRows[0].id,
        },
      })
    },
    //  修改记录查看
    Modifyhistoryview() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择一笔订单');
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('只能选择一笔订单');
        return
      }
      // this.$router.push({
      //   path: '/orders/OriginalOrderRevisionHistory',
      //   query: {
      //     id: this.selectedRows[0].id,
      //     departmentId: this.selectedRows[0].departmentId
      //   }
      // })
      // 存储筛选条件
      this.form.time = this.time
      // this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/orders/OriginalOrderRevisionHistory',
        query: {
          orderNo: this.selectedRows[0].orderNo,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.tishi {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.formbox {
  // padding-top: 35px;
  display: flex;
  flex-wrap: wrap;

  .inputbox {
    width: 300px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 200px;
    }
  }

  .reset {
    width: 64px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  button {
    margin-bottom: 20px;
  }
}

.Toleadinto {

  // padding-left: 30px;
  // margin-bottom: 20px;
  button {
    margin-bottom: 20px;
  }
}

.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    flex-wrap: wrap;

    .dianshang {
      margin-left: 50px;
    }
  }
}
</style>
