<script>

export default {
  name: 'IncomeWhitelistBtn',
  props: {
    accountNo: {
      required: true,
      type: String,
    },
    accountName: {
      required: true,
      type: String
    },
    accountProperty: String, // 1-个人， 2-对公

  },
  data() {
    return {
      showLegal: this.accountProperty === '2',
      whiteListOptions: this.$store.state.incomingWhitelistAccounts, // 来账白名单选项
      whiteList: [], // 来账白名单选中明细
      configWhiteList: [], // 来账白名单选中明细
      whiteListVisible: false, // 是否显示来账白名单
      smallAmountAuth: false, // 是否通过小额认证
      legalRepAuth: false, // 是否通过法人打款认证
      corporateAuth: false, // 是否通过公户打款认证
    }
  },
  methods: {
    async showConfigModal() {
      this.configWhiteList = []
      // 从后台获取来账白名单清单
      let info = await this.api.bmdListApi(this.accountNo);
      if (info.code === 200) {
        this.whiteList = [...info.data];
        this.configWhiteList = [...info.data];
      } else {
        this.whiteList = []
        this.configWhiteList = []
      }
      //查看是否小额认证通过
      await this.getAmountAuthApi();

      this.whiteListVisible = true
    },

    // 小额认证状态查询
    async getAmountAuthApi() {
      const rsp = await this.api.getAmountAuthApi(this.accountNo);
      if (rsp.code === 200) {
        this.smallAmountAuth = rsp.data.smallAmountAuth
        this.legalRepAuth = rsp.data.legalRepAuth
        this.corporateAuth = rsp.data.corporateAuth
        this.showLegal = rsp.data.accountProperty === '2'
      } else {
        this.smallAmountAuth = false
        this.legalRepAuth = false
        this.corporateAuth = false
      }
    },

    whiteListSelectAll() {
      this.whiteList = [...new Set(this.whiteListOptions.flatMap(item => item.accounts))]
    },
    whiteListDisSelectAll() {
      this.whiteList = []
    },

    async confirmWhiteList() {
      console.info("this.whiteList", this.whiteList)
      console.info("this.configWhiteList", this.configWhiteList)
      // 去除已经配置的名单
      this.whiteList = this.whiteList.filter(item => !this.configWhiteList.includes(item))
      const res = await this.api.bmdAddApi({
        accountNo: this.accountNo,
        oppAccountName: this.whiteList
      });
      if (res.code === 200) {
        this.$msgbox({
          type: "info",
          customClass: 'msg-box-wide',
          message: <a-descriptions title="白名单配置结果" bordered>
            <a-descriptions-item label="成功账户" span="3">
              {res.data.succeed}
            </a-descriptions-item>
            <a-descriptions-item label="失败账户" span="3">
              {res.data.fail}
            </a-descriptions-item>

          </a-descriptions>,
          callback: () => {
          }
        })
        this.whiteListVisible = false;


      } else {
        this.$message.error(res.info);
      }

      this.whiteListVisible = false

    },

    async getLegalPerson() {
      let res = await this.api.getLegalPerson(this.accountNo)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.whiteList.push(res.data.custname)
      this.whiteList = [...new Set(this.whiteList)]
    },


    async handleSmallAmountAuth() {
      const rsp = await this.api.smallAmountAuthApi(this.accountNo);
      if (rsp.code !== 200) {
        this.$message.error(rsp.info)
        await this.handleLegalRepAuth();
      } else {
        if (!rsp.data) {
          this.$message.error("小额打款认证失败")
          await this.handleLegalRepAuth();
        }
      }
      await this.getAmountAuthApi();
    },

    async handleLegalRepAuth() {
      await this.api.legalPersonAuthApi(this.accountNo);
    },
  },
  computed: {},
}
</script>

<template>
  <span v-if="$authority('incomeWhitelist')" @click="showConfigModal">
    <slot>
      <a-button type="link">
        <a-icon type="usergroup-add"/>
      </a-button>
    </slot>

    <a-modal :maskClosable="true" title="来账白名单" :centered="true" :visible="whiteListVisible">
      <p>入账户名：{{ accountName }} </p>
      <p>入账账号：{{ accountNo }}</p>


      <p>
        小额认证：
        <span v-if="smallAmountAuth  " style=" color: green;">已认证</span>

        <span v-if="!smallAmountAuth && !legalRepAuth" style=" color:red;"> 未认证</span>
        <span v-if="!smallAmountAuth && legalRepAuth"
              style=" color:orange;"> 同名户打款未认证，法定代表人打款已认证</span>

        <a-button type="link" v-if="!smallAmountAuth" icon="reload" @click="handleSmallAmountAuth"/>
      </p>

      <el-select style="width: 100%"
                 v-model="whiteList" multiple placeholder="请选择" allow-create filterable>
        <el-option-group
            v-for="group in whiteListOptions"
            :key="group.label"
            :label="group.label"
        >
          <el-option
              v-for="(item,index) in group.accounts"
              :key="group.label + index"
              :value="item"
          >
          </el-option>
        </el-option-group>
      </el-select>
      <br/>
      <a-button type="link" @click="whiteListSelectAll">全部选中</a-button>
      <a-button type="link" @click="whiteListDisSelectAll">全部取消</a-button>
      <a-button type="link" v-if="showLegal" @click="getLegalPerson">填入法定代表人</a-button>


      <template v-slot:footer>
        <!-- 来账白名单 -->
        <div class="custom-footer">
          <a-button @click="whiteListVisible = false">取消</a-button>
          <a-button @click="confirmWhiteList" type="primary">配置</a-button>
        </div>
      </template>
    </a-modal>
  </span>
</template>

<style scoped lang="less">

</style>