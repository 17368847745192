<script>
export default {
  name: "InputBalance",
  model: {prop: 'amount', event: 'update:amount'},
  data() {
    return {
      value: undefined
    }
  },
  emits:['blur'],
  props: {
    amount: String,
    max: Number,
    placeholder: {
      default: "请输入金额"
    },
    disabled: Boolean,
    alignLeft: Boolean
  },
  mounted() {
    this.value = this.amount
  },
  methods: {
    parse(text) {
      let inputString = text
      if (typeof inputString === 'undefined') {
        inputString = "0.00"
      } else {
        inputString = String(text)
      }
      let str = inputString.replaceAll(/[^\d\\.]/g, '')

      const reg = /^([0-9]*(\.[0-9]{0,2})?).*$/
      if (isNaN(Number(str)) && str.match(reg)) { // 提取有效部分数值
        str = str.match(reg)[1]
      }
      return Number(str) || 0
    },
    format(num) {
      // 强制添加两位小数和千分位
      const unformatted = Number(num).toFixed(2)
      return unformatted.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onBlur() {
      this.value = this.format(this.parse(this.value))
      this.emitChange()
      this.$emit('blur')
    },
    onFocus() {
      // 去除添加两位小数和千分位
      this.value = String(this.parse(this.value))
      this.$nextTick(() => {
        this.$refs.input.select()
      })
    },
    emitChange() {
      const value = String(this.parse(this.value))
      if (value !== this.amount) {
        this.$emit('update:amount', value)
      }
    },
    toMax() {
      this.value = this.format(this.max)
      this.emitChange()
    }
  },
  watch: {
    amount(val, old) {
      if (Number(val) !== Number(old)) {
        this.value = this.format(val)
      }
    },
    max(val, old) { // 当前输入金额不超过最大金额
      if (Number(val) < Number(this.amount)) {
        this.toMax()
      }
    },
  },
  computed: {
    placeholderText() {
      if (this.max) {
        return `最多${this.format(this.max)}`
      } else {
        return this.placeholder
      }
    },
    scaleText() {
      let val = this.parse(this.value)
      let scale = -1
      while (val > 0) {
        val = Math.floor(val / 10)
        scale++
      }
      switch (scale) {
        case -1:
          return ""
        case 0:
          return "个"
        case 1:
          return "十"
        case 2:
          return "百"
        case 3:
          return "千"
        case 4:
          return "万"
        case 5:
          return "十万"
        case 6:
          return "百万"
        case 7:
          return "千万"
        case 8:
          return "亿"
        case 9:
          return "十亿"
        case 10:
          return "百亿"
        case 11:
          return "千亿"
        case 12:
          return "万亿"
        case 13:
          return "十万亿"
        case 14:
          return "百万亿"
        default:
          return "..."
      }
    },
  }
  ,
}
</script>

<template>
  <a-tooltip :trigger="['focus','click']" placement="rightTop">
    <a-input v-model="value" ref="input" prefix="￥" @blur="onBlur" @focus="onFocus" :max-length='12'
             :placeholder="placeholderText" :disabled="disabled" :class="alignLeft?'align-left':'align-right'">
      <a-button slot="suffix" v-if="max!==undefined" type="link" @click="toMax" :disabled="disabled" size="small">最大</a-button>
    </a-input>
    <template slot="title">
      {{ scaleText }}
    </template>
  </a-tooltip>
</template>

<style scoped lang="less">

.align-left /deep/ .ant-input {
  text-align: left !important;
}
.align-right /deep/ .ant-input {
  text-align: right !important;
}

.ant-input-affix-wrapper {
  /deep/ .ant-input:not(:last-child) {
    padding-right: 45px;
  }

  /deep/ .ant-input-suffix {
    margin: 0;
    padding: 0;

    .ant-btn {
      padding: 0;
      margin: 0;

    }
  }

}
/deep/ .ant-btn.ant-btn-link.ant-btn-sm {
  span{
    font-size: 12px !important;
  }
}
</style>