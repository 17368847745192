<template>
  <div class="inline">
    <a-popover @visibleChange="visibleChange">
      <template slot="title">
        <a-tag color="green" v-if="isWallet && accountClass === '0'">活期A1</a-tag>
        <a-tag color="blue" v-if="isWallet && accountClass === '6'">冻结A2</a-tag>
        <a-tag color="purple" v-if="isWallet && accountClass === '7'">贷款A3</a-tag>
        <a-tag color="yellow" v-if="isWallet && accountClass === '2'">保证A6</a-tag>
        <a-tag color="blue" v-if="isWallet && accountClass === '8'">分户A7</a-tag>
        <a-tag color="cyan" v-if="isWallet && accountClass === '9'">待清算</a-tag>
        {{ walletTitle }}

        <income-whitelist-btn v-if=" isWallet"
                          :account-no="accountNo"
                          :account-name="accountName"
                          :account-property="accountProperty"
        ></income-whitelist-btn>

      </template>
      <template slot="content">
        <p v-if="channelName"> 归属渠道：<span>{{ channelName }}</span></p>
        <template v-if="isWallet">
          <p v-if="balance!==undefined || enableQueryBalance"> 余额：
            <span v-if="isWallet && accountClass === '6' && !$authority('A2BalanceVisible')"
                  style="display:inline-block; width:120px;">-----</span>
            <span v-else>
              <a-spin size="small" v-if="displayBalance===undefined"/>
              <Balance v-else :amount="displayBalance" :align-right="false"
                      :dcflag="accountClass === '7' ? 'c' : ''" inline></Balance>
              <withdraw-btn v-if="accountClass==='0' && showWithdrawBtn"
                  :channel-no="channelNoMaybe" :account-no="accountNo"
              ></withdraw-btn>
            </span>
          </p>
        </template>
        <p> 户名：<span>{{ accountName }}</span>
          <a-icon type="copy" v-clipboard:copy="accountName" v-clipboard:success="onCopy"
                  v-clipboard:error="onCopyErr"/>
        </p>
        <p> 账号：<span>
          <span class="seg" v-for="(seg,index) in accountNoSeg" :key="index">{{seg}}</span>
        </span>
          <a-icon type="copy" v-clipboard:copy="accountNo" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr"/>
        </p>
        <p v-if="showBank">
          开户银行：<span>{{ displayBankInfo?.bankName || '' }}</span>
          <a-icon type="copy" v-clipboard:copy="displayBankInfo?.bankName || ''" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr"/>
        </p>
        <p v-if="showBank">
          大额行号：<span>{{ displayBankInfo?.bankNo || '' }}</span>
          <a-icon type="copy" v-clipboard:copy="displayBankInfo?.bankNo || ''" v-clipboard:success="onCopy" v-clipboard:error="onCopyErr"/>
        </p>
      </template>
      <div class="account_no">
        <a-tag color="pink" v-if="!isWallet">银行卡</a-tag>
        <a-tag color="green" v-else-if="isWallet && accountClass === '0'">活期A1</a-tag>
        <a-tag color="blue" v-else-if="isWallet && accountClass === '6'">冻结A2</a-tag>
        <a-tag color="geekblue" v-else-if="isWallet && accountClass === '8'">分户A7</a-tag>
        <a-tag color="purple" v-else-if="isWallet && accountClass === '7'">贷款A3</a-tag>
        <a-tag color="yellow" v-else-if="isWallet && accountClass === '2'">保证A6</a-tag>
        <a-tag color="cyan" v-else-if="isWallet && accountClass === '9'">待清算</a-tag>
        <a-tag color="blue" v-else>钱包</a-tag>
        <span v-if="showAccountNo" class="account_no">
          {{accountNo}}
        </span>
        <span v-if="showAccountName" class="account_no">&nbsp;&nbsp;{{ accountName }}</span>
        <template v-if="showBalance && balance !== undefined">
          <span v-if="isWallet && accountClass === '6' && !$authority('A2BalanceVisible')"
                style="display:inline-block; width:120px;">-----</span>
          <Balance v-else style="display:inline-block; width:120px;" :amount="displayBalance"
                   :dcflag="this.accountClass === '7' ? 'c' : ''"></Balance>
        </template>
      </div>
    </a-popover>
  </div>
</template>
<script>
import Balance from "@/view/components/Balance.vue";
import WithdrawBtn from "@/view/components/WithdrawBtn.vue";
import IncomeWhitelistBtn from "@/view/components/IncomeWhitelistBtn.vue";

export default {
  name: "WalletInfo",
  components: {IncomeWhitelistBtn, WithdrawBtn, Balance},
  props: {
    isWallet: {
      type: Boolean,
      default: true, // 是否电子钱包， false表示行外账号
    },
    accountName: String, // 户名
    accountNo: {
      // 账号
      required: true,
      type: String,
    },
    showBank: {
      type: Boolean,
      default: true,
    },
    bankName: {
      // 开户行名称
      type: String,
      // default: "浙江稠州商业银行股份有限公司",
    },
    bankNo: {
      // 开户行大额行号
      type: String,
      // default: "313338707013",
    },
    balance: Number, // 余额
    showBalance: { // 显示钱包余额
      type: Boolean,
      default: true,
    },
    enableQueryBalance: Boolean, //允许查询余额
    accountClass: String, // 0-活期 2-保证 6-冻结 7-贷款
    accountProperty: String, // 1-个人， 2-对公
    channelName: String, // 渠道
    showAccountNo: {
      type: Boolean,
      default: true,
    },
    showAccountName: {
      type: Boolean,
      default: false,
    },
    showWithdrawBtn: Boolean,
    channelNo: String,
    appNo: String,
    idNo: String,
  },
  data() {
    return {
      reloadingBalanceHandler: undefined, // 刷新余额的Window handler
      coreCompanyList: [],
      balanceUpdated: undefined,
    };
  },
  created() {
    this.coreCompanyList = JSON.parse(this.$session.getItem("coreCompanyList"));
  },
  computed: {
    accountNoSeg(){
      return [this.accountNo.substring(0,6),this.accountNo.substring(6,12),this.accountNo.substring(12)]
    },
    walletTitle() {
      let title = "";
      title += this.accountProperty === "1" ? "个人" : "";
      title += this.accountProperty === "2" ? "对公" : "";
      title += this.isWallet ? "钱包" : "账户";
      // title += "信息"
      return title;
    },

    displayBalance(){
      return this.balanceUpdated?? this.balance
    },
    displayBankInfo() {
      let channelNo = this.channelNo || this.accountNo.substring(0, 4)
      if (this.isWallet) {
        let coreInfo = this.coreCompanyList.find(item => item.channelNo === channelNo) ?? {}
        return {
          bankName: coreInfo?.czcbBankName || "浙江稠州商业银行股份有限公司",
          bankNo: coreInfo?.czcbBankNo || "313338707013",
        };
      }

      return {
        bankName: this.bankName,
        bankNo: this.bankNo,
      }
    },
    channelNoMaybe(){
      if(this.channelNo){
        return this.channelNo
      }
      return this.accountNo.substring(0,4)
    }
  },
  methods: {
    onCopy() {
      this.$message.info("已复制");
    },
    onCopyErr() {
      this.$message.info("复制失败");
    },
    async reloadBalance(ignoreHis) {
      if(!this.isWallet) // 非钱包、非展示余额
        return
      if(!this.enableQueryBalance) // 不允许查询余额
        return
      if(!ignoreHis && (this.balanceUpdated !== undefined || this.balance !== undefined))  // 非强制刷新，且余额已获取
        return
      this.balanceUpdated = undefined
      let res = await this.api.accountqueryBalancedApi({
        channel_no: this.channelNo,
        account_no: this.accountNo
      }, true)
      if (res.code !== 200) {
        // this.$message.error(res.info)
        this.balanceUpdated = 0
        return
      }
      this.balanceUpdated = res.data.detail_list[0].avail_balance
    },
    visibleChange(visible){
      if(visible){
        this.reloadingBalanceHandler = window.setTimeout(this.reloadBalance, 500)
      }else{
        if(this.reloadingBalanceHandler){
          window.clearTimeout(this.reloadingBalanceHandler)
          this.reloadingBalanceHandler = undefined
        }
      }
    }
  },
};
</script>
<style scoped lang="less">
i,
.account_no {
  cursor: pointer;
}

.ant-tag {
  margin: 0;
}

.account-info-table {
  display: table;
  width: 100%;
  border-spacing: 0; /* 去除表格的单元格间距 */
  border-collapse: collapse; /* 边框重叠处理 */
}

.account_no span:nth-child(3) {
  display: block;
}
.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 8px; /* 调整单元格内边距 */
  border-bottom: 1px solid #ccc; /* 添加底部边框 */
  vertical-align: middle; /* 垂直居中对齐 */
  text-align: center; /* 文本居中对齐 */
}

.table-cell:last-child {
  border-right: none; /* 最后一列去除右侧边框 */
}

.table-cell:first-child {
  border-left: none; /* 第一列去除左侧边框 */
}

div.inline {
  display: inline-block;
}
span.seg:not(:last-child){
  padding-right: 6px;
}

</style>