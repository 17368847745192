<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <span>出资人</span>
          <div class="float-right">
            机构：<DepartmentChoose  style="min-width: 300px;" :department-code.sync="form.departmentCode" @change="handleQuery"></DepartmentChoose>
          </div>
        </div>

      </template>
        <!-- 头部筛选区域 -->
        <div class="search-box">
          <a-form-model layout="inline">
            <a-form-model-item label="出资人">
              <a-input v-model.trim="form.lenderName" placeholder="请输入出资人名称"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" @click="handleQuery" icon="search">查询</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>

        <div class="action-box">
          <a-button @click="register" type="primary" v-if="$authority('lender_add')" icon="plus">登记</a-button>
        </div>
        <div class="table-box">
          <a-table :columns="columns" :data-source="tableData" :row-key="(record) => record.id" :pagination="false"
                   bordered
                   size="small">
            <template slot="action" slot-scope="text, record">
              <a-button type="link" v-if=" $authority('lender_delete') " @click="del(record)">
                <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
              </a-button>
            </template>
          </a-table>
          <LcfsPagination :page-no.sync="pagination.pageNo" :page-size.sync="pagination.pageSize"
                          :total="total" :fn="fetchData"></LcfsPagination>
        </div>

      <a-modal
          title="出资人登记"
          :visible="registerVisible"
          @ok="submit"
          @cancel="registerVisible = false"
      >
        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item ref="account_name" class="account_name" label="出资人钱包" prop="account_name">
            <WalletChooseDialog v-model="add.accountName" :searchable="true" account-class="0"
                                @chooseDone="onWalletChosen">
            </WalletChooseDialog>
            <WalletInfo v-if="add.account_no" :account-class="add.accountClass" :account-no="add.account_no"
                        :account-name="add.account_name"></WalletInfo>
          </a-form-model-item>
        </a-form-model>
      </a-modal>

    </a-card>

  </div>

</template>

<script>
import {dict_rawdata as busiDict} from "@/utils/bizDict/rawdata";
import Balance from "@/view/components/Balance.vue";
import authority from "@/utils/authority";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import LcfsPagination from "@/view/components/LcfsPagination.vue";
import ChannelInfo from "@/view/components/ChannelInfo.vue";

export default {
  name: 'lender',
  components: {
    ChannelInfo,
    LcfsPagination,
    WalletChooseDialog, WalletInfo,
    DepartmentChoose,
    // eslint-disable-next-line vue/no-unused-components
    Balance
  },
  computed: {
    busiDict() {
      return busiDict
    }
  },
  data() {
    return {
      labelCol: {span: 6},
      wrapperCol: {span: 10},
      registerVisible: false,
      tableData: [],
      add: {id: undefined, lenderName: undefined, accountNo: undefined, accountName: undefined},
      total: 0,
      pagination: {pageNo: 1, pageSize: 10},
      form: {departmentCode: undefined, lenderName: undefined},
      columns: [
        {
          title: 'No', dataIndex: 'No', align: 'center', width: 70,
          customRender: (text, record, index) =>
              `${Number(this.pagination.pageNo - 1) * Number(this.pagination.pageSize) + Number(index + 1)}`
        },
        {title: '出资人名称', align: 'center', dataIndex: 'lenderName'},
        {
          title: '出资账号', dataIndex: 'accountNo', align: 'center',
          customRender: (text, record) =>
              <WalletInfo accountName={record.accountName} accountNo={record.accountNo}
                          accountClass={record.accountClass}/>
        },
        {
          title: "机构", dataIndex: "departmentId", align: "center",
          customRender: (text) => <DeptInfo dept-id={text}/>
        },
        {
          title: '渠道', dataIndex: 'channelNo', align: 'center', width: 160,
          customRender: (text) => <ChannelInfo channel-no={text}></ChannelInfo>
        },
        ...(authority('lender_edit') || authority('lender_delete') ? [
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            align: 'center',
            fixed: 'right',
            scopedSlots: {customRender: 'action'}
          }

        ] : [])
      ],
    };
  },
  created() {
    this.fetchData()
  },
  mounted() {
  },
  activated() {
  },
  methods: {
    // 查询
    async handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      await this.fetchData()
    },
    async submit() {
      const res = await this.api.lenderAddOrEditApi(this.add)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success(res.info)
      this.registerVisible = false
      await this.fetchData()
    },

    onWalletChosen: async function (wallet) {
      this.add.accountNo = wallet.accountNo
      this.add.accountName = wallet.accountName
      this.add.lenderName = wallet.accountName
      this.add.accountClass = wallet.accountClass
    },

    //编辑
    async register(data) {
      this.registerVisible = true

    },
    //编辑
    async edit(data) {
      this.add = data
      this.registerVisible = true
    },
    //删除
    async del(data) {
      this.$confirm({
        title: '是否确认删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.api.lenderDeleteApi(data.id).then((res) => {
            if (res.code !== 200) {
              this.$message.warning(res.info)
            } else {
              this.$message.success(res.info)
              this.fetchData()
            }
          })
        },
        onCancel() {
        },
      });
    },
    // 获取table列表
    async fetchData() {
      // 检查必填项
      const res = await this.api.lenderPageApi({
        ...this.form,
        ...this.pagination
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.total = res.data.total
      this.tableData = res.data.records
      return true
    },

  },
}
</script>

<style lang="less" scoped>
.float-right {
  float: right;
}

.search-box {
  display: flex;
  flex-wrap: wrap;
}

.action-box {
  padding-top: 10px;
  margin-bottom: 20px;
}
</style>
