<script>
import WalletInfo from "@/view/components/WalletInfo.vue";
import Balance from "@/view/components/Balance.vue";
import PaymentSms from "@/view/components/PaymentSms.vue";
import {Modal} from "ant-design-vue";

export default {
  components: {PaymentSms, Balance, WalletInfo},
  emits: ['back', 'settle'],
  props: {
    estimate: Object,
  },
  data() {
    return {
      settleForm: {
        verifyCode: undefined,
      },
      estimateDetail: undefined,
      feeDetailCollapse: true, // 折叠详细费用
    }
  },
  mounted() {
    //TODO: 调后台接口试算
    this.fetchEstimateDetail()
  },
  methods: {
    round(amount, precision) {
      const scale = Math.pow(10, precision ?? 2)
      return Math.round(Number(amount) * scale) / scale
    },

    async fetchEstimateDetail() {
      this.estimateDetail = undefined
      if (!this.estimate) return;
      const res = await this.api.transactionSettleRepayEstApi(this.estimateForm)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.estimateDetail = res.data
    },
    async settle() {
      const result = await this.api.transactionSettleRepayApi({
        ...this.estimateForm,
        ...this.settleForm,
      })
      if (result.code !== 200) { // 接口报错
        Modal.error({
          title: "支付异常", content: result.info,
          okText: "确定",
          onOk: () => {
            this.$router.go(-1)
          },
        })
      } else {
        this.$emit('settle', result)
      }

    },
    onBack() {
      this.$emit('back')
    }
  },
  computed: {
    showFee(){
      return this.$authority('transDetail')
    },// 是否显示费用
    palletCost() { // 托盘费用合计
      return this.round(this.estimate?.palletPrincipal + this.estimate?.palletFee)
    },
    feeAccountNames() { // 服务费收取方简要名
      const cnt = this.estimateDetail?.serviceFeeList?.length
      if (!cnt) return ''

      const first = this.estimateDetail.serviceFeeList[0].feeAccountName ?? ""
      if (cnt === 1) {
        return first
      }
      const names = `${first}等${cnt}人`
      return names
    },
    estimateForm() {
      if (!this.estimate) return undefined;
      return {
        borrowerId: this.estimate.borrowerId, // 	融资人
        receiverId: this.estimate.receiverId, // 货盘单位
        repayAccountId: this.estimate.repayAccountId, //	回款账户
        settleAmount: this.estimate.settleAmount, //	待清算金额
        palletPrincipal: this.estimate.palletPrincipal, //	托盘本金
        palletFee: this.estimate.palletFee, //托盘手续费
        palletCost: this.round(this.estimate.palletPrincipal + this.estimate.palletFee), //	托盘费用 = 托盘本金 + 托盘手续费
        // payWay: '1'
      }
    },
    invalidMsg() { // 检查试算传入金额、余额查询结果是否一致
      if (!this.estimateDetail) {
        return "试算失败，请联系系统管理员"
      }
      if (Number(this.estimateDetail.palletCost) < 0) {
        return "托盘费用小于0"
      }
      if (Number(this.estimateDetail.lastPrincipal) < 0) {
        return "采购尾款过低，请减少托盘费用"
      }
      if (Number(this.estimateDetail.settleAmount) <= 0) {
        return "清算总额小于0"
      }
      return undefined
    },
    submitAvailable() { // 提交按钮是否可用
      return this.invalidMsg === undefined &&
          this.settleForm.verifyCode?.match(/^\d{6}$/) // 验证码6位数字
    },
  },
  watch: {
    estimate(val, old) {
      this.fetchEstimateDetail()
    }
  }
}

</script>

<template>
  <a-card title="清算明细" style="width: 550px;min-height: 500px; height:100%;">
    <div slot="extra">

    <a-button icon="arrow-left" type="link" @click.stop="onBack"> 重新试算</a-button>
    </div>

    <div class="clear-action" slot="actions" v-if="estimateDetail">
      <a-form-model>
        <a-form-model-item label="短信验证码" :label-col="{span: 6}" label-align="left" :wrapper-col="{span: 18}">
          <paymentSms
              :verify-code.sync="settleForm.verifyCode"
              :mobile="estimate.repayAccount.mobile" model-no="102112"
              :channel-no="estimateDetail.repayAccount.channelNo"
              :account-name="estimateDetail.repayAccount.accountName"
              :account-no="estimateDetail.repayAccount.accountNo"
              :pay-amount="estimateDetail.settleAmount"
              :disabled="invalidMsg!==undefined"
          ></paymentSms>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{span: 24}">
          <a-button type="primary" icon="property-safety" block :disabled="!submitAvailable" @click="settle"> 清算
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div v-if="estimateDetail">
      <a-list size="small" style=" max-height: 400px; overflow-y:scroll;">
        <a-list-item key="lender">
          <a-list-item-meta>

            <a-avatar slot="avatar" style="background-color:#e6fffb; color:#13c2c2">{{ estimateDetail.lender.lenderName.substr(0, 1) }}</a-avatar>
            <div slot="title">托盘费用(含服务费)</div>
            <WalletInfo slot="description" :account-name="estimateDetail.lender.accountName"
                        :account-no="estimateDetail.lender.accountNo"
                        show-account-name :show-account-no="false" :show-balance="false"></WalletInfo>
          </a-list-item-meta>
          <div>
            <Balance inline :amount="estimateDetail.palletCost" dcflag="d"></Balance>
          </div>
        </a-list-item>
        <a-list-item key="receiverAndFee" v-if="!showFee">
          <a-list-item-meta>
            <a-avatar slot="avatar" style="background-color: #f9f0ff; color: #722ed1">{{ estimateDetail.receiver.receiverName.substr(0, 1) }}</a-avatar>
            <div slot="title">尾款</div>
            <WalletInfo  slot="description" :account-name="estimateDetail.receiver.accountName"
                         :account-no="estimateDetail.receiver.accountNo"
                         show-account-name :show-account-no="false" :show-balance="false"></WalletInfo>
          </a-list-item-meta>
          <div>
            <Balance inline :amount="estimateDetail.lastPrincipal + estimateDetail.totalFee" dcflag="d"></Balance>
          </div>
        </a-list-item>
        <template v-else>
        <a-list-item key="receiver">
          <a-list-item-meta>
            <a-avatar slot="avatar" style="background-color: #f9f0ff; color: #722ed1">{{ estimateDetail.receiver.receiverName.substr(0, 1) }}</a-avatar>
            <div slot="title"> 采购尾款</div>

            <WalletInfo  slot="description" :account-name="estimateDetail.receiver.accountName"
                        :account-no="estimateDetail.receiver.accountNo"
                        show-account-name :show-account-no="false" :show-balance="false"></WalletInfo>
          </a-list-item-meta>
          <div>
            <Balance inline :amount="estimateDetail.lastPrincipal" dcflag="d"></Balance>
          </div>
        </a-list-item>
        <a-list-item key="fee" v-if="feeDetailCollapse">
          <a-list-item-meta>
            <a-avatar slot="avatar">佣</a-avatar>
            <div slot="title">佣金/服务费</div>
            <div slot="description">{{ feeAccountNames }}
              <a-button type=link @click="feeDetailCollapse=!feeDetailCollapse" icon="caret-down">展开</a-button>
            </div>
          </a-list-item-meta>
          <Balance inline :amount="estimateDetail.totalFee" dcflag="d"></Balance>
        </a-list-item>
        <a-list-item v-else v-for="(fee,index) in estimateDetail.serviceFeeList" :key="index">
          <a-list-item-meta>
            <a-avatar slot="avatar">{{ fee.feeAccountName.substr(0, 1) }}</a-avatar>
            <div slot="title">{{ fee.projectName }} - {{ fee.feeName }}</div>
            <WalletInfo slot="description" :account-name="fee.feeAccountName" :account-no="fee.feeAccountNo"
                        show-account-name :show-account-no="false" :show-balance="false"></WalletInfo>
          </a-list-item-meta>
          <div>
            <Balance inline :amount="fee.feeAmount" dcflag="d"></Balance>
          </div>
        </a-list-item>
        </template>
        <a-row style="width: 100%">
          <a-col :col-span="5">
            <Balance :amount="estimateDetail.settleAmount" dcflag="d" size="middle"></Balance>
          </a-col>
          <a-alert v-if="invalidMsg!==undefined" banner>
            <template slot="message">
              {{ invalidMsg }}
              <a-button icon="arrow-left" type="link" @click.stop="onBack"> 重新试算</a-button>
            </template>
          </a-alert>
        </a-row>
      </a-list>
    </div>
    <div v-else>
      <a-icon type="loading"></a-icon>
    </div>
  </a-card>
</template>

<style scoped lang="less">
.summary {
  /deep/ * {
    font-weight: bolder;
    font-size: 20px;
  }
}

.clear-action {
  margin: 20px auto;
  width: 400px;
}

</style>