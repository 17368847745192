<script>
import cytoscape from 'cytoscape';

export default {
  name: "detail-graph",
  components: {},
  props: {
    borrower: Object
  },
  data() {
    return {}
  },
  mounted() {
    this.renderCy()
  },
  methods: {
    renderCy() {
      if (!this.borrower) {
        return
      }
      console.info(this.borrower)
      const nodes = [], edges = []

      nodes.push({data: {id: 'plat', name: '电商平台（猫狗抖拼快等）', label: '平'},})
      nodes.push({data: {id: 'receiver'},})
      nodes.push({data: {id: 'agent'},})
      nodes.push({data: {id: 'repayAccount', name: "待清算户"},})
      nodes.push({data: {id: 'lender', name: this.borrower.lenderName, label: '资'}})
      nodes.push({data: {id: 'borrower', name: this.borrower.borrowerName, label: '融'}})
      for (const i in this.borrower.receiverList) {
        nodes.push({data: {id: 'receiver-' + i, parent:'receiver', name: this.borrower.receiverList[i].accountName, label: '货'}})
      }
      for (const i in this.borrower.borrowerFeeInfoList) {
        nodes.push({data: {id: 'agent-' + i, parent: 'agent', name: this.borrower.borrowerFeeInfoList[i].feeAccountName, label: '佣'}})
      }
      for (const i in this.borrower.repayAccountList) {
        nodes.push({data: {id: 'repayAccount-' + i,parent: 'repayAccount', name: this.borrower.repayAccountList[i].accountName, label: '清'}})
      }

      edges.push({data: {id: 'lend', source: 'lender', target: 'borrower', label: "借款"}})
      edges.push({data: {id: 'repay', source: 'borrower', target: 'lender', label: "还款"}})



      for (const i in this.borrower.receiverList) {
        edges.push({data: {id: 'payment-' + i, source: 'borrower', target: "receiver-" + i, label: "货款"}})
      }

      for (const i in this.borrower.borrowerFeeInfoList) {
        edges.push({data: {id: 'agency-' + i, source: 'borrower', target: "agent-" + i, label: "佣金"}})
      }

      for (const i in this.borrower.repayAccountList) {
        edges.push({data: {id: 'withdraw-'+i, parent:'plat', source: 'plat', target: 'repayAccount-'+i, label: "提现"}})
        edges.push({data: {id: 'clear-'+i, source: "repayAccount-"+i, target: 'borrower', label: "清算"}})
      }


      cytoscape({
        container: this.$refs.cy,
        elements: {nodes, edges,},
        layout: {name: 'cose'},
        style: [
          {
            selector: 'node',
            style: {
              "text-valign": "bottom",
              "label": "data(name)",
              "background-color": "#FFFFFF",
              "width": 100,
              "height": 100,
              "border-width": 0,
            }
          },
          {
            selector: '#plat, [label^="提现"]',
            style: {
              "background-color": "#ff844c",
              "line-color": "#ff844c",
              'target-arrow-color': '#ff844c',
            }
          },
          {
            selector: '[label^="融"], [label^="还"]',
            style: {
              "width": 150,
              "height": 150,
              "line-color": "#8A52F5",
              "background-color": "#8A52F5",
              'target-arrow-color': '#8A52F5',
            }
          },
          {
            selector: '[label^="资"], [label^="借款"]',
            style: {
              "line-color": "#4BE2FF",
              "background-color": "#4BE2FF",
              'target-arrow-color': '#4BE2FF',
            }
          },
          {
            selector: '[label^="佣"]',
            style: {
              "line-color": "#FFD009",
              "background-color": "#FFD009",
              'target-arrow-color': '#FFD009',
              width: 50,
              height: 50,
            }
          },
          {
            selector: '[label^="清"]',
            style: {
              "line-color": "#F57649",
              "background-color": "#F57649",
              'target-arrow-color': '#F57649',
            }
          },
          {
            selector: '#repayAccount',
            style: {
              "background-color": "#fbd5c9",
            }
          },
          {
            selector: '[label^="货"]',
            style: {
              'line-color': '#1CE803',
              "background-color": "#1CE803",
              'target-arrow-color': '#1CE803',
            }
          },
          {
            selector: 'edge',
            style: {
              'width': 5,
              'target-arrow-shape': 'triangle',
              'curve-style': 'bezier',
              'label': 'data(label)',
              "color": "#606060"
            }
          },
          {
            selector: 'edge[label^="佣"]',
            style: {
              'width': 2,
            }
          },
        ]
      })
    }
  },
}
</script>

<template>
  <div>
    <div id="cy" ref="cy"></div>
  </div>
</template>

<style scoped lang="less">
#cy {
  min-height: 600px;
  min-width: 600px;
  border: #5c6b77 2px solid;
  width: 100%;
}
</style>