<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>驾驶舱/核心企业</span>
          </div>
          <div class="right">
            <!-- 请选择机构 -->
            <!-- 所属机构 -->
            <!-- <a-tree-select v-model="departmentId" style="width: 200px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" @change="departmentIdchange"
              :tree-data="treeData" placeholder="请选择所属机构" tree-default-expand-all>
            </a-tree-select> -->
            <a-select class="dianshang" v-model.trim="channelNo" placeholder="请选择渠道" @change="channelChange">
              <a-select-option v-for="item in coreCompanyList" :key="item.channelNo" :value="item.channelNo">{{
                  item.channelName
                }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </template>
      <!-- -->
      <a-tabs @change="changeTab($event)">
        <a-tab-pane key="1" tab="保证金">
          <div class="trill">
            <div class="trilllist">
              <a-row>
                <a-col :span="12">
                  <a-col :span="6">
                    担保人账号:
                  </a-col>
                  <a-col :span="6">
                    <a-select class="dianshang" :dropdownMatchSelectWidth="false" placeholder="请选择担保人账户"
                              @change="mchNochange" v-model.trim="accountNoDb" style="min-width: 200px;">
                      <a-select-option v-for="item in mchNolist" :key="item.value" :value="item.accountNoDb"
                                       :item="item">
                        <span>{{ item.accountNameDb }}</span>
                      </a-select-option>
                    </a-select>
                  </a-col>
                </a-col>
                <a-col :span="12">
                  <a-col :span="6">
                    保证金使用率:
                  </a-col>
                  <a-col :span="6">
                    <Balance :amount="marginUsageRate" prefix="" suffix="％"></Balance>
                  </a-col>

                </a-col>
              </a-row>
              <!--担保人账户-->
              <a-row>
                <a-col :span="12">
                  <a-col :span="6">
                    企业活期余额:
                  </a-col>
                  <a-col :span="8">
                    <WalletInfo v-if="accountNoDb" :account-no="accountNoDb" :account-name="accountNameDb"
                                :balance="currentBalance" :show-account-no="false" account-class="0" show-withdraw-btn>
                    </WalletInfo>
                  </a-col>
                </a-col>
                <a-col :span="12">
                  <a-col :span="6">
                    电商贷款余额:
                  </a-col>
                  <a-col :span="6">
                    <Balance :amount="ecommerceLoanBalance"></Balance>
                  </a-col>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-col :span="6">
                    保证金余额:
                  </a-col>
                  <a-col :span="8">
                    <WalletInfo v-if="accountNoDbA6" :account-no="accountNoDbA6" :account-name="accountNameDb"
                                :balance="marginBalance" :show-account-no="false" account-class="2">
                    </WalletInfo>
                  </a-col>
                  <a-col :span="2">
                    <guarantee-withdraw-btn
                        :channel-no="channelNo" :app-no="appNo"
                        :account-no-a1="accountNoDb" :account-no-a6="accountNoDbA6">
                    </guarantee-withdraw-btn>
                  </a-col>
                  <a-col :span="2">
                    <guarantee-deposit-btn
                        :channel-no="channelNo" :app-no="appNo"
                        :account-no-a1="accountNoDb" :account-no-a6="accountNoDbA6">
                    </guarantee-deposit-btn>
                  </a-col>
                </a-col>
                <a-col :span="12">
                  <a-col :span="6">
                    剩余可贷:
                  </a-col>
                  <a-col :span="6">
                    <Balance :amount="surplusLoanable"></Balance>
                  </a-col>
                </a-col>
              </a-row>
            </div>

          </div>

          <div class="tablebox">
            <!-- 表格 -->
            <div class="tableBody">
              <a-table ref="table" :rowKey="(record, index) => `${record.borrowerA1}`" :columns="columns"
                       :data-source="data"
                       size="small" :pagination="false" :scroll="{ y: 300 }">
                <template slot="Controls" slot-scope="text, record">
                  <div class="Controlsbox">

                    <a-popover>
                      <template slot="title">详情</template>
                      <template slot="content">
                        <p> 借款人：<span>{{ record.borrower }}</span>
                          <a-icon type="copy" v-clipboard:copy="record.borrower" v-clipboard:success="onCopy"
                                  v-clipboard:error="onCopyErr"/>
                        </p>
                        <p> 借款人A1：

                          <span>{{ record.borrowerA1 }}</span>
                          <a-icon type="copy" v-clipboard:copy="record.borrowerA1" v-clipboard:success="onCopy"
                                  v-clipboard:error="onCopyErr"/>
                        </p>
                        <p> 担保人：<span>{{ record.dbAccountName }}</span>
                          <a-icon type="copy" v-clipboard:copy="record.dbAccountName" v-clipboard:success="onCopy"
                                  v-clipboard:error="onCopyErr"/>
                        </p>
                        <p> 担保人A1：<span>{{ record.dbAccountNoA1 }}</span>
                          <a-icon type="copy" v-clipboard:copy="record.dbAccountNoA1" v-clipboard:success="onCopy"
                                  v-clipboard:error="onCopyErr"/>
                        </p>
                      </template>
                      <a-button type="link">详情</a-button>
                    </a-popover>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="商户">
          <div class="trill">
            <a-row>
              <a-col :span="2">商户所属机构：</a-col>
              <a-col :span="10">
                <DepartmentChoose :departmentCode.sync="departmentCode" @change="departmentChange"/>
              </a-col>
            </a-row>
            <div class="tableBody">
              <a-table ref="table2" :rowKey="(record, index) => `${record.id}`" bordered :columns="columnsMch"
                       :data-source="dataMch" size="small" :pagination="false" :scroll="{ y: 800, x: 2000 }">
              </a-table>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="电商日报">
          <div class="trill">
            <div class="selected-box">
              <div class="box-item">
                <div>电商所属机构：</div>
                <div>
                  <DepartmentChoose :departmentCode.sync="departmentCode" @change="query"/>
                </div>
              </div>
              <div class="box-item">
                <div class="left">数据日期：</div>
                <div>
                  <a-range-picker v-model.trim="searchtime" valueFormat="YYYY-MM-DD" style="width: 200px;"/>
                </div>
              </div>
              <div class="box-item">
                <div class="left">电商编号：</div>
                <div>
                  <a-input v-model.trim="selectData.mchNo" placeholder="请输入电商编号"></a-input>
                </div>
              </div>
              <div class="box-item">
                <div class="left">店铺名称：</div>
                <div>
                  <a-input v-model.trim="selectData.shopName" placeholder="请输入店铺名称"></a-input>
                </div>
              </div>
              <div class="box-item">
                <div class="left">受托支付对象：</div>
                <div>
                  <a-input v-model.trim="selectData.entrustedPayer" placeholder="请输入受托支付对象"></a-input>
                </div>
              </div>
              <div class="box-item">
                <div class="left">贷款人：</div>
                <div>
                  <a-input v-model.trim="selectData.lender" placeholder="请输入贷款人"></a-input>
                </div>
              </div>
              <div class="box-item">
                <div>
                  <a-button type="primary" @click="query">查询</a-button>
                </div>
                <div>
                  <a-button>重置</a-button>
                </div>
              </div>
            </div>
            <div class="table-title">
              <a-button type="link" @click="downloadDialy">下载日报</a-button>
            </div>
            <div class="tableBody">
              <a-table ref="table2" :rowKey="(record, index) => record['电商编号']" bordered :columns="columnsMchDialy"
                       :data-source="dataMchDialy" size="small" :pagination="false" :scroll="{ y: 800, x: 2000 }">
              </a-table>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-card>

  </div>
</template>

<script>
// import dayjs from "dayjs";

import {utils, writeFile} from 'xlsx';

import Balance from '@/view/components/Balance'
import WalletInfo from "@/view/components/WalletInfo.vue";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import GuaranteeWithdrawBtn from "@/view/components/GaranteeWithdrawBtn.vue";
import GuaranteeDepositBtn from "@/view/components/GaranteeDepositBtn.vue";

export default {
  name: 'CoreCompany',
  components: {
    GuaranteeDepositBtn,
    GuaranteeWithdrawBtn,
    DepartmentChoose,
    Balance,
    WalletInfo
  },
  data() {
    return {
      searchtime: [],
      // 选中担保人信息
      selectDbInfo: {},
      // 担保人账号
      accountNoDb: "",
      accountNameDb: "",
      accountNoDbA6: "",
      accountNameDbA6: "",
      // 渠道
      channelNo: "",
      // 担保人渠道
      dbChannel: '',
      appNo: '',
      // 企业活期余额
      currentBalance: 0,
      marginBalance: 0,//保证金余额
      marginUsageRate: 0,//保证金使用率
      surplusLoanable: 0,//剩余可贷
      ecommerceLoanBalance: 0,//贷款余额
      idNo: "",
      coreCompanyId: '',
      value: "1",
      value1: "",
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0, // 数量
      time: [],
      mchNolist: [], // 担保人账户列表
      departmentId: undefined,
      departmentCode: this.$session.getItem("companyCode"),
      coreCompanyList: JSON.parse(this.$session.getItem('coreCompanyList')),
      columnsMch: [
        {
          title: "电商",
          children: [
            {
              title: "编号",
              dataIndex: "电商编号",
              align: "center",
              width: 100,
              sorter: (a, b) => a['电商编号'].localeCompare(b['电商编号']),
            },
            {
              title: "名称",
              dataIndex: "电商名称",
              align: "center",
              width: 200,
              sorter: (a, b) => a['电商名称'].localeCompare(b['电商名称']),
            },],
          fixed: 'left'
        },
        {
          title: "贷款余额",
          dataIndex: "融资余额",
          align: "center",
          width: 130,
          sorter: (a, b) => a['融资余额'] - b['融资余额'],
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: "可贷金额",
          dataIndex: "可融资金额",
          align: "center",
          width: 130,
          sorter: (a, b) => a['可融资金额'] - b['可融资金额'],
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          }
        },
        {
          title: "融资金额",
          children: [
            {
              title: "今日",
              dataIndex: "今日融资金额",
              align: "center",
              width: 130,
              sorter: (a, b) => a['今日融资金额'] - b['今日融资金额'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "昨日",
              dataIndex: "昨日融资金额",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "7日",
              dataIndex: "7天融资金额",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            // {
            //   title: "30日",
            //   dataIndex: "30天融资金额",
            //   align: "center",
            //   width: 130,
            //   customRender:(text) =>{ return <Balance amount={text}></Balance> }
            // },
            {
              title: "历史合计",
              dataIndex: "融资总额",
              align: "center",
              width: 130,
              sorter: (a, b) => a['融资总额'] - b['融资总额'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
          ]
        },
        {
          title: "剩余应还本金",
          children: [
            {
              title: "今日",
              dataIndex: "今日应还",
              align: "center",
              width: 130,
              sorter: (a, b) => a['今日应还'] - b['今日应还'],
              customRender: (text) => {
                let flag = text ?? 0 > 0 ? "c" : "d"
                return <Balance amount={text} dcflag={flag}></Balance>
              }
            },
            {
              title: "明日",
              dataIndex: "明日应还",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "7日",
              dataIndex: "7天内应还",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "合计",
              dataIndex: "合计应还",
              align: "center",
              width: 130,
              sorter: (a, b) => a['合计应还'] - b['合计应还'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            // {
            //   title: "30日",
            //   dataIndex: "30天内应还",
            //   align: "center",
            //   width: 130,
            //   customRender:(text) =>{ return <Balance amount={text}></Balance> }
            // },
          ]
        },
        {
          title: "已还本金",
          children: [
            {
              title: "今日",
              dataIndex: "今日还本",
              align: "center",
              width: 130,
              sorter: (a, b) => a['今日还本'] - b['今日还本'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "昨日",
              dataIndex: "昨日还本",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "7日",
              dataIndex: "7天还本",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "合计",
              dataIndex: "合计还本",
              align: "center",
              width: 130,
              sorter: (a, b) => a['合计还本'] - b['合计还本'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
          ]
        }, {
          title: "已还利息",
          children: [
            {
              title: "今日",
              dataIndex: "今日还息",
              align: "center",
              width: 130,
              sorter: (a, b) => a['今日还息'] - b['今日还息'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "昨日",
              dataIndex: "昨日还息",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "7日",
              dataIndex: "7天还息",
              align: "center",
              width: 130,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
            {
              title: "合计",
              dataIndex: "合计还息",
              align: "center",
              width: 130,
              sorter: (a, b) => a['合计还息'] - b['合计还息'],
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              }
            },
          ]
        },

      ],
      columnsMchDialy: [
        {
          title: '电商信息',
          children: [

            {
              title: "编号",
              dataIndex: "电商编号",
              align: "center",
              width: 200,
              sorter: (a, b) => a['电商编号'].localeCompare(b['电商编号']),
            },
            {
              title: "商户",
              dataIndex: "融资电商",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['融资电商'].localeCompare(b['融资电商']),
            },
            {
              title: "类型",
              dataIndex: "商品类型",
              align: "center",
              width: 70,
              filters: [{
                text: "🛍商品类", value: "🛍"
              }, {
                text: "🎤娱乐类", value: "🎤"
              }, {
                text: "💰资金类", value: "💰"
              }, {
                text: "🤝代销类", value: "🤝"
              }, {
                text: "🎗️代销(特殊)", value: "🎗️"
              }, {
                text: "💴权益", value: "💴"
              }],
              onFilter: (value, record) => record['商品类型'] === value
            }, {
              title: "合作渠道",
              dataIndex: "合作渠道",
              align: "center",
              width: 200,
              ellipsis: true,
              customRender: (text, row) => {
                return <div>{text}<br/>{row['合作渠道电话']}</div>
              },
              sorter: (a, b) => a['合作渠道'].localeCompare(b['合作渠道']),
            }, {
              title: "落地机构",
              dataIndex: "落地机构",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['落地机构'].localeCompare(b['落地机构']),
            }, {
              title: "商户客户经理",
              dataIndex: "电商客户经理",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['电商客户经理'].localeCompare(b['电商客户经理']),
            }, {
              title: "信贷客户经理",
              dataIndex: "信贷客户经理",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['信贷客户经理'].localeCompare(b['信贷客户经理']),
            }, {
              title: "可控商户",
              dataIndex: "可控电商",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['可控电商'].localeCompare(b['可控电商']),
            }, {
              title: "经营主体",
              dataIndex: "经营主体",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['经营主体'].localeCompare(b['经营主体']),
            },
            {
              title: "受托支付对象",
              dataIndex: "受托支付对象",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['受托支付对象'].localeCompare(b['受托支付对象']),
            },

          ],
        },
        {
          title: '店铺授信信息',
          children: [
            {
              title: "贷款人",
              dataIndex: "贷款人",
              align: "center",
              width: 100,
              ellipsis: true,
              sorter: (a, b) => a['贷款人'].localeCompare(b['贷款人']),
            },
            {
              title: "授信总额",
              dataIndex: "授信总额",
              align: "center",
              customRender: (text) => {
                return <Balance amount={Number(text) / 10000} suffix="万"></Balance>
              },
              width: 120,
              ellipsis: true,
              sorter: (a, b) => a['授信总额'] - b['授信总额'],
            },
            {
              title: "已用额度(实时)",
              dataIndex: "已用额度(实时)",
              align: "center",
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              width: 150,
              ellipsis: true,
              sorter: (a, b) => a['已用额度(实时)'] - b['已用额度(实时)'],
            }, {
              title: "销售平台",
              dataIndex: "直播平台",
              align: "center",
              width: 80,
              ellipsis: true,
              filters: [{
                text: "快手", value: "快手"
              }, {
                text: "抖音", value: "抖音"
              }, {
                text: "微信支付", value: "微信支付"
              }, {
                text: "其他", value: "其他"
              }, {
                text: "无", value: ""
              }],
              onFilter: (value, record) => record['直播平台'] === value
            }, {
              title: "店铺",
              dataIndex: "店铺",
              align: "center",
              width: 200,
              ellipsis: true,
              sorter: (a, b) => a['店铺'].localeCompare(b['店铺']),
            }, {
              title: "融资比例",
              dataIndex: "融资比例",
              align: "center",
              width: 100,
              ellipsis: true,
              customRender: (text) => {
                return text + '%'
              },
              sorter: (a, b) => a['融资比例'] - b['融资比例'],
            }, {
              title: "退货率",
              dataIndex: "退货率",
              align: "center",
              width: 100,
              ellipsis: true,
              customRender: (text) => {
                return text + '%'
              },
              sorter: (a, b) => a['退货率'] - b['退货率'],
            },
          ],
        },
        {
          title: '融资情况',
          children: [
            {
              title: "销售额",
              dataIndex: "销售额",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['销售额'] - b['销售额'],
            },
            {
              title: "放款金额",
              dataIndex: "放款金额",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['放款金额'] - b['放款金额'],
            },
            {
              title: "已还本金",
              dataIndex: "已还本金",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['已还本金'] - b['已还本金'],
            }, {
              title: "剩余应还本金",
              dataIndex: "剩余应还本金",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['剩余应还本金'] - b['剩余应还本金'],
            }, {
              title: "其中:逾期本金",
              dataIndex: "其中:逾期本金",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:逾期本金'] - b['其中:逾期本金'],
            }, {
              title: "已还利息",
              dataIndex: "已还利息",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['已还利息'] - b['已还利息'],
            }, {
              title: "剩余应还利息",
              dataIndex: "剩余应还利息",
              align: "center",
              width: 130,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['剩余应还利息'] - b['剩余应还利息'],
            },
          ],
        },

        {
          title: '费率',
          children: [
            {
              title: "综合费率",
              dataIndex: "综合费率",
              align: "center",
              width: 100,
              ellipsis: true,
              sorter: (a, b) => a['综合费率'].localeCompare(b['综合费率']),
            },
            {
              title: "其中:核心企业费率",
              dataIndex: "其中:核心企业费率",
              align: "center",
              width: 100,
              ellipsis: true,
              sorter: (a, b) => a['其中:核心企业费率'].localeCompare(b['其中:核心企业费率']),
            },
            {
              title: "其中:渠道费率",
              dataIndex: "其中:渠道费率",
              align: "center",
              width: 100,
              ellipsis: true,
              sorter: (a, b) => a['其中:渠道费率'].localeCompare(b['其中:渠道费率']),
            }, {
              title: "其中:银行利率(年化)",
              dataIndex: "其中:银行利率(年化)",
              align: "center",
              width: 100,
              ellipsis: true,
              sorter: (a, b) => a['其中:银行利率(年化)'].localeCompare(b['其中:银行利率(年化)']),
            }, {
              title: "其中:平台服务费率(年化)",
              dataIndex: "其中:平台服务费率(年化)",
              align: "center",
              width: 100,
              ellipsis: true,
              sorter: (a, b) => a['其中:平台服务费率(年化)'].localeCompare(b['其中:平台服务费率(年化)']),
            },
          ],
        },
        {
          title: '费用收取(估算)',
          children: [
            {
              title: "已收费用",
              dataIndex: "已收费用",
              align: "center",
              width: 120,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['已收费用'] - b['已收费用'],
            },
            {
              title: "其中:核心企业费用",
              dataIndex: "其中:核心企业费用",
              align: "center",
              width: 120,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:核心企业费用'] - b['其中:核心企业费用'],
            },
            {
              title: "其中:渠道应收费用",
              dataIndex: "其中:渠道应收费用",
              align: "center",
              width: 120,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:渠道应收费用'] - b['其中:渠道应收费用'],
            },
            {
              title: "其中:渠道费用",
              dataIndex: "其中:渠道费用",
              align: "center",
              width: 120,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:渠道费用'] - b['其中:渠道费用'],
            }, {
              title: "其中:银行利息",
              dataIndex: "其中:银行利息",
              align: "center",
              width: 120,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:银行利息'] - b['其中:银行利息'],
            }, {
              title: "其中:平台服务费",
              dataIndex: "其中:平台服务费",
              align: "center",
              width: 120,
              ellipsis: true,
              customRender: (text) => {
                return <Balance amount={text}></Balance>
              },
              sorter: (a, b) => a['其中:平台服务费'] - b['其中:平台服务费'],
            },
          ],
        },
      ],
      columns: [
        {
          title: "借款人",
          dataIndex: "borrower",
          align: "center",
          ellipsis: true,
        },
        {
          title: "借款人活期",
          dataIndex: "borrowerA1",
          align: "center",
          customRender: (text, row) => {
            return <WalletInfo accountClass="0" accountNo={text} accountName={row.borrower}></WalletInfo>
          }
        },
        {
          title: "贷款账号",
          dataIndex: "loanAccountNo",
          align: "center",
          customRender: (text, row) => {
            return <WalletInfo accountClass="7" accountNo={text} accountName={row.borrower}></WalletInfo>
          }
        },
        {
          title: "贷款余额",
          dataIndex: "loanBalance",
          align: "center",
          width: 150,
          ellipsis: true,
          customRender: (text) => {
            return <Balance amount={text}></Balance>
          },
        },
        {
          title: "保证金占用",
          dataIndex: "marginOccupation",
          align: "center",
          width: 110,
          ellipsis: true,
          customRender: (text) => {
            return <Balance amount={text} prefix="" suffix="％"></Balance>
          },
        },
        {
          title: "最高担保额",
          dataIndex: "maximumAmountSecured",
          align: "center",
          width: 110,
          ellipsis: true,
          customRender: (text) => {
            return <Balance amount={text / 10000} suffix="万"></Balance>
          },

        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   key: 'Controls',
        //   // width: 200,
        //   scopedSlots: { customRender: 'Controls' },
        // },

      ],

      data: [],
      dataMch: [],
      dataMchDialy: [],
      selectData: {
        shopNo: '',
        shopName: '',
        entrustedPayer: '',
        lender: ''
      }
    };
  },
  mounted() {
    // table表格
    this.departmentId = this.$session.getItem("companyId");
    this.departmentCode = this.$session.getItem("companyCode")
    // 右上角的渠道
    this.channelNo = JSON.parse(this.$session.getItem("coreCompanyList"))[0]?.channelNo
    // 获取担保人账户
    this.getmchshopsApi();
  },
  methods: {
    // 时间选择改变
    onChange() {
      //获取电商日报
      this.getMchDialyTable()
    },
    // 切换tab栏
    changeTab(event) {
      // this.departmentCode = this.$session.getItem("companyCode")
      if (event === '3') {
        let startTime = this.$moment().startOf('year').format('YYYY-MM-DD')
        let endTime = this.$moment().format('YYYY-MM-DD')
        this.$set(this.searchtime, 0, startTime)
        this.$set(this.searchtime, 1, endTime)
      }
    },
    changeMch() {
      //获取电商日报
      this.getMchDialyTable()
    },
    async getMchDialyTable() {
      let data = {
        sqlParame: {
          depatCode: this.departmentCode,
          starDate: this.searchtime[0],
          endDate: this.searchtime[1],
          channelNo: this.channelNo,
          ...this.selectData
        },
        key: 'e_commerce_daily'
      }

      let res = await this.api.getRzDetailsApi(data)
      if (res.code === 200) {
        // console.log(res);
        this.dataMchDialy = res.data
      }
    },
    // 查询
    query() {
      //获取电商日报
      this.getMchDialyTable()
    },
    // 下载日报
    downloadDialy() {
      // 创建一个新的工作簿
      const wb = utils.book_new();
      // 创建一个新的工作表
      const ws = utils.aoa_to_sheet([]);
      // 设置合并单元格以创建多级表头
      ws['!merges'] = [
        {s: {r: 0, c: 0}, e: {r: 0, c: 11}}, // 合并第一行的9
        {s: {r: 0, c: 12}, e: {r: 0, c: 18}}, // 合并第一行的7
        {s: {r: 0, c: 19}, e: {r: 0, c: 25}},//7
        {s: {r: 0, c: 26}, e: {r: 0, c: 30}},//5
        {s: {r: 0, c: 31}, e: {r: 0, c: 36}},//5
      ];
      // // 添加父级表头
      const parentHeaders = [
        // 第一行 - 多个父级表头
        ['电商信息', "", "", "", "", "", "", "", "", "", "", "", '店铺授信信息', "", "", "", "", "", "", '融资情况', "", "", "", "", "", "", '费率', "", "", "", "", '费用收取(估算)', "", "", "", "", ""
        ],

        // 第二行 - 子级表头
        ["渠道编号", "电商编号", "商品类型", "融资电商", "合作渠道", "合作渠道电话", "落地机构", "电商客户经理", "信贷客户经理", "可控电商", "经营主体", "受托支付对象", "贷款人", "授信总额", "已用额度(实时)", "直播平台", "店铺", "融资比例", "退货率", "销售额", "放款金额", "已还本金", "剩余应还本金", "其中:逾期本金", "已还利息", "剩余应还利息", "综合费率", "其中:核心企业费率", "其中:渠道费率", "其中:银行利率(年化)", "其中:平台服务费率(年化)", "已收费用", "其中:核心企业费用", "其中:渠道应收费用", "其中:渠道费用", "其中:银行利息", "其中:平台服务费",]
      ];

      utils.sheet_add_aoa(ws, parentHeaders, {origin: 'A1'});

      // 提取数据并添加到工作表
      const dataRows = this.dataMchDialy.map(dataRow => {
        return [dataRow['渠道编号'], dataRow['电商编号'], dataRow['商品类型'], dataRow['融资电商'], dataRow['合作渠道'], dataRow['合作渠道电话'], dataRow['落地机构'], dataRow['电商客户经理'], dataRow['信贷客户经理'], dataRow['可控电商'], dataRow['经营主体'], dataRow['受托支付对象'], dataRow['贷款人'], dataRow['授信总额'], dataRow['已用额度(实时)'], dataRow['直播平台'], dataRow['店铺'], dataRow['融资比例'], dataRow['退货率'], dataRow['销售额'], dataRow['放款金额'], dataRow['已还本金'], dataRow['剩余应还本金'], dataRow['其中:逾期本金'], dataRow['已还利息'], dataRow['剩余应还利息'], dataRow['综合费率'], dataRow['其中:核心企业费率'], dataRow['其中:渠道费率'], dataRow['其中:银行利率(年化)'], dataRow['其中:平台服务费率(年化)'], dataRow['已收费用'], dataRow['其中:核心企业费用'], dataRow['其中:渠道应收费用'], dataRow['其中:渠道费用'], dataRow['其中:银行利息'], dataRow['其中:平台服务费'],];
      });

      utils.sheet_add_aoa(ws, dataRows, {origin: 'A3'});

      utils.book_append_sheet(wb, ws, '电商日报');
      writeFile(wb, `电商日报${this.searchtime[0]}至${this.searchtime[1]}.xlsx`);

    },

    // 获取担保人活期余额
    async getfindAuthoShop() {
      if (!this.accountNoDb) return
      this.currentBalance = 0
      this.marginBalance = 0
      const res = await this.api.accountqueryBalancedApi({
        // channel_no: this.channelNo,
        channel_no: this.dbChannel,
        account_no: this.accountNoDb,
        relation_acct: '1'
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      res.data.detail_list.forEach(item => {
        if (item.account_class === '0') {
          // 活期余额
          this.currentBalance = item.cur_balance
        } else if (item.account_class === '2') {
          // 保证金余额
          this.marginBalance = item.cur_balance
          this.accountNoDbA6 = item.account_no
          this.accountNameDbA6 = item.account_name
        }
      })
      this.idNo = res.data.id_no
      // 保证金使用率
      this.marginUsageRate = this.marginBalance === 0 ? 0 : (Number(this.ecommerceLoanBalance) / (this.marginBalance * 5) * 100)
      //剩余可贷
      this.surplusLoanable = this.marginBalance * 5 - this.ecommerceLoanBalance
      this.selectDbInfo = res.data
    },
    onCopy() {
      this.$message.info("已复制");
    },
    onCopyErr() {
      this.$message.info("复制失败");
    },
    //  改变右上角的渠道
    async channelChange() {
      this.getmchshopsApi()
    },
    // 改变担保人账户列表触发
    async mchNochange() {
      let item = this.mchNolist.find(o => o.accountNoDb == this.accountNoDb)
      // this.channelNo = item?.channelNo
      this.dbChannel = item?.channelNo
      this.accountNoDb = item?.accountNoDb
      this.accountNameDb = item?.accountNameDb
      this.appNo = item?.appNo
      this.coreCompanyId = item?.id
      this.reset()
      await this.getCoreCompony()
      // 获取担保人活期余额
      await this.getfindAuthoShop();
    },
    departmentChange() {
      // 获取电商经营情况
      this.getMchTable();
    },
    // 获取核心企业详情
    async getCoreCompony() {
      let res = await this.api.getCoreComponyApi(this.coreCompanyId)
      if (res.code == 200) {
        this.ecommerceLoanBalance = res.data.ecommerceLoanBalance
        // 保证金详情
        this.data = res.data.marginDetails
      }
    },
    // 获取核心企业(从担保人列表中获取)列表
    async getmchshopsApi() {
      this.mchNolist = [];
      let data = {
        pageNo: 1,
        pageSize: 10000,
        obj: {
          //   departmentId: this.departmentId,
          channelNo: this.channelNo
        }
      }
      const res = await this.api.guarantorListApi(data);
      if (res.code != 200) {
        this.$message.error(res.info);
        return;
      }

      this.mchNolist = res.data.records.map(x => Object.assign(x, {
        label: x.accountNameDb,
        value: x.accountNoDb
      }))
      // 默认选中第一个
      this.accountNoDb = this.mchNolist[0]?.accountNoDb
      this.mchNochange()
    },
    async getMchTable() {
      let data = {
        sqlParame: {
          depatCode: this.departmentCode,
        },
        key: 'core_company_view'
      }
      let res = await this.api.getRzDetailsApi(data)
      if (res.code == 200) {
        this.dataMch = res.data
      }
    },
    reset() {
      this.data = []
      this.currentBalance = 0
      this.marginBalance = 0//保证金余额
      this.marginUsageRate = 0//保证金使用率
      this.surplusLoanable = 0//剩余可贷
      this.ecommerceLoanBalance = 0//贷款余额
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

.trill {
  width: 100%;
  padding: 0 44px;
  //border-bottom: 1px solid #d7d7d7;
  min-height: 230px;

  .selected-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .box-item {
      flex-basis: 300px;
      margin: 0 20px 20px 0;
      display: flex;
      align-items: center;

      .left {
        width: 100px;
      }

      .ant-input {
        width: 200px;
      }
    }
  }

  .table-title {
    display: flex;
    justify-content: end;
  }

  .trilllist {
    width: 100%;

    .ant-row {
      width: 80%;
      padding: 10px 0;

      .ant-col-12 {
        display: flex;
        align-items: center;
      }
    }

  }

  .footertishi {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    color: #969696;
  }
}

.formbox {
  display: flex;
  flex-wrap: wrap;
  padding-top: 35px;
  display: flex;
  padding-left: 30px;
  align-items: center;

  button {
    margin-bottom: 20px;
  }

  .inputbox {
    width: 260px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      width: 180px;
    }
  }

  button {
    margin-bottom: 20px;
  }

  .reset {
    width: 64px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Toleadinto {
  padding: 30px;
}

.tablebox {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding: 0 32px;
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  padding: 0 32px;
  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}

/deep/ .ant-card-head-title {
  .top-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .dianshang {
      margin-left: 50px;
      width: 260px;
    }
  }
}
</style>
