<script>
import {nbiDict as nbiStatus} from "@/utils/bizDict/nbiDict";

export default {
  emits: ['quit'],
  props: {
    result: Object
  },
  name: "settle-result",
  data() {
    return {
      nbiStatus
    }
  },
  methods: {
    onQuit() {
      this.$emit('quit')
    }
  },
  computed: {
    status() {
      if (this.result.code === 200) {
        if (this.result.data?.payStatus === '1') {
          return {
            status: 'success',
            title: this.result.info,
          }
        } else {
          const stat = nbiStatus.statusConfig[this.result.data?.payStatus]
          return {
            status: 'warning',
            title: stat?.label || "交易失败"
          }
        }
      }
      return 'error'
    }
  },
}
</script>

<template>
  <a-result :status="status.status" :title="status.title">
    <template #extra>
      <a-button key="back" type="primary" @click="onQuit">
        返回
      </a-button>
    </template>
    <template #sub-title>
      请到历史交易明细确认记账详情
    </template>
  </a-result>
</template>

<style scoped lang="less">
.ant-result {
  /deep/ .anticon svg {
    font-size: 72px;
  }
}

</style>