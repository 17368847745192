<script>
import WalletInfo from "@/view/components/WalletInfo.vue";

export default {
  name: "detail-contracts",
  components: {WalletInfo},
  props: {
    borrower: Object
  },
  methods: {
    buildContract(notes, type, status, payerNo, payerName, payeeNo, payeeName) {
      return {notes, type, status, payerNo, payerName, payeeNo, payeeName,}
    }
  },
  computed: {
    borrow() {
      return [
        this.buildContract('借', 1, this.borrower.status,
            this.borrower.lender.accountNo, this.borrower.lender.accountName,
            this.borrower.accountNo, this.borrower.accountName,
        ),
      ]
    },
    repay() {
      return [
        this.buildContract('还', 2, this.borrower.status,
            this.borrower.accountNo, this.borrower.accountName,
            this.borrower.lender.accountNo, this.borrower.lender.accountName,
        )
      ]

    },

    receiverList() {
      return this.borrower?.receiverList?.map(item => {
        return this.buildContract('货款', 2, item.status,
            this.borrower.accountNo, this.borrower.accountName,
            item.accountNo, item.accountName,
        )
      })
    },
    repayAccountList() {
      return this.borrower?.repayAccountList?.map(item => {
        return this.buildContract(`[${item.settleContract?.contractNo ?? "协议未配置"}]清分`, 1, item.status,
            item.settleContract?.accountNoSettling1 ?? "", item.settleContract?.accountNameSettling1 ?? "",
            this.borrower.accountNo, this.borrower.accountName,
        )
      })
    },
    borrowerFeeInfoList() {
      return this.borrower?.borrowerFeeInfoList?.map(item => {
        return this.buildContract(item.projectName, 2, item.status,
            this.borrower.accountNo, this.borrower.accountName,
            item.feeAccountNo, item.feeAccountName,
        )
      })
    },
    contracts() {
      // 支付协议
      return [
        ...this.borrow ?? [],
        ...this.receiverList ?? [],
        ...this.repayAccountList ?? [],
        ...this.repay ?? [],
        ...this.borrowerFeeInfoList ?? [],
      ]
    }
  },
}
</script>

<template>
  <a-table :data-source="contracts" size="small" :row-key="(record)=>record.payeeNo + record.notes">
    <a-table-column title="类型" data-index="type" :width="90">
      <template slot-scope="text, record">
        <a-tag v-if="record.type === 1" color="cyan">支付协议</a-tag>
        <a-tag v-if="record.type === 2" color="orange">代扣协议</a-tag>
      </template>
    </a-table-column>
    <a-table-column title="付" data-index="payerName" align="right">
      <template slot-scope="text, record">
        <WalletInfo v-if="record.payerName" :account-name="record.payerName" :account-no="record.payerNo"
                    show-account-no
                    show-account-name></WalletInfo>
      </template>
    </a-table-column>
    <a-table-column title="说明" data-index="notes" align="center">
      <template slot-scope="text, record">

        <a-tag v-if="record.status === 1" color="green">{{ text }}
          <a-icon type="arrow-right"/>
        </a-tag>
        <a-tag v-else-if="record.status === 0" color="red">{{ text }}
          <a-icon type="arrow-right"/>
        </a-tag>
        <a-tag v-else color="grey">{{ text }}
          <a-icon type="arrow-right"/>
        </a-tag>

      </template>
    </a-table-column>
    <a-table-column title="收" data-index="payeeName" align="left">
      <template slot-scope="text, record">
        <WalletInfo :account-name="record.payeeName" :account-no="record.payeeNo" :show-balance="false" show-account-no
                    show-account-name></WalletInfo>
      </template>
    </a-table-column>
  </a-table>
</template>

<style scoped lang="less">

</style>