<script>
import PaymentSms from "@/view/components/PaymentSms.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import Balance from "@/view/components/Balance.vue";

export default {
  name: "WithdrawBtn",
  components: {Balance, InputBalance, PaymentSms},
  props: {
    accountNo: {type: String, required: true},
    channelNo: {type: String, required: true},
  },
  emits: ['cancel', 'ok'],
  data() {
    return {
      visible: false,
      confirmVisible: false,
      accountDetail: undefined,
      cardList: [],
      form: {
        channel_no: this.channelNo,
        account_no: this.accountNo,
        account_name: undefined,
        primary_account: undefined,
        amount: undefined,
        verify_code: undefined,
      }
    }
  },

  methods: {
    async fetchAccountInfo() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.channelNo,
        account_no: this.accountNo,
      })
      if (res.code === 200) {
        this.accountDetail = res.data
        this.form.account_name = this.accountDetail.account_name
      }
    },
    async fetchCardList() {
      const res = await this.api.bankcardpageApi(0, 100, {
        accountNo: this.accountNo
      })
      if (res.code === 200) {
        this.cardList = res.data.records
        if (this.cardList.length >= 1) {
          this.form.primary_account = this.cardList[0].bankCardNo
        }
      }
    },
    async toWithdraw() {
      this.accountDetail = undefined
      this.cardList = []
      await this.fetchAccountInfo()
      await this.fetchCardList()
      this.visible = true
    },
    toConfirm() {
      this.confirmVisible = true
    },
    cancel() {
      this.visible = false
      this.$emit('cancel')
    },
    async withdraw() {
      const res = await this.api.merWithdrawApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('提现成功')
      this.$emit('ok')
      this.visible = false
      this.confirmVisible = false
    }
  },
  watch: {},
  computed: {
    maxBalance() {
      if (this.accountDetail?.detail_list?.length > 0) {
        return this.accountDetail?.detail_list[0]?.cur_balance
      }
      return 0
    }
  }
}

</script>

<template>
  <span @click="toWithdraw" v-if="$authority('withdrawDeposit')">
    <slot>
      <a-button type=link>提现</a-button>
    </slot>
    <a-modal v-model="visible" @cancel="cancel" title="提现" v-if="accountDetail" :zIndex="100">
      <a-form :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-item label="付款钱包">
             <a-tag color="green">活期A1</a-tag>{{ accountNo }}
        </a-form-item>
        <a-form-item label="收款账户">
            {{ accountDetail.account_name }}
        </a-form-item>
        <a-form-item label="收款卡号">
         <a-select v-model.trim="form.primary_account" placeholder="请选择已绑定的银行卡号">
            <a-select-option v-for="(item, ind) in cardList" :key="ind" :value="item.bankCardNo">
              {{ item.bankCardNo }}
              <div class="bank-name">{{ item.bankName }}</div>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="提现金额">
          <input-balance v-model="form.amount" :max="maxBalance"></input-balance>
          <Balance prefix="可提现金额：￥" dcflag="d" :amount="maxBalance"/>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" @click="toConfirm" :disabled="!Number(form.amount)>0 || !form.primary_account">确定
        </a-button>
      </template>
    </a-modal>
    <a-modal v-model="confirmVisible" :maskClosable="false" :zIndex="200">
      <a-descriptions :column="1" title="请确认交易信息" bordered>
        <a-descriptions-item label="付款钱包">
          {{ form.account_no }}
        </a-descriptions-item>
        <a-descriptions-item label="收款账户">
          {{ form.account_name }}
        </a-descriptions-item>
        <a-descriptions-item label="收款卡号">
          {{ form.primary_account }}
        </a-descriptions-item>
        <a-descriptions-item label="提现金额">
          <Balance :amount="Number(form.amount)" :align-right="false"></Balance>
        </a-descriptions-item>

      </a-descriptions>
      <a-form style="margin-top:20px" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-item label="短信验证">
      <payment-sms v-if="confirmVisible" :verifyCode.sync="form.verify_code"
                   :trade-type="3"
                   :account-no="form.account_no"
                   :account-name="form.account_name"
                   :pay-amount="form.amount"
                   :mobile="accountDetail.mobile"
                   :channel-no="form.channel_no"
                   :card-no="form.primary_account"
                   :id-no="accountDetail.id_no"
                   :disabled="!Number(form.amount)>0 || !form.primary_account"
                   model-no=""></payment-sms>
        </a-form-item>
      </a-form>
      <template slot="footer">

        <a-button @click="confirmVisible=false">取消</a-button>
        <a-button type="primary" @click="withdraw" :disabled="form.verify_code?.length!==6">提现
        </a-button>
      </template>
    </a-modal>
  </span>
</template>

<style scoped lang="less">

.bank-name {
  float: right;
  color: #6a6a6a;
  font-size: 0.9em;
}

/deep/ .ant-select-selection-selected-value {
  width: 100%;
}

/deep/ .ant-form-item-control {
  text-align: end;

  .ant-select {
    text-align: start;
  }
}
</style>