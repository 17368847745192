<template>
  <div>
    <!-- 企业客户管理 -->
    <a-card title="企业客户管理" style="margin: 20px">
      <!-- 筛选区域 -->
      <div class="selectBox">
        <div class="item">
          <span>所属机构</span>
            <DepartmentChoose :department-code.sync="paramsData.departmentCode" @change="handleQuery" ></DepartmentChoose>
        </div>
        <div class="item">
          <span>客户编号</span>
          <a-input v-model.trim="paramsData.custnum" placeholder="请输入客户编号" />
        </div>
        <div class="item">
          <span>客户名称</span>
          <a-input v-model.trim="paramsData.custname" placeholder="请输入客户名称" />
        </div>
        <div class="item">
          <span>资料完整</span>
          <a-select v-model.trim="paramsData.custdataintactind" placeholder="全部">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 是 </a-select-option>
            <a-select-option value="0"> 否 </a-select-option>
          </a-select>
        </div>
        <div class="item">
          <span>客户状态</span>
          <a-select allowClear @change="selectOnChange($event)" v-model.trim="paramsData.custstatuscd"
            :options="busiDict.CstSt" placeholder="请选择客户状态">
          </a-select>
        </div>
        <div class="item">
          <span>证件号码</span>
          <a-input v-model.trim="paramsData.certnum" placeholder="请输入证件号码" />
        </div>
        <div class="item">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格部分 -->
      <div class="tableBox">
        <!-- 操作按钮 -->
        <div class="handleBtn">
          <a-button type="primary" v-if="$authority('addEnterpriseadd')">
            <router-link :to="{ name: 'addEnterprise', query: { type: 'add' } }" target="_self"
              rel="opener">新增</router-link>
          </a-button>
          <a-button type="primary" v-if="$authority('addEnterpriseedit')" @click="editBtn">编辑</a-button>
          <a-button type="primary" v-if="$authority('addEnterprise')" @click="viewBtn">查看</a-button>
          <a-button type="primary" v-if="$authority('connectEnterprise')" @click="contactBtn">关联用户</a-button>
          <a-button type="primary" v-if="$authority('enterpriseWalletOpening')" @click="walletOpen">钱包开立</a-button>
          <a-button type="primary" v-if="$authority('businessLoanApplication')" @click="loanApply">贷款申请</a-button>
        </div>
        <!-- 表格 -->
        <div class="tableBody">
          <a-table :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" :columns="columns" :data-source="data" :pagination="false" :customRow="clickrow" :rowKey="(record, index) => `${record.id}`
  ">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(paramsData.pageNo) == 1" @click="Homepage">
          首页
        </a-button> <a-pagination v-model.trim="paramsData.pageNo" :page-size-options="pageSizeOptions" :total="total"
          show-size-changer :page-size="paramsData.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
          :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage"
          :disabled="paramsData.pageNo == Math.ceil(Number(total) / Number(paramsData.pageSize))" class="Trailingpage">
          尾页
        </a-button>
      </div>
    </a-card>
  </div>
</template>
<script>
import { message } from "ant-design-vue";
import { BizDict, BizDictCascade } from "@/utils/bizDict/kvMap";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
const data = [];
export default {
  name: 'enterprisePage',
  components: {DepartmentChoose},
  data() {
    const columns = [
      {
        title: "No",
        customRender: (text, record, index) =>
          `${Number(this.paramsData.pageNo - 1) *
          Number(this.paramsData.pageSize) +
          Number(index + 1)
          }`,
        align: "center",
        width: 60
      },
      {
        title: "客户编号",
        dataIndex: "custnum",
        align: "center",
        // width: 150,
        ellipsis: true,
      },
      {
        title: "客户名称",
        dataIndex: "custname",
        align: "center",
        ellipsis: true,

      },
      {
        title: "证件号码",
        dataIndex: "certnum",
        align: "center",
        ellipsis: true,
        customRender: (text) => {
          if (!text) return ""
          let starizedText = text.replace(/^(.{6}).+(.{4}$)/, "$1****$2")
          return <a-tooltip><template slot="title"> {{ text }} </template> {starizedText} </a-tooltip>
        }
      },
      // {
      //   title: "客户类型细分",
      //   dataIndex: "custtypecdsub",
      //   align: "center",
      //   width: 150,
      //   ellipsis: true,
      //
      //   customRender: (text) => {
      //     if (text) {
      //       return handleCustTypeSub(text);
      //     } else {
      //       return "---";
      //     }
      //   },
      // },
      // {
      //   title: "客户状态",
      //   dataIndex: "custstatuscd",
      //   align: "center",
      //   customRender: (text) => {
      //     if (text === null) {
      //       return "---";
      //     } else {
      //       return this.findCuststatuscd(text);
      //     }
      //   },
      // },
      {
        title: "机构",
        dataIndex: "departmentId",
        align: "center",
        customRender: (text) => {
          return <DeptInfo dept-id={text}></DeptInfo>
        },
      },
      {
        title: "创建人",
        dataIndex: "creater",
        align: "center",
        width: 100,
        customRender: (text) => {
          return text === null ? "---" : text;
        },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        align: "center",

        ellipsis: true,
        customRender: (text) => {
          // console.log(text);
          if (text === null) {
            return "---";
          } else {
            return text.split("T")[0] + " " + text.split("T")[1];
          }
        },
      },
    ];
    return {
      data,
      columns,
      selectedRowKeys: [],
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      total: 0,
      paramsData: {
        departmentId: undefined,
        departmentCode: '',
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 客户编号
        custnum: "",
        // 客户名称
        custname: "",
        // 客户资料是否完整
        custdataintactind: "",
        // 客户状态
        custstatuscd: "",
        // 证件号码
        certnum: "",
      },
      // 选中的客户数组
      selectCustData: [],
    };
  },
  created() {
    this.busiDict = { ...BizDict, ...BizDictCascade };
    // 获取机构列表
  },
  mounted() {
    // if (sessionStorage.getItem('msgInfo')) {
    //   this.paramsData = this.$store.state.searchMsgInfo
    // }
    // 获取表格
    this.getTableData();
  },
  activated() {
    if (this.$route.query.fromAddPage) {
      this.paramsData = {
        departmentId: undefined,
        departmentCode: '',
        // 当前页，
        pageNo: 1,
        // 一页多少条
        pageSize: 10,
        // 客户编号
        custnum: "",
        // 客户名称
        custname: "",
        // 客户资料是否完整
        custdataintactind: "",
        // 客户状态
        custstatuscd: "",
        // 证件号码
        certnum: "",
      }
      this.getTableData();

    } else if (this.$route.query.fromEditPage) {
      this.getTableData();

    }
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo');
  },
  methods: {
    // 通过客户状态值返回状态name
    findCuststatuscd(text) {
      for (let i = 0; i < this.busiDict.CstSt.length; i++) {
        if (this.busiDict.CstSt[i].value === text) {
          return this.busiDict.CstSt[i].label;
        }
      }
    },
    // 获取表格数据
    async getTableData() {
      let obj = {};
      obj.departmentId = this.paramsData.departmentId;
      obj.departmentCode = this.paramsData.departmentCode;
      obj.custnum = this.paramsData.custnum;
      obj.custname = this.paramsData.custname;
      obj.custdataintactind = this.paramsData.custdataintactind;
      obj.custstatuscd = this.paramsData.custstatuscd;
      obj.certnum = this.paramsData.certnum;
      let data = {
        pageNo: this.paramsData.pageNo,
        pageSize: this.paramsData.pageSize,
        obj: obj,
      };
      const res = await this.api.custEnterprise(data);
      if (res.code == 200) {
        this.data = res.data.records;
        this.paramsData.pageNo = res.data.current;
        this.paramsData.pageSize = res.data.size;
        this.total = res.data.total;
      } else {
        return message.warning(res.info);
      }
    },
    selectOnChange(event) {
      if (event == undefined) {
        this.handleQuery();
      }
    },
    // 查询
    handleQuery() {
      this.paramsData.pageNo = 1;
      this.getTableData();
    },
    // 重置
    handleReset() {
      this.paramsData = {
        // 当前页，
        pageNo: 1,
        //一页多少条
        pageSize: 10,
        // 所属机构
        departmentId: undefined,
        // 客户编号
        custnum: "",
        // 客户名称
        custname: "",
        // 客户资料是否完整
        custdataintactind: undefined,
        // 客户状态
        custstatuscd: undefined,
        // 证件号码
        certnum: "",
      };
      this.getTableData();
    },
    // 编辑跳转
    editBtn() {
      // console.log(this.selectCustData, '00')
      if (this.selectCustData.length != 1) {
        return message.warning("只能选择一个客户!");
      } else {
        // 存储筛选条件
        // this.$store.commit('saveSearchMsgInfo', this.paramsData)
        let data = this.selectCustData[0];
        this.$router.push({
          name: "addEnterprise",
          query: {
            custnum: data.custnum,
            type: "edit",
          },
        });
      }
    },
    // 查看跳转
    viewBtn() {
      if (this.selectCustData.length != 1) {
        return message.warning("只能选择一个客户!");
      } else {
        // 存储筛选条件
        // this.$store.commit('saveSearchMsgInfo', this.paramsData)
        let data = this.selectCustData[0];
        this.$router.push({
          name: "addEnterprise",
          query: {
            custnum: data.custnum,
            type: "view",
          },
        });
      }
    },
    // 关联用户跳转
    contactBtn() {
      if (this.selectCustData.length == 0) {
        return message.warning("请选择客户信息!");
      } else {
        // 存储筛选条件
        // this.$store.commit('saveSearchMsgInfo', this.paramsData)
        let paramDataArr = [];
        this.selectCustData.forEach((item) => {
          console.log(item, "item");
          let paramData = {};
          paramData.id = item.id;
          paramData.custnum = item.custnum;
          paramData.custname = item.custname;
          paramData.custtypecd = item.custtypecd;
          paramData.departmentId = item.departmentId;
          paramDataArr.push(paramData);
          // console.log(paramDataArr, 'paramDataArr')
          this.$store.commit(
            "customer/setAssociatedEnterpriseParam",
            paramDataArr
          );
          const { href } = this.$router.resolve({
            path: "/cust/enterprise/connectEnterprise",
          });

          window.open(href, "_self");
        });
      }
    },
    async walletCheckOpen() {
      let data = this.selectCustData[0];
      let custInfo = await this.api.getEnterpriseCust(data.custnum)
      if (custInfo.data.custAccountInfos.length > 0) {
        return true
      } else {
        return false;
      }
    },
    async walletOpen() {
      if (this.selectCustData.length != 1) {
        return message.warning("请选中一个客户");
      }
      let checkResult = await this.walletCheckOpen()
      if (checkResult) {
        return message.warning("当前用户已开立钱包");
      }
      // 存储筛选条件
      // this.$store.commit('saveSearchMsgInfo', this.paramsData)
      this.$router.push({
        name: 'CorporateAccountOpening',
        params: {
          type: "add",
          custnum: this.selectCustData[0].custnum
        }
      })
    },

    async loanApply() {
      if (this.selectCustData.length != 1) {
        return message.warning("请选中一个客户");
      }
      let checkResult = await this.walletCheckOpen()
      if (!checkResult) {
        return message.warning("当前用户未开立钱包");
      }
      // 存储筛选条件
      // this.$store.commit('saveSearchMsgInfo', this.paramsData)
      this.$router.push({
        name: 'Enterpriseloan',
        params: {
          type: "add",
          custnum: this.selectCustData[0].custnum
        }
      })
    },
    // 选中表格的事件函数
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectCustData = row;
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString()
            let index = this.selectedRowKeys.findIndex((item) => id == item)
            if (index == -1) {
              this.selectedRowKeys.push(id)
              this.selectCustData.push(record)

            } else {

              this.selectedRowKeys.splice(index, 1)
              this.selectCustData.splice(index, 1)
            }
          }
        }
      }
    },
    // 改变当前页的事件函数
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {
      // console.log(current);
      // console.log(pageSize);
      this.paramsData.pageNo = current;
      this.paramsData.pageSize = pageSize;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    pagechange(val) {
      this.paramsData.pageNo = val
      this.getTableData()
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.paramsData.pageNo = 1,
        this.getTableData();
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.paramsData.pageNo = Math.ceil(Number(this.total) / Number(this.paramsData.pageSize))
      this.getTableData();
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.paramsData.pageNo = page;
      this.selectedRowKeys = [];
      this.getTableData();
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a>下一页</a>;
      }
      return originalElement;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-card-body {
  padding: 24px 34px;
}

.selectBox {
  display: flex;
  flex-wrap: wrap;

  /deep/ .item {
    margin-bottom: 20px;
    margin-right: 20px;

    // span {
    //   margin-right: 15px;
    // }
    .ant-input {
      width: 200px;
      margin-left: 20px;
    }

    .ant-select {
      width: 200px;
      margin-left: 20px;
    }
  }
}

.tableBox {
  margin-bottom: 20px;

  .handleBtn {
    margin-bottom: 20px;
  }
}

.paginationBox {
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
