<script>
import storage from "@/utils/storage";

export default {
  name: "DepartmentChoose",
  components: {},
  props: {
    departmentId: Number,
    departmentCode: String,
    departmentName: String,
    disabled: {type: Boolean, default: false},
    defaultFirst: {type: Boolean, default: true},
  },
  emits: ['update:departmentCode', 'update:departmentId', 'update:departmentName', 'change'],
  async mounted() {
    await this.loadRawData();
  },
  beforeUpdate() {
    this.initValue()
  },
  data() {
    return {
      value: undefined,
      treeData: [],
    }
  },
  methods: {
    initValue(){
      if(!this.departmentId && this.departmentCode){
        this.value = this.deptCodeToId(this.departmentCode)
      }else{
        this.value = this.departmentId
      }
      if(!this.value && this.defaultFirst){
        this.value = this.treeData[0].value
        this.onSelect(this.value)
      }
    },
    // 获取所属机构下拉框数据
    async loadRawData() {
      let data = {
        username: this.$session.getItem("username"),
      };
      let res = await this.api.agencyList(data);
      if (res.code === 200) {
        this.treeData = this.getResettingTrees([res.data]);
      } else {
        return this.$message.error(res.info);
      }
    },
    //重构树形结构方法
    getResettingTrees(trees) {
      if (trees.length === 0) {
        return [];
      }
      let res = [];
      trees.forEach((tree) => {
        let deptKey = "STAR_DEPT_"+tree.coreDepartment.id
        res.push({
          title: tree.coreDepartment.name,
          value: tree.coreDepartment.id,
          starred: storage.getBoolOrDefault(deptKey,false),
          key: tree.coreDepartment.code,
          scopedSlots: {title: "title"},
          children:
              tree.children && tree.children.length > 0
                  ? this.getResettingTrees(tree.children)
                  : [],
        });
      });
      return res;
    },
    onSelect(departmentId) {
      let departmentCode = this.deptIdToCode(departmentId)
      let departmentName= this.deptIdToName(departmentId)
      this.$emit("update:departmentId", departmentId);
      this.$emit("update:departmentCode", departmentCode);
      this.$emit("update:departmentName", departmentName);
      this.$emit("change", {departmentId, departmentCode, departmentName})
    },
    filterTreeNode(searchText, item){
      return this.isStarred(item.data.props) || item.data.props.dataRef.title.indexOf(searchText) > -1;
    },
    dropdownVisibleChange(){
      this.$forceUpdate() // 调用这个方法会刷新视图
    },
    toggleStarred(dept){
      console.info("toggle:", dept.title)
      let deptKey = "STAR_DEPT_"+dept.value
      let starred = storage.getBoolOrDefault(deptKey,false)
      storage.set(deptKey, !starred)
      this.$forceUpdate() // 调用这个方法会刷新视图
    },
    isStarred(dept){
      let deptKey = "STAR_DEPT_"+dept.value
      return storage.getBoolOrDefault(deptKey,false)
    },
    deptCodeToId(deptCode){
      let match = deptCode.match(/^(?:.*\.)*(\d+)\.$/)
      if(match){
        return Number(match[1])
      }else{
        return undefined
      }
    },
    deptIdToCode(deptId){
      let current = this.treeData
      while(current.length>0){
        let found = current.find(item=>item.value === Number(deptId))
        if(found){
          return found.key
        }
        current = current.flatMap(item=>item.children)
      }
    },
    deptIdToName(deptId) {
      let current = this.treeData
      while (current.length > 0) {
        let found = current.find(item => item.value === Number(deptId))
        if (found) {
          return found.title
        }
        current = current.flatMap(item => item.children)
      }
    }
  }
}
</script>

<template>
  <a-tree-select v-model="value" :dropdown-style="{ maxHeight: '400px', minWidth: '200px', overflow: 'auto' }"
                 placeholder="请选择机构" searchPlaceholder="搜索机构"
                 :tree-data="treeData" :disabled="disabled" tree-default-expand-all @select="onSelect"
                 :replaceFields="{'title':'name'}"
                 showSearch :filterTreeNode="filterTreeNode"
                 @dropdownVisibleChange="dropdownVisibleChange">
    <template slot="title" slot-scope="dept">
      <span :class="isStarred(dept)?'dept-title starred':'dept-title'">
         {{dept.title}}
          <a-icon type="star" :theme="isStarred(dept)?'filled':'outlined'" @click.stop="toggleStarred(dept)" />
      </span>
    </template>
  </a-tree-select>
</template>

<style scoped lang="less">
// 选中不回显星号
.ant-select{
  min-width: 200px;
  .dept-title{
    i{
      display: none;
    }
  }
}
// 星星靠右，正常不显示
.dept-title{
  i{
    float:right;
    visibility: hidden;
  }
}
.ant-select-tree-node-content-wrapper:hover i{
  visibility: visible;
}
.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal.starred i{
  visibility: visible;
}
.starred{
  i{
    visibility: visible;
  }
  font-weight: bold;

}

</style>