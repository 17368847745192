<script>
import TransRecord from "@/view/nbi/trans/record.vue";

export default {
  name: "advance",
  components: {TransRecord},
  data() {
    return {
      lenderId: undefined,
      borrowerId: undefined,
    }
  },
  methods: {
    toAdvance() {
      this.$router.push({
        name: 'advanceAdd', params: {
          lenderId: this.lenderId,
          borrowerId: this.borrowerId,
        }
      },)
    }
  }
}
</script>

<template>
  <trans-record trans-type="nbi_advance"
                lender-picker :lender-id.sync="lenderId"
                borrower-picker :borrower-id.sync="borrowerId">
    <template v-slot:action-box>
      <a-button type="primary" @click="toAdvance" icon="transaction">去垫资</a-button>
    </template>
  </trans-record>
</template>

<style scoped lang="less">

</style>