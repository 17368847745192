<template>
  <div>
    <a-modal title="小额打款认证" :visible="visible" @cancel="clickCancel" :centered="true" :width="600">
      <div class="form-box">
        <!-- 付款人 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>付款人</span>
            </a-col>
            <a-col :span="20">
              <a-input disabled v-model="paramsData.accountName"></a-input>
            </a-col>
          </a-row>
        </div>
        <!-- 付款账号 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>付款账号</span>
            </a-col>
            <a-col :span="20">
              <a-radio-group v-model="paramsData.accountNo" @change="changeAccount($event)">
                <a-radio-button v-for="(item, ind) in payerWalletList" :key="'payerWalletList' + ind"
                  :value="item.account_no"
                :disabled="!$authority('A2BalanceVisible') && item.account_class==='6'">
                  <a-tag :color="item.account_class == '0' ? 'green' : 'blue'">{{ item.account_class == '0' ? '活期' : '冻结'
                  }}</a-tag>
                  <span class="right">{{ item.account_no }}</span>
                </a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </div>
        <!-- 余额 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>可用余额:</span>
            </a-col>
            <a-col :span="20" style="padding: 7px 0;">
              <Balance :amount="currentLoan" :align-right="false"></Balance>

            </a-col>
          </a-row>
        </div>
        <!-- 收款对象 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>收款人</span>
            </a-col>
            <a-col :span="20">
              <a-select placeholder="请选择收款人" @change="paymentChange($event)">
                <a-select-option v-for="item in paymentList" :key="item.id" :value="item.id">{{ item.oppAccountName }}
                </a-select-option>
              </a-select>
              <a-descriptions bordered size="small" :column="1" v-if="paramsData.oppAccountNo">
                 <a-descriptions-item label="收款人账号">{{ paramsData.oppAccountNo }}</a-descriptions-item>
                <a-descriptions-item label="收款人户名">{{ paramsData.oppAccountName }}</a-descriptions-item>
                <a-descriptions-item label="开户行">{{ paramsData.oppBankName }}</a-descriptions-item>
                <a-descriptions-item label="大额行号">{{ paramsData.oppBankNo }}</a-descriptions-item>
              </a-descriptions>
            </a-col>
          </a-row>
        </div>
        <!-- 支付金额 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>支付金额</span>
            </a-col>
            <a-col :span="20">
              <input-balance style="width:300px" v-model="paramsData.payAmount" :max="currentLoan" @blur="changeInput"></input-balance>
            </a-col>
          </a-row>
        </div>
        <!-- 验证码 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>验证码</span>
            </a-col>

            <a-col :span="20">
              <payment-sms style="width:300px"
                           :disabled="!paramsData.payAmount || Number(paramsData.payAmount)<=0 || !paramsData.oppAccountNo"
                   :verify-code.sync="paramsData.verifyCode"
                  :account-name="paramsData.accountName" :account-no="paramsData.accountNo"
                  :pay-amount="paramsData.payAmount"
                  model-no="102216" :mobile="mobile" :channel-no="paramsData.channelNo" ></payment-sms>
            </a-col>
          </a-row>
        </div>

      </div>
      <template slot="footer">
        <a-button @click="clickCancel">取消
        </a-button>

        <a-popconfirm title="是否确认支付？" ok-text="确定" cancel-text="取消" @confirm="onConfirmDialog"
          @cancel="cancelConfirmDialog">
          <a-button type="primary">确认支付
          </a-button>
        </a-popconfirm>
      </template>

    </a-modal>
  </div>
</template>
<script>
import Balance from '@/view/components/Balance.vue'
import CountdownBtn from "@/view/components/CountdownBtn.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import PaymentSms from "@/view/components/PaymentSms.vue";

export default {
  name: "PaymentAuthDialog",
  props: {
    selectedAccount: {
      required: true,
      type: Object
    },
    type: String
  },
  components: {
    PaymentSms,
    InputBalance,
    Balance,
    CountdownBtn
  },
  data() {
    const paramsData = {
      channelNo: this.selectedAccount.channelNo,
      appNo: this.selectedAccount.appNo,
      departmentId: this.selectedAccount.departmentId,
      // 付款户名/电商户名
      accountName: this.selectedAccount.accountName,
      // 	付款账号/电商A1账号
      accountNo: '',//默认活期
      accountNoA2: '',
      mchName: '',
      mchNo: "",
      // 	收款账号
      oppAccountNo: "",
      // 	收款户名
      oppAccountName: "",
      oppAccountType: '',
      oppBankNo: '',
      oppBankName: "",
      orderType: '',
      //支付金额
      payAmount: "",
      payWay: '3',
      remark: '小额打款认证',
      // 	验证码
      verifyCode: "",
    };
    return {
      // 页面参数
      paramsData,
      visible: true,
      // 当前余额
      currentLoan: 0,
      // 收款对象数组
      paymentList: [],
      isShowConfirmDialog: false,
      confirmSuccess: false,
      payerWalletList: [],
      mobile: '',

    };
  },

  watch: {},
  computed: {

  },
  created() {
    // 获取余额账号
    this.getBalance()
    // 获取收款人列表
    this.payeeList()
    // console.log(this.selectedAccount);
    this.paramsData.oppAccountNo = ''
    this.paramsData.oppAccountName = ''
    this.paramsData.oppBankNo = ''
    this.paramsData.oppBankName = ''
    this.paramsData.payAmount = ''
    this.paramsData.verifyCode = ''
  },
  methods: {
    async payeeList() {
      let data = {
        pageNo: 1,
        pageSize: 1000
      }
      data.obj = {
        orderType: '0'
      }
      let res = await this.api.getPayProtocolListApi(data);
      if (res.code == 200) {
        this.paymentList = res.data.records
      }
    },
    async getBalance() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.selectedAccount.channelNo,
        account_no: this.selectedAccount.majorAccountNo || this.selectedAccount.accountNo,
        relation_acct: this.selectedAccount.accountRelation,
      })
      if (res.code == 200) {
        res.data.detail_list.forEach(item => {
          if (item.account_class == '0') {
            //活期账号
            this.payerWalletList[0] = item
          } else if (item.account_class == '6') {
            // 冻结账号
            this.payerWalletList[1] = item
          }
        })
        //默认活期
        this.paramsData.accountNo = this.payerWalletList[0].account_no
        // 冻结
        this.paramsData.accountNoA2 = this.payerWalletList[1].account_no
        // 默认活期余额
        this.currentLoan = this.payerWalletList[0].cur_balance
        this.mobile = res.data.mobile

      } else {
        this.paramsData.accountName = ''
        this.currentLoan = 0
        this.$message.error(res.info)
      }
    },
    changeAccount() {
      this.payerWalletList.forEach(item => {
        if (item.account_no == this.paramsData.accountNo) {
          this.currentLoan = item.cur_balance
        }
      })
    },
    clickCancel() {
      this.$emit('OnPaymentChange', false)
    },

    //  选中付款对象的回调
    paymentChange(event) {
      let value = event;
      let paymentData = this.paymentList.reduce((acc, item) => {
        if (item.id == value) {
          acc = JSON.parse(JSON.stringify(item));
        }
        return acc;
      }, {});
      // 回显收款对象信息
      console.log(paymentData, "paymentData");
      this.paramsData.mchName = paymentData.mchName;
      this.paramsData.mchNo = paymentData.mchNo;
      this.paramsData.oppAccountName = paymentData.oppAccountName;
      this.paramsData.oppAccountNo = paymentData.oppAccountNo;
      this.paramsData.oppAccountType = paymentData.oppAccountType;
      this.paramsData.oppBankNo = paymentData.oppBankNo;
      this.paramsData.oppBankName = paymentData.oppBankName;
      this.paramsData.orderType = paymentData.orderType;
    },
    // 输入金额
    changeInput() {
      // 校验可用是否足够
      let value = Number(this.paramsData.payAmount)
      if (
        value > this.currentLoan
      ) {
        this.$message.error("支付金额不能大于可用余额!");
      }
    },
    //余额支付/融资支付/协议支付
    async confirmPay() {
      // this.paramsData.payAmount = Number(this.paramsData.payAmount);
      this.paramsData.departmentId = this.paramsData.departmentId.toString();
      let data = JSON.parse(JSON.stringify(this.paramsData));
      let res = await this.api.orderPayApi(data);
      if (res.code == 200) {
        this.$message.success(res.info);
        let toUrl = ''
        if (this.type == 'management') {
          toUrl = '/wallet/accountmanagement'
        } else {
          toUrl = '/wallet/accountlist'
        }
        this.$router.push(toUrl);
      } else {
        return this.$message.error(res.info);
      }
    },
    // 弹框确定按钮
    onConfirmDialog() {

      if (this.paramsData.verifyCode == "") {
        return this.$message.error("请填写验证码");
      }

      // 调用支付接口
      this.confirmPay();


    },
    // 弹框取消按钮
    cancelConfirmDialog() {
      console.log('00');
      // this.isShowConfirmDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal {
  width: 700px;

  .ant-modal-content {
    width: 100%;
  }
}

.form-box {
  padding-left: 10px;

  .item-container {
    width: 400px;
    margin-left: 70px;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }

  .form-box-item {
    margin-bottom: 10px;

    .up,
    .down {
      cursor: pointer;
    }

    .up {
      transform: rotate(-180deg);
    }

    .ant-select {
      min-width: 300px;
    }

    .ant-input {
      max-width: 300px;
    }

    .ant-input-number {
      width: 300px;
    }

    .ant-col-4 {
      padding: 7px 0;
    }

    .ant-input-group-wrapper {
      width: 300px;
    }

    .payment-box {
      width: 450px;
      padding: 10px;
      margin-top: 10px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      box-sizing: border-box;
      background-color: #f2f2f2;

      .payment-box-item {
        margin-bottom: 10px;
      }
    }
  }

  .form-box-last-item {
    margin-top: 10px;
    padding-left: 300px;
  }
}
</style>