//订单管理
import axios from '@/utils/axios'
import urlConfig from '@/utils/urlConfig'

export default {
  // 支付协议管理列表page
  getPayProtocolListApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL +
      '/busi/payorcers/contract/page?' +
      'pageNo=' +
      data.pageNo +
      '&pageSize=' +
      data.pageSize,
      data.obj
    ),
  // 支付协议管理-新增-电商信息page
  getEcListApi: (data) =>
      axios.apiPost( urlConfig.busiURL + '/mch/live/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data.obj),
  // 支付协议管理-新增
  addProtocolApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorcers/contract/add', data),
  // 支付协议管理-删除
  delProtocolApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorcers/contract/delete', data),
  // 支付协议管理-修改
  updateProtocolApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorcers/contract/update', data),
  // 支付协议管理-查看
  viewProtocolApi: (data) =>
    axios.apiGet(urlConfig.busiURL + '/busi/payorcers/contract/get', data),
  // 订单支付列表page
  payOrdersApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorders/page?pageNo='+data.pageNo+'&pageSize='+ data.pageSize,data),
  // 订单支付-详情
  detailOrdersApi: (data) =>
    axios.apiGet(urlConfig.busiURL + '/busi/payorders/details', data),
  // 订单支付-新增支付-余额查询
  avibaleBalanceApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/cust/account/queryBalance', data),
  // 订单支付-新增支付-mcn电商信息
  mcnMchApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/mcn/getPageByMchNo?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize + '&mchNo=' + data.mchNo),
  // 订单支付-新增支付-直播信息
  liveListApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/broadcast/page', data),
  // 订单支付-新增支付-协议支付
  protocolPayApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorders/agreementPay', data),
  // 订单支付-新增支付-余额支付
  balancePayApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorders/balancePayment', data),
  // 订单支付-订单支付整合接口
  orderPayApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorders/orderPay', data),
  // 订单支付-新增支付-融资支付
  financingPayApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/payorders/financingPayment', data),
  // 订单支付-新增支付-获取电商下的融资额度
  getFinacingLimitApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/collect/orders/availCreditLimit?mchNo=' + data),
  // 订单导出接口
  orderExportApi: (data) =>
    axios.apiPostDown(
      urlConfig.busiURL + '/busi/payorders/export', data),
  // 订单同步接口
  orderSyncApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/payorders/syncToCg?orderNo=' + data),

  retryServiceFeeApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/payorders/retryServiceFee', data),
  // 原始销售订单列表
  oriordersApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/oriorders/page', data),
  // 原始订单导出
  oriorderexportsApi: (data) =>
    axios.apiPostDown(urlConfig.busiURL + '/busi/oriorders/export', data),
  // 获取原始订单详情
  oriordersdetailsApi: (id) =>
    axios.apiPost(urlConfig.busiURL + '/busi/oriorders/details?id=' + id),
  // 原始订单操作记录列表
  busiOriginalOrdersOperationApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/busiOriginalOrdersOperation/page',
      data
    ),
  // 原始订单修改记录列表
  busiOriginalRevisionApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/oriorders/history/page',
      data
    ),
  // 原始销售订单导入
  excelImportsApi: (mchNo, data) =>
    axios.apiPost(
      urlConfig.busiURL + `/busi/oriorders/douyin/import?mchNo=${mchNo}`,
      data
    ),
  capitalOrderSync: (mchNo) =>
      axios.apiGet(urlConfig.busiURL + `/busi/oriorders/capital/SynOrder?mchNo=${mchNo}`),
  // 汇总订单列表
  collectordersApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/page', data),
  // 获取电商店铺
  mchshopsApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/live/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 汇总订单导出
  collectordersexportApi: (data) =>
    axios.apiPostDown(urlConfig.busiURL + '/busi/collect/orders/export', data),
  // 汇总订单原始明细导出
  collectordersitemexportApi: (data) =>
    axios.apiPostDown(urlConfig.busiURL + '/busi/collect/orders/itemExport', data),
  //  清算还款页面
  collectordersdetailsApi: (id) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/collect/orders/settlePage?id=' + id
    ),
  collectOrdersGenerateApi: (data)=>
    axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/generate', data),
  // 追加特殊订单
  collectOrdersSpecialApi: (data)=>
    axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/additionalSpecialOrder', data),
  // 汇总订单修正
  collectordersamendmentApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/amendment?orderNo='+ data.orderNo),
  // 汇总订单汇总记录
  collectordersrevisionnRecordListApi: (data) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/collect/orders/revisionnRecordList',
      data
    ),
  // 汇总订单同步
  collectordersorderSynApi: (orderNo) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/collect/orders/orderSyn?orderNo=' + orderNo
    ),
  // 汇总订单同步
  collectEquityOrderSynchByMchNoApi: (mchNo) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/collect/orders/equityOrderSynchByMchNo?mchNo=' + mchNo
    ),
  //汇总订单作废
  collectordersorderCancelApi: (orderNo) =>
    axios.apiPost(
      urlConfig.busiURL + '/busi/collect/orders/cancelOrder?orderNo=' + orderNo
    ),
  // 清算还款
  collectorderssettleApi: (id) =>
    axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/settle?id=' + id),

    // 清算还款
    orderSettleMchNoApi: (data) => axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/settleMchNo', data),
  // 批量清算还款
  collectordersSettleAll:()=>
      axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/settleAll'),

//  获取店铺授权列表 
  findAuthoShopApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/shop/findAuthoShop', data),

  // 获取快手授权地址
  getKsAuthorizedAddressApi: (data) =>
    axios.apiGet(urlConfig.busiURL + '/threeplat/kuaishou/getKsAuthorizedAddress?shopNo=' + data,),

  // 汇总订单差额补足 
  getcollectorderscompensationApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/busi/collect/orders/compensation', data,),
  // 快手导入
  ksEntryApi: (data) => axios.apiPost(urlConfig.busiURL + '/busi/oriorders/kuaishou/ksSynOrder', data),



  // 汇总订单汇总记录
  collectorderstatisticsApi: (data) =>
      axios.apiPost(
          urlConfig.busiURL + '/busi/collect/orders/statistics',
          data
      ),

  /**
   * 原始订单新增
   * @param {object} params BusiOriginalOrderAddReq
   * @param {number} params.id 主键ID    自增长
   * @param {string} params.orderNo 订单编号
   * @param {object} params.orderTime 下单时间
   * @param {string} params.mchNo 电商编号
   * @param {number} params.orderAmount 订单金额
   * @returns
   */
  oriordersAdd: (data) => axios.apiPost(urlConfig.busiURL + '/busi/oriorders/add', data),


  oriordersUpdate: (data) => axios.apiPost(urlConfig.busiURL + '/busi/oriorders/update', data),


  payWithholdContractList: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  payWithholdContractRegister: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/register', data),
  payWithholdContractDelete: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/delete?id=' + data),
  payWithholdContractApproval: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/approval?id=' + data),
  payWithholdContractAuthentication: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/authentication?id=' + data),
  payWithholdContractOneKeyCollection: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/oneKeyCollection', data),
  payWithholdContractCollection: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/collection', data),

  payWithholdContractItemList: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/items', data),
  payWithholdContractItemExport: (data) => axios.apiPostDown(urlConfig.busiURL + '/busiPayWithholdContract/items/export', data),
  payWithholdRetryServiceFee: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/retryServiceFee?id=' + data),
  payWithholdDetailApi: (data) => axios.apiPost(urlConfig.busiURL + '/busiPayWithholdContract/detail?id=' + data),


  orderDraftList: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  orderDraftAdd: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/add', data),
  orderDraftEdit: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/edit', data),
  orderDraftDetail: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/getDetails?id='+ data),
  orderDraftInvalid: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/invalid?id='+ data),
  orderDraftSubmit: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/submit?id='+ data),
  orderDraftRevoke: (data) => axios.apiPost(urlConfig.busiURL + '/busiOriginalOrdersDraft/revoke?id='+ data),
  orderDraftApprove: (data) => axios.apiPost(urlConfig.busiURL + `/busiOriginalOrdersDraft/approve?id=${data.id}&checkStatus=${data.checkStatus}&failMsg=${data.failMsg}`, data),

}
