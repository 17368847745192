<script>

import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";

export default {
  components: {WalletChooseDialog, WalletInfo},
  emits: ['detailModified'],
  props: {
    borrower: {
      type: Object,
      required: true
    },

  },
  data() {
    const columns = [
      {title: '服务费项目', dataIndex: 'projectName', key: 'projectName'},
      {
        title: '收取方', dataIndex: 'feeAccountNo', align: 'center', customRender: (text, record) =>
            <WalletInfo accountName={record.feeAccountName} accountNo={record.feeAccountNo} showAccountName={true}
                        showAccountNo={false}/>
      },
      {title: '费率', dataIndex: 'feeName', key: 'feeName'},
      {title: '备注', dataIndex: 'note', key: 'note'},
      {
        title: "状态", dataIndex: "status",
        align: "center",
        customRender: (text, record) => {
          if (text === 1) {
            return <a-tag color="green">开通</a-tag>
          } else {
            return <span>
                  <a-tag color="red">未开通</a-tag>
                  <a-button type="link" icon="reload"
                      onClick={() => this.fetchFeeStatus(record)}
                  ></a-button>
                  </span>
          }
        },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 250,
        align: 'center',
        scopedSlots: {customRender: 'action'}
      }
    ]

    return {
      searchForm: {},
      tableData: [],
      feeRuleList: [],
      columns,
      editDialogVisible: false,
      editForm: {},
    }
  },
  created() {
    this.getFeeRule()
  },
  methods: {
    async fetchFeeStatus(record) {
      let params = {borrowerId: this.borrower.id, feeId: record.id}
      const res = await this.api.borrowerFeeInfoRefreshContractApi(params)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.onDetailModified()
    },

    // 获取服务费规则
    async getFeeRule() {
      let data = {};
      let res = await this.api.getCommisionFeeRuleApi(data);
      if (res.code === 200) {
        // console.log(res, '服务费规则');
        this.feeRuleList = res.data.filter(item => item.feeType == '2');
      }
    },

    onDetailModified() {
      this.$emit('detailModified')
    },
    async onAddOrEdit(record) {
      if (record) {// edit
        // TODO: not implemented
        return
      }
      for (let key in this.editForm) {
        this.editForm[key] = undefined
      }
      this.editForm.borrowerId = this.borrower.id
      this.editForm.borrowerName = this.borrower.borrowerName
      this.editForm.lenderId = this.borrower.lenderId
      this.editForm.lenderName = this.borrower.lenderName
      this.editDialogVisible = true
    },
    async del(record) {
      this.$confirm({
        title: '是否确认删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: async () => {
          let res = await this.api.borrowerFeeInfoDeleteApi(record.id)
          if (res.code !== 200) {
            return this.$message.error(res.info)
          }
          this.$message.success("删除成功")
          this.onDetailModified()
        },
        onCancel() {
        },
      });
    },
    onWalletChosen: async function (wallet) {
      this.editForm.feeAccountNo = wallet.accountNo
      this.editForm.feeAccountName = wallet.accountName
    },
    projectChange(value) {

      if (value === 'CORE') {
        this.editForm.projectName = '核心企业服务费(不含利息)'
      } else if (value === 'AGENCY') {
        this.editForm.projectName = '合作方渠道服务费(不含利息)'
      } else if (value === 'PLAT') {
        this.editForm.projectName = '平台技术服务费'
      }
    },

    handleaFeeChange(event) {
      // 服务规则
      this.feeRuleList.forEach(item => {
        if (item.feeNo == event) {
          this.editForm.feeName = item.feeName
        }
      })
    },
    async onSave() {
      let res = await this.api.borrowerFeeInfoAddOrEditApi(this.editForm)

      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.editDialogVisible = false
      this.onDetailModified()
    },

    onCancel() {
      this.editDialogVisible = false
    },
  }
}
</script>

<template>
  <div>
    <p>
      <a-button @click="onAddOrEdit(undefined)" type="primary">新增</a-button>
    </p>
    <a-table :columns="columns" :data-source="borrower.borrowerFeeInfoList" row-key="id" size="small">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" size="small" @click="del(record)" aria-label="删除">
          <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
        </a-button>
      </template>
    </a-table>
    <a-modal
        title="新增服务费"
        :visible="editDialogVisible"
        @ok="onSave"
        @cancel="onCancel"
        width="600px"
        :body-style="{ height: 'auto', overflow: 'auto' }"
    >
      <a-form-model :model="editForm" :label-col="{span:6}" :wrapper-col="{span:10}">
        <a-form-model-item label="融资人名称">
          <a-input v-model="editForm.borrowerName" :disabled="true"/>
        </a-form-model-item>

        <a-form-model-item label="服务费名目" prop="projectNo">
          <a-select placeholder="请选择服务费名目" @change="projectChange" v-model="editForm.projectNo">
            <a-select-option value="CORE">核心企业服务费(不含利息）</a-select-option>
            <a-select-option value="AGENCY">合作方渠道服务费(不含利息）</a-select-option>
            <a-select-option value="PLAT">平台技术服务费</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="服务费规则" prop="feeRuleNo">
          <a-select @change="handleaFeeChange($event)" v-model="editForm.feeRuleNo" placeholder="请选择服务费规则">
            <a-select-option v-for="item in feeRuleList" :key="item.id" :value="item.feeNo">{{
                item.feeName
              }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item ref="account_name" class="account_name" label="收取方钱包" prop="account_name">
          <WalletChooseDialog v-model="editForm.feeAccountName" :searchable="true" :account-class="'0'"
                              @chooseDone="onWalletChosen"/>
          <WalletInfo v-if="editForm.feeAccountNo" :account-no="editForm.feeAccountNo"
                      :account-name="editForm.feeAccountName"/>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-input v-model="editForm.note"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<style scoped lang="less">

</style>