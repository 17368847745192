<script>
import CountdownBtn from "@/view/components/CountdownBtn.vue";

export default {
  name: "PaymentSms",
  components: {CountdownBtn},
  props: {
    verifyCode: String,
    channelNo: {type: String, required: true}, // 渠道号
    appNo: {type: String, required: false}, // 渠道号
    mobile: {type: String, required: true},// 手机号
    modelNo: {type: String, required: true}, // 短信模板类型
    payAmount: {required: true}, // 交易金额
    accountNo: {type: String, required: true}, // 交易账号
    accountName: {type: String, required: true}, // 交易户名
    cardNo: {type: String, required: false}, // 提现卡号
    idNo: {type: String, required: false}, // 证件号
    tradeType: {type: Number, default: 9}, // 自定义短信类型，均为9
    disabled: {type: Boolean, default: false}, //
  },
  data() {
    return {}
  },
  methods: {
    async sendVerifyCode() {
      // 短信发送参数组装
      let data = {
        tradeType: this.tradeType,
        accountNo: this.accountNo,
        accountName: this.accountName,
        channelNo: this.channelNo,
        amount: this.payAmount,
        mobile: this.mobile,
        modelNo: this.modelNo,
        cardNo: this.cardNo,
        idNo: this.idNo,
      };

      let res = await this.api.vefifycodesend(data);
      if (res.code === 200) {
        return true
      } else {
        this.$message.error(res.info)
        throw new Error(res.info)
      }
    },
  },
  computed: {
    mobileFormatted() {
      try {
        if (this.mobile)
          return this.mobile.substring(0, 3) + "****" + this.mobile.substring(7, 11)
        else return ""
      } catch (e) {
        return this.mobile
      }
    },
    value: {
      get() {
        return this.verifyCode
      },
      set(val) {
        this.$emit("update:verifyCode", val)
      }
    }
  }
}
</script>

<template>

  <div class="sms">
    <a-input class="input-code" v-model.trim="value" :max-length="6"
             placeholder="请输入6位验证码">
      <template #suffix>
        <CountdownBtn :wait-time="60" :error-wait-time="10" :send-call="sendVerifyCode" :disabled="disabled"
                      btn-type="link">
          <i class="anticon anticon-sync">
            <svg width="1em" height="1em" class="icon" viewBox="0 0 1024 1024">
              <path fill="currentColor"
                  d="M122.645333 88.32a64 64 0 0 1 49.578667 2.261333L937.728 454.186667a64 64 0 0 1 0 115.626666L171.882667 933.418667a64 64 0 0 1-87.466667-80l126.101333-341.461334L84.714667 170.517333a64 64 0 0 1 34.432-80.789333zM770.602667 554.666667h-484.906667L182.613333 833.856 770.602667 554.666667zM182.869333 190.08L285.738667 469.333333h484.992L182.869333 190.08z"></path>
            </svg>
          </i>
        </CountdownBtn>
      </template>
    </a-input>
    <span class="desc" v-if="mobile">
      验证手机<span class="mobile">{{ mobileFormatted }}</span>
    </span>
  </div>
</template>

<style scoped lang="less">
.sms {

  align-items: start;
  text-align: left;

  .desc {
    font-size: 0.9em;
    color: grey;

    .mobile {
      color: #1890ff;
    }
  }
}

</style>