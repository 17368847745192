<template>
  <!-- 店铺管理 -->
  <div>
    <a-card title="店铺管理" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">所属机构</div>
            <!-- 所属机构 -->
            <DepartmentChoose :department-code.sync="form.departmentCode" @change="search"></DepartmentChoose>
          </div>
          <!-- 店铺类型 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">店铺类型</div>-->
          <!--            <a-select v-model.trim="form.shopType" placeholder="请选择店铺类型" style="width: 200px">-->
          <!--              <a-select-option value=""> 全部</a-select-option>-->
          <!--              <a-select-option value="0"> 个体店 </a-select-option>-->
          <!--              <a-select-option value="1"> 企业店 </a-select-option>-->
          <!--            </a-select>-->
          <!--          </div>-->
          <!-- 销售平台 -->
          <div class="inputbox">
            <div class="lefttitle">销售平台</div>

            <a-select v-model.trim="form.spNo" placeholder="请选择销售平台" style="width: 200px">
              <a-select-option v-for="item in spNamelist" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 商户名称 -->
          <div class="inputbox">
            <div class="lefttitle">商户名称</div>
            <a-input placeholder="请输入商户名称" v-model.trim="form.mchName"/>
          </div>
          <!-- 商户编号 -->
          <div class="inputbox">
            <div class="lefttitle">商户编号</div>
            <a-input v-model.trim="form.mchNo" placeholder="请输入商户编号"/>
          </div>
          <!-- 店铺名称 -->
          <div class="inputbox">
            <div class="lefttitle">店铺名称</div>
            <a-input v-model.trim="form.shopName" placeholder="请输入店铺名称"/>
          </div>
          <!-- 店铺号 shopNo-->
          <div class="inputbox">
            <div class="lefttitle">店铺号</div>
            <a-input type="number" v-model.trim="form.shopNo" placeholder="请输入店铺号"/>
          </div>

          <!-- 查询 -->

          <a-button class="inquire" @click="search" icon="search" type="primary">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor">
          <!--  新增  Add-->
          <a-button @click="newAdd" type="primary" v-if="$authority('shopAdd')" icon="plus">
            新增
          </a-button>
          <!--  编辑-->
          <a-button @click="edit" type="primary" :disabled="selectedRows.length!==1"
                    icon="edit" v-if="$authority('shopEdit')"> 编辑
          </a-button>
          <!-- 查看 -->
          <a-button @click="view" type="primary" :disabled="selectedRows.length!==1"
                    icon="eye" v-if="$authority('shopView')"> 查看
          </a-button>
          <!-- 删除 -->
          <a-button @click="Deletebutton" type="danger" :disabled="selectedRows.length!==1"
                    icon="delete" v-if="$authority('shopDel')"> 删除
          </a-button>
        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table ref="table" size="small" :rowKey="(record, index) => `${record.id}`" :customRow="clickrow"
                     :row-selection="{
                selectedRowKeys: selectedRowKeys,
                hideDefaultSelections: true,
                onChange: onSelectChange,
              }" :columns="columns" :data-source="data" :pagination="false">
            </a-table>
          </div>
          <lcfs-pagination :page-no.sync="pagination.pageNo" :page-size.sync="pagination.pageSize"
                          :total="total" :fn="fetchShopPage"></lcfs-pagination>
        </div>
      </div>
    </a-card>
    <!-- 选择贷款类型弹窗 -->
    <a-modal title="提示" :maskClosable="true" @cancel="deleteshow = false" :centered="true" :visible="deleteshow">
      <div class="Openanaccount">
        是否确认删除所勾选数据？删除后数据将不可恢复
      </div>
      <template v-slot:footer>
        <div class="custom-footer">
          <a-button type="primary" @click="Confirmdelete">确认</a-button>
          <a-button @click="deleteshow = false">取消</a-button>

        </div>

      </template>
    </a-modal>


  </div>
</template>

<script>

import {BizDict} from "@/utils/bizDict/kvMap";
import {handleChannelNo} from '@/utils/commonMethods'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import LcfsPagination from "@/view/components/LcfsPagination.vue";

export default {
  name: 'MchShopManage',
  components: {LcfsPagination, DepartmentChoose},
  data() {
    return {
      deleteshow: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,

      // 销售平台list
      spNamelist: [],
      data: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      form: {
        departmentCode: undefined, //部门id
        channelNo: '', // channelNo编号
        shopType: '', // 店铺类型
        spNo: '', // 销售平台
        mchName: '', // 商户名称
        mchNo: '', //商户编号
        shopName: '', //店铺名称
        shopNo: '', // 店铺号
      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
              `${Number(this.pagination.pageNo - 1) * Number(this.pagination.pageSize) +
              Number(index + 1)
              }`,
          align: 'center',
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
        },
        {
          title: '平台',
          dataIndex: 'spName',
          align: 'center',
        },
        {
          title: '商户编号',
          dataIndex: 'mchNo',
          align: 'center',
          ellisis: true
        },
        {
          title: '商户名称',
          dataIndex: 'mchName',
          align: 'center',
          ellisis: true
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          align: 'center',
          ellisis: true
        },
        {
          title: '店铺编号',
          dataIndex: 'shopNo',
          align: 'center',
          ellisis: true
        },
        {
          title: "机构",
          dataIndex: "departmentId",
          align: "center",
          customRender: (text) => {
            return <DeptInfo dept-id={text}></DeptInfo>
          },
        },
      ],
    }
  },
  created() {

  },
  mounted() {
    // if (sessionStorage.getItem('msgInfo')) {
      // 从store里读取筛选条件
      // this.form = this.$store.state.searchMsgInfo
    // }
    this.fetchShopPage()
    this.form.departmentCode = sessionStorage.getItem("companyCode")
    // 获取 渠道编号列表和销售平台列表 companylistApi  salePlatformInfolistApi
    this.fetchSalesPlatformList()
  },
  async activated() {
    if (this.$route.query.fromAddPage) {
      this.form = {
        departmentCode: undefined, //部门id
        channelNo: '', // channelNo编号
        shopType: '', // 店铺类型
        spNo: '', // 销售平台
        mchName: '', // 商户名称
        mchNo: '', //商户编号
        shopName: '', //店铺名称
        shopNo: '', // 店铺号
      }
      //  获取列表
      this.fetchShopPage()
    } else if (this.$route.query.fromEditPage) {
      this.fetchShopPage()
    }
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')
  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    // 获取table列表
    async fetchShopPage() {
      const res = await this.api.managerpageApi({...this.form, ...this.pagination})
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.pagination.pageNo = res.data.current
      this.pagination.pageSize = res.data.size

      this.data = res.data.records
    },

    // 获取销售平台列表
    async fetchSalesPlatformList() {
      const res = await this.api.salePlatformInfolistApi()
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      res.data.forEach((item) => {
        this.spNamelist.push({
          label: item.spName,
          value: item.spNo
        })
      })
      this.spNamelist.unshift({
        label: '全部',
        value: '',
      })
    },
    // 点击重置表单
    reset() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.pagination.pageSize = 10
      this.pagination.pageNo = 1
      this.form = {
        departmentCode: undefined, //部门id
        channelNo: '', // channelNo编号
        shopType: '', // 店铺类型
        spNo: '', // 销售平台
        mchName: '', // 商户名称
        mchNo: '', //商户编号
        shopName: '', //店铺名称
        shopNo: '', // 店铺号
      }
      this.fetchShopPage()
    },
    // 搜索查询
    search() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.pagination.pageNo = 1
      this.fetchShopPage()
    },
    // 点击新增
    newAdd() {
      this.$router.push({
        path: '/Mch/MchShopManage/AddMchShopManage',
        query: {
          type: 'add'
        }
      })
    },
    // 点击编辑
    edit() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('查看只能选择一个账户账户')
        return
      }
      // 存储筛选条件
      // this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/Mch/MchShopManage/AddMchShopManage',
        query: {
          type: 'edit',
          id: this.selectedRows[0].id
        }
      })
    },
    // 点击查看
    view() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择店铺')
        return
      }
      if (this.selectedRows.length > 1) {
        this.$message.warning('查看只能选择一个店铺')
        return
      }
      // 存储筛选条件
      // this.$store.commit('saveSearchMsgInfo', this.form)
      this.$router.push({
        path: '/Mch/MchShopManage/AddMchShopManage',
        query: {
          type: 'view',
          id: this.selectedRows[0].id
        }
      })
    },
    // 删除
    Deletebutton() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请先选择账户')
        return
      }
      this.deleteshow = true
    },
    // 点击删除
    async Confirmdelete() {
      let data = []
      this.selectedRows.forEach((item) => {
        data.push(item.id)
      })
      const res = await this.api.mchshopdeleteApi(data)
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.$message.success('删除成功')
      this.selectedRows = []
      this.deleteshow = false
      this.fetchShopPage()
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys.splice(-1, 1)
      this.selectedRows = selectedRows.splice(-1, 1)
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            if (this.selectedRowKeys[0] === record.id.toString()) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id.toString()]
              this.selectedRows = [record]
            }
          }
        }
      }
    },
    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },

  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead > tr > th {
  background-color: #f2f2f2;
}

.ant-modal-footer {

  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}

// 贷款弹窗
.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}
</style>
