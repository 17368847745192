<script>

export default {
  props: {
    pageNo: Number,
    pageSize: Number,
    total: Number,
    fn: Function,
  },
  data() {
    return {
      fnLoading: false
    }
  },
  methods: {
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    firstPage() {
      this.$emit("update:pageNo", 1);
      this.callFn()
    },
    // 尾页
    lastPage() {
      if (this.total === 0) {
        return
      }
      this.$emit("update:pageNo", Math.ceil(Number(this.total) / Number(this.pageSize)));
      this.callFn()
    },
    pageNoChange(pageNo, pageSize) {
      this.$emit("update:pageNo", pageNo);
      this.callFn()
    },
    pageSizeChange(pageNo, pageSize) {
      this.$emit("update:pageNo", 1);
      this.$emit("update:pageSize", pageSize);
      this.callFn()
    },
    callFn() {
      if (!this.fnLoading && this.fn) {
        this.fnLoading = true
        this.fn()
        this.fnLoading = false
      }
    }
  }

}
</script>

<template>
  <div class="pagination-control-box">
    <a-button class="first-page-btn" :disabled="Number(pageNo) === 1" @click="firstPage">
      首页
    </a-button>
    <a-pagination :current="pageNo"
                  :total="total"
                  :page-size="pageSize"
                  :page-size-options="['10', '20', '50', '100']"
                  @change="pageNoChange"
                  @showSizeChange="pageSizeChange"
                  show-size-changer
                  :item-render="itemRender"
    >
    </a-pagination>
    <a-button @click="lastPage"
              :disabled="pageNo === Math.ceil(Number(total) / Number(pageSize))"
              class="last-page-btn">
      尾页
    </a-button>
  </div>
</template>

<style scoped lang="less">
.pagination-control-box {
  display: flex;
  margin-top: 20px;
  justify-content: end;
}
</style>