<template>
  <div>
    <a-card title="贷款申请" style="margin: 20px">
      <div class="loan">
        <!-- !表单区域 -->
        <div class="formbox">
          <!-- 所属机构 -->
          <div class="inputbox">
            <div class="lefttitle">机构</div>
            <!-- 所属机构 -->
            <DepartmentChoose :departmentCode.sync="form.departmentCode"/>
          </div>
          <!-- 渠道编号 -->
          <div class="inputbox">
            <div class="lefttitle">渠道</div>
            <a-select placeholder="请选择核心企业" v-model="form.channelNo" style="width: 200px">
              <a-select-option v-for="item in JSON.parse($session.getItem('coreCompanyList'))" :key="item.channelNo"
                :value="item.channelNo">
                {{ item.channelName }}
              </a-select-option>
            </a-select>
          </div>
          <div class="inputbox">
            <div class="lefttitle">状态</div>
            <a-select v-model.trim="form.status" placeholder="请选择状态" :allowClear="true" style="width: 200px">
              <a-select-option v-for="item in statuslist" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
          <!-- 客户编号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">客户编号</div>-->
          <!--            <a-input v-model.trim="form.custnum" type="number" placeholder="请输入客户编号" />-->
          <!--          </div>-->
          <!-- 客户名称 -->
          <div class="inputbox">
            <div class="lefttitle">客户名称</div>
            <a-input v-model.trim="form.custname" placeholder="请输入客户名称" />
          </div>
          <!-- 账号 -->
          <div class="inputbox">
            <div class="lefttitle">钱包编号</div>
            <a-input type="number" controls="false" v-model.trim="form.accountNo" placeholder="请输入客户账号" />
          </div>
          <!-- 户名 -->
          <!--          <div class="inputbox"> <div class="lefttitle">户名</div>-->
          <!--            <a-input placeholder="请输入户名" v-model.trim="form.accountName" />-->
          <!--          </div>-->

          <!-- 应用编号 -->
          <!--          <div class="inputbox">-->
          <!--            <div class="lefttitle">应用编号</div>-->
          <!--            <a-input type="number" v-model.trim="form.appNo" placeholder="请输入应用编号" />-->
          <!--          </div>-->
          <!-- 手机号 -->

          <!-- 查询 -->

          <a-button class="inquire" @click="search" type="primary">
            查询
          </a-button>
          <!-- 重置 -->
          <a-button @click="reset" class="reset">重置</a-button>
        </div>

        <div class="Applyfor">
          <!--  贷款申请 -->
          <a-button @click="Loanapplication" type="primary" v-if="$authority('loanApplicationF')">
            贷款申请
          </a-button>
          <!-- 查看 -->
          <!--          <a-button @click="view" type="primary" v-if="$authority('loanApplicationView')"> 查看 </a-button>-->
        </div>
        <div class="tablebox">
          <!-- 表格 -->
          <div class="tableBody">
            <a-table size="small" ref="table" :rowKey="(record, index) => `${record.id}`" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              hideDefaultSelections: true,
              onChange: onSelectChange,
            }" :customRow='clickrow' :columns="columns" :data-source="data" :pagination="false">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'name'">
                  <a>
                    {{ record.name }}
                  </a>
                </template>
                <template v-else-if="column.key === 'tags'">
                  <span>
                    <a-tag v-for="tag in record.tags" :key="tag"
                      :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'">
                      {{ tag.toUpperCase() }}
                    </a-tag>
                  </span>
                </template>
              </template>
            </a-table>
          </div>
          <!-- 分页部分 -->
          <div class="paginationBox">
            <a-button class="Homepage" :disabled="Number(form.pageNo) == 1" @click="Homepage">
              首页
            </a-button> <a-pagination v-model.trim="form.pageNo" :page-size-options="pageSizeOptions" :total="total"
              show-size-changer :page-size="form.pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
              :item-render="itemRender">

            </a-pagination>
            <a-button @click="Trailingpage" :disabled="form.pageNo == Math.ceil(Number(total) / Number(form.pageSize))"
              class="Trailingpage">
              尾页
            </a-button>
          </div>
        </div>
      </div>
    </a-card>
    <!-- 选择贷款类型弹窗 -->
    <a-modal title="选择贷款类型" :maskClosable="true" @cancel="loanvisible = false" :centered="true" :visible="loanvisible"
      :footer="null">
      <div class="Openanaccount">
        <!--个人贷款  -->

        <div class="personage" @click="Personalloan" v-if="$authority('personalLoanApplicationF')">个人贷款</div>

        <!-- 企业贷款 -->
        <div class="firm" @click="Enterpriseloan" v-if="$authority('businessLoanApplicationF')">企业贷款</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { BizDict } from "@/utils/bizDict/kvMap";
import { handleChannelNo } from '@/utils/commonMethods'
import WalletInfo from '@/view/components/WalletInfo.vue'
import Balance from '@/view/components/Balance.vue'
import LoanTags from '@/view/components/LoanTags.vue'
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";

export default {
  name: "loanPage",
  components: {DepartmentChoose, WalletInfo, Balance, LoanTags, DeptInfo },
  data() {
    return {
      loanvisible: false, // 贷款弹窗
      statuslist: BizDict.LoanAppStatus,
      total: 0,
      selectedRowKeys: [],
      selectedRows: [], // 选数数组的值,
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      institutiontrees: {},
      data: [],
      form: {
        pageNo: 1,
        pageSize: 10,
        //departmentId: undefined, //部门id
        departmentCode: '', //部门id
        custnum: '', // 客户编号
        custname: '', // 客户名称
        accountNo: '', // 账号
        accountName: '', // 户名
        channelNo: undefined, // 渠道编号
        appNo: '', //应用编号
        status: undefined, // 状态
      },
      columns: [
        {
          title: 'No',
          dataIndex: 'No',
          customRender: (text, record, index) =>
            `${Number(this.form.pageNo - 1) * Number(this.form.pageSize) +
            Number(index + 1)
            }`,
          align: 'center',
          width: 55,
        },
        {
          title: '客户',
          dataIndex: 'accountName',
          align: 'center',
          width: 180,
          ellipsis: true
        },
        {
          title: '钱包',
          dataIndex: 'accountNo',
          align: 'center',
          width: 220,
          customRender: (text, record) => {
            return <WalletInfo accountName={record.accountName}
              accountNo={record.accountNo}
              accountClass='0'
              accountProperty={record.accountProperty}
            ></WalletInfo>
          }
        },
        {
          title: '经营实体',
          dataIndex: 'ecustomerName',
          align: 'center',
          width: 120,
          ellipsis: true,
        },
        // 类型
        {
          title: '类型',
          dataIndex: 'custtypecd',
          align: 'center',
          width: 90,
          ellipsis: true,
          customRender: (text) => {
            if (Number(text) === 1) {
              return '个人'
            } else if (Number(text) === 2) {
              return '企业'
            } else {
              return '---'
            }
          },
        },

        {
          title: '申请金额',
          dataIndex: 'businessApplySum',
          align: 'center',
          width: 140,
          // ellipsis: true
          customRender: (text) => {
            return <Balance amount={Number(text) / 10000} suffix="万"></Balance>
          }
        },
        {
          title: '申请利率',
          dataIndex: 'businessRate',
          align: 'center',
          customRender: (text) => (text ? text + '%' : '0'),
          width: 130,
          // ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          width: 90,
          customRender: (text, row) => {
            // console.log(text);
            let status = BizDict.LoanAppStatus.find(item => item.value === text)
            let statusText = status?.label || "---"
            let statusColor = status?.color
            // if (status.value == '06' || status.value == '05') {
            return <LoanTags selectData={row} status={status}></LoanTags>
            // } else {
            //   return <a-tooltip >
            //     <template slot="title">
            //       {row.statusDesc}
            //     </template>
            //     <a-tag color={statusColor}>{statusText}</a-tag>
            //   </a-tooltip>
            // }
          },
        },

        // 申请时间
        {
          title: '申请时间',
          dataIndex: 'createTime',
          align: 'center',
          customRender: (text) => {
            return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
          },
          ellipsis: true

        },
        // {
        //   title: '客户编号',
        //   dataIndex: 'custnum',
        //   align: 'center',
        //   customRender: (text) => (text ? text : '---'),
        //   width: 100,
        //   ellipsis: true
        // },
        // {
        //   title: '客户名称',
        //   dataIndex: 'custname',
        //   align: 'center',
        //   customRender: (text) => (text ? text : '---'),
        //   ellipsis: true
        //
        // },

        {
          title: '渠道',
          dataIndex: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
          width: 90,
          ellipsis: true

        },
        // {
        //   title: '应用编号',
        //   dataIndex: 'appNo',
        //   align: 'center',
        //   customRender: (text) => (text ? text : '---'),
        // },
        {
          title: '所属机构',
          dataIndex: 'departmentId',
          align: 'center',
          customRender: (text,row) => {
            return <DeptInfo dept-code={row.departmentCode}></DeptInfo>
          },
        },
      ],
    }
  },
  created() {

    //this.form.departmentId = sessionStorage.getItem("companyId");
    // this.form.departmentcode = sessionStorage.getItem("companyCode");
  },
  mounted() {
    // if (sessionStorage.getItem('msgInfo')) {
    //   this.form = this.$store.state.searchMsgInfo
    // }
    this.getloanPage()
  },
  deactivated() {
    sessionStorage.removeItem('msgInfo')

  },
  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {
    // 获取table列表
    async getloanPage() {
      if (!this.form.departmentCode) {
        this.form.departmentCode = sessionStorage.getItem("companyCode")
      }

      const res = await this.api.loanlistApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.total = res.data.total
      this.form.pageNo = res.data.current
      this.form.pageSize = res.data.size

      this.data = res.data.records
    },
    // 点击重置表单
    reset() {
      this.selectedRowKeys = []
      this.form = {  // 不能整个对象赋值，分页会损坏
        pageNo: 1,
        //departmentId: '', //部门id
        pageSize: 10,
        departmentCode: undefined, //部门id
        custnum: '', // 客户编号
        custname: '', // 客户名称

        accountNo: '', // 账号
        accountName: '', // 户名
        channelNo: '', // 渠道编号

        appNo: '', //应用编号
        status: undefined,
      }
      this.getloanPage()
    },
    // 改变当前页的事件函数
    onShowSizeChange(current, pageSize) {
      this.form.pageSize = pageSize
      this.getloanPage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = 1,
        this.getloanPage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.form.pageNo = Math.ceil(Number(this.total) / Number(this.form.pageSize))
      this.getloanPage()
    },
    pagechange(val) {
      this.form.pageNo = val

      this.getloanPage()
    },
    // 搜索查询
    search() {
      this.form.pageNo = 1
      this.getloanPage()
    },
    // 贷款申请
    Loanapplication() {
      this.loanvisible = true
    },
    //选中时触发的回调
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRows.length > 1) {
        //获取选中的数据的key
        var selectNumber = this.selectedRowKeys[1]
        //清空选中的key
        this.selectedRowKeys = []
        //选中的数据的key重新赋值给selectedRowKeys
        this.selectedRowKeys.push(selectNumber)
      }
      //获取选中的数据详细信息
      this.selectedRows = [selectedRows[0]]
      // 每次训中都要清空上次输入得数据
      console.info(this.selectedRows)
    },
    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            // const index = this.selectedRowKeys.findIndex((item) => id == item)
            // if (index == -1) {
            //   this.selectedRowKeys.push(id)
            //   this.selectedRows.push(record)
            // } else {
            //   this.selectedRowKeys.splice(index, 1)
            //   this.selectedRows.splice(index, 1)
            // }
            if (id == this.selectedRowKeys[0]) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = []
              this.selectedRowKeys.push(id)
              this.selectedRows = record
            }
          }
        }
      }
    },

    findItemById(dataObj, idToFind) {
      // 基本情况：如果当前数据对象具有匹配的ID，返回其名称
      if (dataObj == {}) return
      if (dataObj.coreDepartment?.id === idToFind) {
        return dataObj.coreDepartment.name
      }
      // 递归情况：在子数组中搜索
      if (dataObj.children && dataObj.children.length > 0) {
        for (const child of dataObj.children) {
          const foundName = this.findItemById(child, idToFind)
          if (foundName) {
            return foundName
            // 如果在子节点中找到，则返回名称
          }
        }
      }
      // 如果在数据对象中未找到匹配的ID，则返回null
      return null
    },
    // 查看
    // 点击查看
    view() {
      if (this.selectedRows?.length == 0 || !this.selectedRows) {
        this.$message.warning('请先选择账户')
        return
      }
      // 存储筛选条件
      // this.$store.commit('saveSearchMsgInfo', this.form)
      // 企业
      if (this.selectedRows.eCertType) {
        this.$store.commit('saveCorporateloaninformation', this.selectedRows)
        this.$router.push({
          path: '/Financingmanagement/Enterpriseloan',
          query: {
            status: this.selectedRows.status,
            type: 1,// 企业
          },
        })
        //  个人
      } else {
        this.$store.commit('saveLoanpersonalinformation', this.selectedRows)
        this.$router.push({
          path: '/Financingmanagement/Personalloan',
          query: {
            status: this.selectedRows.status,
            type: 0,// 个人
          },
        })
      }
    },
    // 个人贷款
    Personalloan() {
      this.loanvisible = false
      this.$router.push('/Financingmanagement/Personalloan')
      // this.$store.commit('saveLoanpersonalinformation', this.selectedRows)
    },
    // 企业贷款
    Enterpriseloan() {
      this.loanvisible = false
      this.$router.push('/Financingmanagement/Enterpriseloan')
    },
  },
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
</style>
<style lang="less" scoped>
.ant-card-head-title {
  font-size: 14px;
  font-weight: 600;
}

/* 自定义表头样式 */
.ant-table-thead>tr>th {
  background-color: #f2f2f2;
}

.ant-modal-footer {
  border: none;
  padding-bottom: 20px;
}

.ant-modal-content {
  width: 420px;
}

.loan {
  .formbox {
    display: flex;
    flex-wrap: wrap;

    .inputbox {
      width: 260px;
      margin-right: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      input {
        width: 180px;
      }

      .lefttitle {
        min-width: 80px;
      }
    }

    .zhaungtai {
      width: 280px;
      margin-right: 120px;
    }

    .reset {
      width: 64px;
      padding: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }

    button {
      margin-bottom: 20px;
    }

    .ant-tag {
      cursor: pointer;
    }
  }

  .tablebox {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .ant-tag {
      cursor: pointer;
    }
  }

  .paginationBox {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    // .ant-pagination-options {
    //   float: left;
    // }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      border: 1px solid #d9d9d9;
      width: 60px;
    }
  }

  .Applyfor {
    padding-top: 10px;
    margin-bottom: 20px;
  }
}

// 贷款弹窗
.Openanaccount {
  padding: 30px;

  .personage {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .firm {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #555555;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
}
</style>
