<template>
  <!-- 支付协议管理列表页 -->
  <div>
    <a-card title="支付协议管理" style="margin: 20px">
      <!-- 头部筛选区域 -->
      <div class="selected-box">
        <!-- 渠道编号 -->
        <div class="selected-box-item">
          <span class="left-item">渠道编号</span>
          <a-select v-model="selectedData.channelNo" placeholder="请选择渠道">
            <a-select-option v-for="(item, ind) in JSON.parse(this.$session.getItem('coreCompanyList'))"
                             :key="'channelNo' + ind" :value="item.channelNo">{{ item.channelName }}
            </a-select-option>
          </a-select>
        </div>


        <div class="selected-box-item">
          <span class="left-item">任务编号</span>
          <a-input v-model.trim="selectedData.taskNo" placeholder="请输入任务编号"></a-input>
        </div>

        <!-- 申请人 -->
        <div class="selected-box-item">
          <span class="left-item">申请人</span>
          <a-input v-model.trim="selectedData.name" placeholder="请输入申请人"></a-input>
        </div>
        <!-- 申请人证件号 -->
        <div class="selected-box-item">
          <span class="left-item">申请人证件号</span>
          <a-input v-model.trim="selectedData.idNo" placeholder="请输入申请人证件号"></a-input>
        </div>
        <!-- 申请人手机号 -->
        <div class="selected-box-item">
          <span class="left-item">申请人手机号</span>
          <a-input v-model.trim="selectedData.mobile" placeholder="请输入申请人手机号"></a-input>
        </div>
        <!-- 申请人银行卡号 -->
        <div class="selected-box-item">
          <span class="left-item">申请人银行卡号（账户）</span>
          <a-input v-model.trim="selectedData.cardNo" placeholder="请输入申请人手机号（账户）"></a-input>
        </div>
        <!-- 类型 -->
        <div class="selected-box-item">
          <span class="left-item">任务类型</span>
          <a-select v-model="selectedData.taskType">
            <a-select-option value=""> 全部</a-select-option>
            <a-select-option value="0">个人开户</a-select-option>
            <a-select-option value="1">企业开户</a-select-option>
            <a-select-option value="2">个人贷款</a-select-option>
            <a-select-option value="3">企业贷款</a-select-option>
            <a-select-option value="4">归集协议登记</a-select-option>
            <a-select-option value="5">个人开户信息变更</a-select-option>
            <a-select-option value="6">企业开户信息变更</a-select-option>
            <a-select-option value="7">个人自助开户</a-select-option>
            <a-select-option value="8">企业自助开户</a-select-option>
          </a-select>
        </div>
        <!-- 同步状态 -->
        <div class="selected-box-item">
          <span class="left-item">任务状态</span>
          <a-select v-model="selectedData.taskStatus">
            <a-select-option value=""> 全部</a-select-option>
            <a-select-option value="0"> 待处理</a-select-option>
            <a-select-option value="1"> 已处理</a-select-option>
            <a-select-option value="2"> 处理失败</a-select-option>
          </a-select>
        </div>
        <div class="selected-box-item" style="display: block">
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="table-box">
        <div class="table-box-handle">
          <a-button type="danger" @click="addTimeBtn" v-if="$authority('openAccOperation')">延长过期时间</a-button>
          <a-button type="danger" @click="increaNumBtn" v-if="$authority('openAccOperation')">短信次数归零</a-button>
          <a-button type="danger" @click="delBtn" v-if="$authority('OpenAccDel')">删除</a-button>
        </div>
        <div class="table-main">
          <a-table size="small" :scroll="{ x: 2500, }" :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            type: 'radio'

          }" :customRow="clickrow" :columns="columns" :data-source="data" :pagination="false" :type="radio"
                   :rowKey="(record, index) => `${record.id}`">
          </a-table>
        </div>
      </div>
      <!-- 分页部分 -->
      <div class="paginationBox">
        <a-button class="Homepage" :disabled="Number(pageNo) == 1" @click="Homepage">
          首页
        </a-button>
        <a-pagination v-model.trim="pageNo" :page-size-options="pageSizeOptions" :total="total"
                      show-size-changer :page-size="pageSize" @change="pagechange" @showSizeChange="onShowSizeChange"
                      :item-render="itemRender">
          <!-- <template slot="buildOptionText" slot-scope="props">
              <span>{{ props.value }}条/页</span>
            </template> -->
        </a-pagination>
        <a-button @click="Trailingpage" :disabled="pageNo == Math.ceil(Number(total) / Number(pageSize))"
                  class="Trailingpage">
          尾页
        </a-button>
      </div>

      <!-- 删除按钮弹框 -->
      <div class="del-dialog">
        <a-modal title="提示" :body-style="{ textAlign: 'center' }" :visible="isShowDelDialog" :centered="true"
                 @ok="handleOk" @cancel="isShowDelDialog = false">
          <p>是否确认删除?</p>
        </a-modal>
      </div>

      <!-- 延长过期时间按钮弹框 -->
      <div class="del-dialog">
        <a-modal title="提示" :body-style="{ textAlign: 'center' }" :visible="isOpenAccdTimeDialog" :centered="true"
                 @ok="handlAddTimeOk" @cancel="isOpenAccdTimeDialog = false">
          <p>是否确认延长一天过期时间?</p>
        </a-modal>
      </div>
      <!-- 短信限制归0按钮弹框 -->
      <div class="del-dialog">
        <a-modal title="提示" :body-style="{ textAlign: 'center' }" :visible="increaSendSmsNumDialog" :centered="true"
                 @ok="increaNumOk" @cancel="increaSendSmsNumDialog = false">
          <p>是否将已发送短信次数归零?</p>
        </a-modal>
      </div>

    </a-card>
  </div>
</template>

<script>
import {message} from 'ant-design-vue'
import {handleChannelNo} from '@/utils/commonMethods'

const data = []

export default {
  name: 'PaymentProtocolManage',
  props: {},
  data() {
    return {
      // 表格数据
      data,
      columns: [
        {
          title: 'No',
          customRender: (text, record, index) =>
              `${Number(this.pageNo - 1) * Number(this.pageSize) +
              Number(index + 1)
              }`,
          align: 'center',
          key: 'sort',
          width: 55
        },
        {
          title: '渠道',
          dataIndex: 'channelNo',
          key: 'channelNo',
          align: 'center',
          customRender: (text) => {
            return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), text, '1')
          },
          width: 90
        },
        // {
        //   title: '应用编号',
        //   dataIndex: 'appNo',
        //   key: 'appNo',
        //   align: 'center',
        // },
        {
          title: '任务编号',
          dataIndex: 'taskNo',
          key: 'taskNo',
          align: 'center',
        },
        {
          title: '任务类型',  // 0 个人开户 1 企业开户
          dataIndex: 'taskType',
          key: 'taskType',
          align: 'center', ellipsis: true,
          customRender: (text) => {
            return {"0": "个人开户", "1": "企业开户", "2": "个人贷款", "3": "企业贷款", "4": "归集协议登记", "5": "个人开户信息变更", "6": "企业开户信息变更", "7": "个人自助开户", "8": "企业自助开户"}[text]
          }
        },
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '身份证号',
          dataIndex: 'idNo',
          key: 'idNo',
          align: 'center',
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          align: 'center',
        },
        {
          title: '银行卡号',
          dataIndex: 'cardNo',
          key: 'cardNo',
          align: 'center',
        },
        // {
        //   title: '请求报文',
        //   dataIndex: 'reqData',
        //   key: 'reqData',
        //   align: 'center',
        // },
        {
          title: '任务状态',  //0：待处理 1：已处理 2、处理失败
          dataIndex: 'taskStatus',
          key: 'taskStatus',
          align: 'center', ellipsis: true,
          customRender: (text) => {
            return {"0": "待处理", "1": "已处理", "2": "处理失败"}[text]
          }
        },
        {
          title: '过期时间',
          dataIndex: 'expireTime',
          key: 'expireTime',
          align: 'center',
        },
        {
          title: '处理结果',
          dataIndex: 'taskResult',
          key: 'taskResult',
          align: 'center',
        },
        {
          title: '创建人',
          dataIndex: 'creater',
          key: 'creater',
          align: 'center',
          width: 100
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          align: 'center',
          width: 120,
          ellipsis: true
        },
      ],
      selectedRowKeys: [],

      // 筛选参数
      selectedData: {

        // 渠道编号
        channelNo: undefined,
        // 任务编号
        taskNo: '',
        // 申请人
        name: '',
        // 申请人证件号
        idNo: '',
        // 申请人手机号
        mobile: '',
        // 申请人银行卡号
        cardNo: '',
        // 任务类型
        taskType: '',
        //任务状态
        taskStatus: ''

      },
      // 当前页
      pageNo: 1,
      // 一页显示多少条
      pageSize: 10,
      // 总数
      total: 0,
      //分页大小
      pageSizeOptions: ['10', '20', '30', '40', '50'],
      isShowDelDialog: false,
      isOpenAccdTimeDialog: false,
      increaSendSmsNumDialog: false,
      radio: true
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  watch: {},
  created() {
    // 获取机构列表


  },
  mounted() {

    // if (sessionStorage.getItem('msgInfo')) {
    //   this.selectedData = this.$store.state.searchMsgInfo
    // }
    // 获取列表
    this.custOpenAccTaskPage()
  },
  activated() {
    if (this.$route.query.fromAddPage) {
      this.pageNo = 1
      this.selectedData = {
        // 渠道编号
        channelNo: undefined,
        // 任务编号
        taskNo: '',
        // 申请人
        name: '',
        // 申请人证件号
        idNo: '',
        // 申请人手机号
        mobile: '',
        // 申请人银行卡号
        cardNo: '',
        // 任务类型
        taskType: '',
        //任务状态
        taskStatus: ''

      }
      this.custOpenAccTaskPage()

    } else if (this.$route.query.fromEditPage) {
      this.custOpenAccTaskPage()

    }
  },

  destroyed() {
    sessionStorage.removeItem('msgInfo')
  },
  methods: {


    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },

    // 选中行的回调

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeysArray = selectedRowKeys;
      this.selectedRows = selectedRows;
    },

    // 获取列表函数
    async custOpenAccTaskPage() {
      this.selectedData.pageNo = this.pageNo
      this.selectedData.pageSize = this.pageSize
      // obj是个对象
      let res = await this.api.custOpenAccTaskPage(this.selectedData)
      if (res.code == 200) {
        this.data = res.data.records
        this.pageNo = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
      } else {
        this.$message.error(res.info)
      }
      this.selectedRowKeys = []
      this.isOpenAccdTimeDialog = false
      this.isShowDelDialog = false
    },
    // 查询
    handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      this.custOpenAccTaskPage()
    },
    // 重置
    handleReset() {
      this.selectedRowKeys = []
      this.pageNo = 1
      this.pageSize = 10
      this.selectedData = {
        // 渠道编号
        channelNo: undefined,
        // 任务编号
        taskNo: '',
        // 申请人
        name: '',
        // 申请人证件号
        idNo: '',
        // 申请人手机号
        mobile: '',
        // 申请人银行卡号
        cardNo: '',
        // 任务类型
        taskType: '',
        //任务状态
        taskStatus: ''

      }
      this.custOpenAccTaskPage()
    },
    // 改变pagesize的事件函数
    onShowSizeChange(current, pageSize) {

      this.pageNo = current
      this.pageSize = pageSize
      this.custOpenAccTaskPage()
    },
    // 改变当前页的事件
    handleChangePage(page) {
      this.pageNo = page
      this.custOpenAccTaskPage()
    },
    // 修改上一页和下一页为文字链接
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>上一页</a>
      } else if (type === 'next') {
        return <a>下一页</a>
      }
      return originalElement
    },
    // 首页
    Homepage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = 1,
          this.custOpenAccTaskPage()
    },
    // 尾页
    Trailingpage() {
      if (this.total == 0) {
        return
      }
      this.pageNo = Math.ceil(Number(this.total) / Number(this.pageSize))
      this.custOpenAccTaskPage()
    },
    pagechange(val) {
      this.pageNo = val
      this.custOpenAccTaskPage()
    },

    clickrow(record) {
      return {
        on: {
          click: () => {
            let id = record.id.toString();
            if (id == this.selectedRowKeys[0]) {
              this.selectedRowKeys = []

            } else {
              this.selectedRowKeys = []
              this.selectedRowKeys.push(id)

            }
          }
        }
      }
    },

    // 删除
    delBtn() {
      if (this.selectedRowKeys.length == 0) {
        return message.warning('请选择一条记录!')
      } else {
        this.isShowDelDialog = true
      }
    },
    // 延长过期时间
    addTimeBtn() {
      if (this.selectedRowKeys.length == 0) {
        return message.warning('请选择一条记录!')
      } else {
        this.isOpenAccdTimeDialog = true
      }
    },
    // 短信次数归零
    increaNumBtn() {
      if (this.selectedRowKeys.length == 0) {
        return message.warning('请选择一条记录!')
      } else {
        this.increaSendSmsNumDialog = true
      }
    },


    // 弹框确认删除事件
    async handleOk() {
      let idArr = []
      idArr.push(this.selectedRowKeys[0])
      let res = await this.api.custOpenAccTaskDelApi(idArr)
      this.selectedRowKeys = []
      this.isShowDelDialog = false
      if (res.code == 200) {
        this.$message.success(res.info)
      } else {
        return this.message.error(res.info)
      }
      this.custOpenAccTaskPage()
    },
    // 弹框确认
    handlAddTimeOk: async function () {
      console.log(this.selectedRowKeys)
      let reqData = {
        "time": 1,
        "id": this.selectedRowKeys[0]
      }
      console.log(reqData)
      let res = await this.api.custOpenAccTaskAddTime(reqData)
      this.isOpenAccdTimeDialog = false
      this.selectedRowKeys = []
      if (res.code == 200) {
        this.$message.success(res.info)
      } else {
        this.message.error(res.info)
      }
      this.custOpenAccTaskPage()
    },
    // 弹框确认
    increaNumOk: async function () {
      let reqData = {
        "id": this.selectedRowKeys[0]
      }
      console.log(reqData)
      let res = await this.api.custOpenAccTaskIncreaSmsNum(reqData)
      this.selectedRowKeys = []
      this.increaSendSmsNumDialog = false
      if (res.code == 200) {

        this.$message.success(res.info)
      } else {
        this.message.error(res.info)
      }
      this.custOpenAccTaskPage()
    },
  },
}
</script>

<style lang="less" scoped>
.selected-box {
  display: flex;
  flex-wrap: wrap;

  /deep/ .selected-box-item {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    width: 280px;
    margin-bottom: 20px;
    align-items: center;

    .left-item {
      margin-right: 15px;
    }

    .ant-input {
      width: 200px;
    }

    .ant-select-selection--single {
      width: 200px;
    }
  }
}

.table-box {
  .table-box-handle {
    margin-bottom: 20px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  // .ant-pagination-options {
  //   float: left;
  // }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    border: 1px solid #d9d9d9;
    width: 60px;
  }
}
</style>
