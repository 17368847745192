<script>

import WalletInfo from "@/view/components/WalletInfo.vue";
import ChannelInfo from "@/view/components/ChannelInfo.vue";

export default {
  name: 'settle-account-select',
  components: {ChannelInfo, WalletInfo},
  emits: ['change', 'update:value'],
  props: {
    departmentCode: String,
    disabled: Boolean,
    value: Number,
    defaultFirst: {
      type: Boolean,
      default: true
    },
    allowClear: Boolean
  },
  data() {
    return {
      pagination: {
        pageSize: 1000,
        pageNo: 1,
      },
      innerValue: this.value,
      searchForm: {
        departmentCode: this.departmentCode
      },
      options: [],
    }
  },
  methods: {
    onChange(id) {
      const lender = this.options.find(i => i.id === id)
      // value.sync
      this.$emit("update:value", id)
      // @change
      this.$emit("change", lender)
    },
    async fetchData() {
      // 检查必填项
      const res = await this.api.settleAccountApi({
        obj: {...this.searchForm},
        ...this.pagination
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.options = res.data.records
      // 默认选中第一个
      if (this.defaultFirst && !this.innerValue && this.options && this.options.length > 0) {
        this.innerValue = this.options[0].id
        this.onChange(this.options[0].id)
      }
      return true
    },

    filterOption(input, option) {
      let val = input?.trim().toLowerCase()
      if (val.length < 1) return false;
      const children = option.componentOptions.children[0].children.map(m => m.text).filter(m => m)
      return (
          children.find(m => m.toLowerCase().indexOf(val) >= 0)
      );
    },
  },
  async mounted() {
    await this.fetchData()
  },
  watch: {
    departmentCode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchForm.departmentCode = newVal
        this.fetchData()
      }
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.innerValue = newVal
      }
    },
  }
}
</script>

<template>
  <a-select class="settle-account.select"
            :filter-option="filterOption"
            :show-search="true"
            v-model="innerValue" placehoder="请选择待清算账号" :allowClear="allowClear"
            @change="onChange">
    <a-select-option v-for="item in options" :key="item.id" :value="item.id" :title="item.accountNameSettling1">
      <span v-show="false">{{item.accountNameSettling1}}</span>
      <span><WalletInfo :account-no="item.accountNoSettling1" :account-name="item.accountNameSettling1"
                         account-class="9"
                         :show-account-name="true"
                         :show-account-no="false"
                         :show-balance="false"></WalletInfo> </span>
      <span style="float:right;">
        <ChannelInfo :channel-no="item.channelNo" :size="18"></ChannelInfo>
      </span>
    </a-select-option>
  </a-select>
</template>

<style scoped lang="less">
.settle-account-select {
  min-width: 200px;

  /deep/ .ant-select {
    width: 100%;
  }
}
</style>