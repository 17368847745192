<script>
import {handleChannelNo} from "@/utils/commonMethods";

export default {
  name: 'channelInfo',
  props: {
    channelNo: String,
    size: {
      type: Number,
      default: 32
    }
  },
  data() {
    return {}
  },
  computed: {
    channelName() {
      return handleChannelNo(JSON.parse(this.$session.getItem('coreCompanyList')), this.channelNo, '1')
    }
  }
}

</script>

<template>
  <span>
    <a-avatar v-if="channelName" :size="size"> {{ channelName.substring(0, 1) }} </a-avatar>
    <a-avatar v-else :size="size"> {{ channelNo }} </a-avatar>
  </span>
</template>

<style scoped lang="less">

</style>