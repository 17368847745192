import Vue from 'vue'
import Vuex from 'vuex'
import example from './example'
import customer from './customer'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    count: 0,
    Loanpersonalinformation: {},// 个人贷款信息
    Corporateloaninformation: {},//  企业贷款信息
    // 账户管理查看用户信息
    Accountmanagementinfo: {},
    // 清分信息
    settlementAccountInfo: {},
    // 钱包信息存储
    Walletinformation: {},
    // 下载模板列表
    getMapList: [],
    // 电商费用模板
    mchFeeTpl: [],
    // 电商余额费用模板
    mchPayFeeTpl: [],

    payWithholdFeeTpl: [],
    // 来账白名单列表
    incomingWhitelistAccounts: [],
    // 公告
    announcement: [],
    // 客户经理列表
    mchInputUser: [],
    // 存储列表页的筛选数据
    searchMsgInfo: {},
    // 融资电商跳转到订单支付界面数据
    orderData: {},
    keepAliveArr:[],
    // 存储账户管理修改数据
    accountEdit:{}
  },
  mutations: {
    increment(state) {
      state.count++
    },
    // 存储个人贷款信息
    saveLoanpersonalinformation(state, data) {
      state.Loanpersonalinformation = data
    },
    //  存储企业贷款信息
    saveCorporateloaninformation(state, data) {
      state.Corporateloaninformation = data
    },
    //存储账户管理查看用户信息
    saveAccountmanagementinfo(state,data) {
      state.Accountmanagementinfo = data
    },
    //存储账户管理查看用户信息
    saveSettlementAccountInfo(state, data) {
      state.settlementAccountInfo = data
    },
    saveWalletinformation(state, data) {
      state.Walletinformation = data
    },
    //存储下载模板列表
    savegetMapList(state, data) {
      state.getMapList = data
    },
    // 存储费用模板
    mchFeeTpl(state, data){
     state.mchFeeTpl = data
    },

    mchPayFeeTpl(state, data){
     state.mchPayFeeTpl = data
    },

    payWithholdFeeTpl(state, data){
     state.payWithholdFeeTpl = data
    },
    // 来账白名单
    incomingWhitelistAccounts(state, data){
      state.incomingWhitelistAccounts = data
    },
    announcement(state, data){
      state.announcement = data
    },
    mchInputUser(state, data){
      state.mchInputUser = data
    },
    // 存储列表页的筛选数据
    saveSearchMsgInfo(state, data) {
      state.searchMsgInfo = data
    },
    // 存储融资电商跳转到订单支付界面数据
    saveOrderInfo(state, data) {
      state.orderData = data
    },
    addKeepAlive(state, data) {
      if (!state.keepAliveArr.includes(data)) {
        state.keepAliveArr.push(data)
        }
    },
    delKeepAliveList(state, data) {
      state.keepAliveArr = state.keepAliveArr.filter(item => item !== data && item !== undefined && item !== null)
    },
    saveAccountEditData(state, data) {
      state.accountEdit = data
     }
  },
  modules: {
    example,
    customer,
  },
  // vuex 数据持久化配置
  plugins: [
    createPersistedState({
      // 存储方式
      storage: sessionStorage,
      key: 'vuexStore',
    }),
  ],
})

export default store
