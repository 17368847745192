// 电商管理
import axios from '@/utils/axios'
import urlConfig from '@/utils/urlConfig'

export default {
//  电商管理新增
addMchManageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/live/addOrUpdate', data),
    newAddMchManageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/live/addOrUpdateNew', data),
  // 电商管理详情
  getMchManageDataApi: (data) => axios.apiGet(urlConfig.busiURL + '/mch/live/getMchLiveDetails', data),
  // 电商管理编辑
  editMchManageDataApi: (data) => axios.apiPost(urlConfig.busiURL + '/mch/live/update', data),
  // 电商管理删除
  delMchManageDataApi: (data) => axios.apiPost(urlConfig.busiURL + '/mch/live/delete', data),
  // 电商服务费信息删除
  delMchFeeDataApi: (data) => axios.apiPost(urlConfig.busiURL + '/mch/fee/delete', data),

  delMchFeePayDataApi: (data) => axios.apiPost(urlConfig.busiURL + '/mchFeePayInfo/delete', data),

  getMchPayCalFeeApi: (data) => axios.apiPost(urlConfig.busiURL + '/mchFeePayInfo/calFee', data),
  //电商支付服务费
  getMchFeeApi: (data) => axios.apiPost(urlConfig.busiURL + '/mch/fee/getMchFee', data),
  // 服务费规则
  getCommisionFeeRuleApi: (data) => axios.apiPost(urlConfig.busiURL + '/feeRule/list', data),
  // 获取核心企业
  getCoreCompanyApi: (data) => axios.apiPost(urlConfig.busiURL + '/core/company/getByUsername', data),
  
   //待清算协议管理page
  getMchContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/busiSettleContract/page', data),
     //待清算协议新增
  addMchContractApi: (data) => axios.apiPost(urlConfig.busiURL + '/busiSettleContract/addOrUpdate', data),
   //清算账户详情
   detailMchContractApi: (data) => axios.apiGet(urlConfig.busiURL + '/busiSettleContract/getDetails', data),
     //清算账户
  getClearAccountApi: (data) => axios.apiGet(urlConfig.busiURL + '/busiSettleAccount/findByChannelNo?channelNo=' + data),
      //清算账户余额同步
      clearBalanceSyncApi: (data) => axios.apiGet(urlConfig.busiURL + '/busiSettleContract/balanceSynch', data),

  // 查询交易明细
  settleBalanceLogQueryApi: (data) => axios.apiPost(urlConfig.busiURL + '/busiSettleContract/settleBalanceLogQuery', data),

  //电商提现管理page
  getMchWithDrawalApi: (data) => axios.apiPost(urlConfig.busiURL + '/mchWithdrawalRecords/page', data),
  //提现记录导出
  withdrawManuallyExport: (data) => axios.apiPostDown(urlConfig.busiURL + '/mchWithdrawalRecords/export', data),

  //电商手动提现
  getMchWithDrawalManually: (data) => axios.apiPost(urlConfig.busiURL + '/mchWithdrawalRecords/withdraw?shopNo='+ data),


  // 店铺管理渠道列表 
  companylistApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/core/company/list', data),
  // 店铺管理销售平台列表
  salePlatformInfolistApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/salePlatformInfo/list', data),
  // 店铺账户信息
  managerpageApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/shop/page?pageNo=' + data.pageNo + '&pageSize=' + data.pageSize, data),
  // 电商店铺信息删除
  mchshopdeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/shop/delete', data),
  // 电商店铺管理页新增修改
  addOrUpdateApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/shop/addOrUpdate', data),

  // 电商管理页详情 
  getMchShopDetailsApi: (data) =>
    axios.apiGet(urlConfig.busiURL + '/mch/shop/getMchShopDetails?id=' + data,),
  // 电商主播信息删除

  broadcastdeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/broadcast/delete', data),
  // 店铺账户删除 /mch/shop/manager/delete
  mchbroadcastdeleteApi: (data) =>
    axios.apiPost(urlConfig.busiURL + '/mch/shop/manager/delete', data),
  //用户管理-绑定电商
  userBindMchApi:(data)=>axios.apiPost(urlConfig.busiURL + '/mchLiveUser/addOrUpdate',data),


  mchShopInvoiceListApi: (data) =>
      axios.apiPost(urlConfig.busiURL + `/mch/shopInvoice/list?pageNo=${data.pageNo}&pageSize=${data.pageSize}`, data),


  verifyInvoiceWithOptions: (data) =>
      axios.apiPost(urlConfig.busiURL + '/base/ocr/verifyInvoiceWithOptions', data),

  mchShopInvoiceRegisterApi: (data) =>
      axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/register', data),


  mchShopInvoiceImportApi: (formData) =>
      axios.apiFormPost(urlConfig.busiURL + '/mch/shopInvoice/import', formData),



  //查询流水
  getDetails: (data) =>
      axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/getDetails?id=' + data),

//   作废
  mchShopInvoiceInvalidApi: (data) =>
      axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/invalid', data),

//   清算
  mchShopInvoiceSettleApi: (data) =>
      axios.apiGet(urlConfig.busiURL + '/mch/shopInvoice/settle?id=' + data,),

  //查询流水
  mchShopInvoiceDepositTransQueryApi: (data) =>
      axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/deposit?id=' + data),

  //查询流水
  mchShopInvoiceUnmatchedTransQueryApi: (data) =>
      axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/unmatched?id=' + data),

  //匹配流水
  mchShopInvoiceMatchApi: (data) =>
  axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/match', data),

  //发票到账明细导入
  mchShopInvoiceDepositApi: (formData) =>
      axios.apiFormPost(urlConfig.busiURL + '/mch/shopInvoice/deposit/import', formData),

  //发票到账明细导入
  mchShopInvoiceTransItemListApi: (data) =>
      axios.apiFormPost(urlConfig.busiURL + '/mch/shopInvoice/invoiceTransItem/list?mchNo=' + data.mchNo + '&refundAccountNo=' + data.refundAccountNo + '&registerDate=' + data.registerDate),  //发票到账明细导入

  invoiceAutoMatchByTransApi: (data) =>
      axios.apiFormPost(urlConfig.busiURL + '/mch/shopInvoice/invoiceAutoMatchByTrans?mchNo=' + data.mchNo + '&refundAccountNo=' + data.refundAccountNo + '&registerDate=' + data.registerDate),

  //获取当天发票到账明细b标记
  mchShopInvoiceMarktApi: (data) =>
      axios.apiPost(urlConfig.busiURL + '/mch/shopInvoice/invoiceTransItem/mark',data),


}
