<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="top-title">
          <div class="left">
            <span>融资人</span>
          </div>
          <div class="right">
            <a-form-model layout="inline">
              <a-form-model-item label="机构">
                <DepartmentChoose :department-code.sync="form.departmentCode" @change="handleQuery"></DepartmentChoose>
              </a-form-model-item>
              <a-form-model-item label="出资人">
                <lender-select :value.sync="form.lenderId" :department-code="form.departmentCode"
                               @change="onSearchLenderChange" allow-clear></lender-select>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </template>
      <div class="loan">
        <!-- 头部筛选区域 -->
        <div class="search-box">
          <a-form-model layout="inline">
            <a-form-model-item label="融资人">
              <a-input v-model.trim="form.borrowerName" placeholder="请输入融资人名称"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" @click="handleQuery" icon="search">查询</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="action-box">
          <a-button @click="register(undefined)" type="primary" v-if="$authority('borrower-add')" icon="plus"> 融资人登记
          </a-button>
        </div>
        <div class="table-box">
          <a-table :columns="columns" :data-source="tableData" :row-key="(record) => record.id" :pagination="false"
                   bordered
                   size="small">
            <template slot="action1" slot-scope="text, record">
              <template v-if="record.status ===1">
                <router-link v-if="$authority('advanceAdd')"
                    :to="{ name: 'advanceAdd', params: { lenderId: record.lenderId, borrowerId: record.id } }">
                  <a-button type="link" icon="transaction">垫资</a-button>
                </router-link>
                <router-link  v-if="$authority('reclaimSettle')"
                    :to="{ name: 'reclaimSettle', params: { lenderId: record.lenderId, borrowerId: record.id } }">
                  <a-button type="link" icon="fork">清算</a-button>
                </router-link>
              </template>
              <template v-else>
                <a-tag color="red">未开通</a-tag>
                <a-button type="link" icon="reload" @click="refreshContract(record)"/>
              </template>
            </template>
            <template slot="action2" slot-scope="text, record">
              <a-button type="link" size="small" v-if=" $authority('borrower-edit') " @click="detail(record)">
                <a-icon type="setting" theme="twoTone"/>
              </a-button>
              <a-button type="link" size="small" v-if=" $authority('borrower-delete') " @click="del(record)">
                <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
              </a-button>
            </template>
          </a-table>
          <LcfsPagination :page-no.sync="pagination.pageNo" :page-size.sync="pagination.pageSize"
                          :total="total" :fn="fetchData"></LcfsPagination>
        </div>
      </div>
      <a-modal
          title="融资人登记"
          :visible="registerVisible"
          @ok="borrowerAdd"
          @cancel="registerVisible=false"
      >
        <a-form-model :model="add" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item label="出资人">
            <lender-select :value.sync="add.lenderId" :department-code="form.departmentCode"
                           @change="onAddLenderChange"></lender-select>
          </a-form-model-item>
          <!--          <a-form-model-item label="融资人名称">-->
          <!--            <a-input v-model="add.borrowerName" :disabled="true"/>-->
          <!--          </a-form-model-item>-->
          <a-form-model-item ref="account_name" class="account_name" label="融资人钱包" prop="account_name">
            <WalletChooseDialog v-model="add.accountName" :searchable="true" account-class="6"
                                @chooseDone="onAddWalletChosen"/>
            <WalletInfo v-if="add.account_no" account-class="6" :account-no="add.account_no"
                        :account-name="add.account_name"/>
          </a-form-model-item>

        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {dict_rawdata as busiDict} from "@/utils/bizDict/rawdata";
import Balance from "@/view/components/Balance.vue";
import DepartmentChoose from "@/view/components/DepartmentChoose.vue";
import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import DeptInfo from "@/view/components/DeptInfo.vue";
import LcfsPagination from "@/view/components/LcfsPagination.vue";
import LenderSelect from "@/view/nbi/lender/lender-select.vue";
import ChannelInfo from "@/view/components/ChannelInfo.vue";

export default {
  name: 'borrower',
  components: {
    LenderSelect,
    LcfsPagination,
    WalletChooseDialog, WalletInfo,
    DepartmentChoose,
    // eslint-disable-next-line vue/no-unused-components
    Balance
  },
  computed: {
    busiDict() {
      return busiDict
    }
  },
  data() {
    return {
      labelCol: {span: 6},
      wrapperCol: {span: 10},
      registerVisible: false,
      lenderList: [],
      tableData: [],
      add: {
        lenderId: undefined,
        lenderName: undefined,
        borrowerName: undefined,
        accountNo: undefined,
        accountName: undefined
      },
      total: 0,
      pagination: {pageNo: 1, pageSize: 10},
      form: {departmentCode: undefined, borrowerName: undefined, lenderId: undefined, lenderName: undefined},
      columns: [
        {
          title: 'No', dataIndex: 'No', align: 'center', width: 70, customRender: (text, record, index) =>
              `${Number(this.pagination.pageNo - 1) * Number(this.pagination.pageSize) + Number(index + 1)}`
        },
        {
          title: '融资人', align: 'center', dataIndex: 'borrowerName', customRender: (text, record) => {
            if (this.$authority('borrower-edit')) {
              return <a-button type="link" onClick={() => this.detail(record)}>
                <a-tag color="purple">融</a-tag>
                {text} </a-button>
            } else {
              return <div>
                <a-tag color="purple">融</a-tag>
                {text}</div>
            }
          }
        },
        {
          title: '出资人', dataIndex: 'lenderName', align: 'center', customRender: (text, record) =>
              <div>
                <a-tag color="cyan">资</a-tag>
                {text}</div>
        },
        {title: "机构", dataIndex: "departmentId", align: "center", customRender: (text) => <DeptInfo dept-id={text}/>},
        {
          title: "渠道",
          dataIndex: "channelNo",
          align: "center",
          width: 50,
          customRender: (text) => <ChannelInfo channel-no={text}/>
        },
      ...(!this.$authority('advanceAdd') && !this.$authority('reclaimSettle'))?[]:[
        {
          title: '操作',
          key: 'action1',
          width: 210,
          align: 'center',
          fixed: 'right',
          scopedSlots: {customRender: 'action1'}
        }],
      ...(!this.$authority('borrower-edit') && !this.$authority('borrower-delete'))?[]:[
        {
          title: '配置',
          key: 'action2',
          width: 100,
          align: 'center',
          fixed: 'right',
          scopedSlots: {customRender: 'action2'}
        }],
      ],
    };
  },
  created() {
  },
  mounted() {
  },
  activated() {
  },
  methods: {
    async refreshContract(data) {
      await this.api.borrowerRefreshContractApi(data.id)
      await this.fetchData()
    },
    // 查询
    async handleQuery() {
      this.pageNo = 1
      this.selectedRowKeys = []
      await this.fetchData()
    },
    async borrowerAdd() {
      const res = await this.api.borrowerAddOrEditApi(this.add)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success(res.info)
      this.registerVisible = false
      await this.fetchData()
    },
    onSearchLenderChange(lender) {
      this.form.lenderName = lender?.lenderName
      this.fetchData()
    },
    onAddWalletChosen(wallet) {
      this.add.accountNo = wallet.accountNo
      this.add.accountName = wallet.accountName
      this.add.borrowerName = wallet.accountName
      this.add.accountClass = wallet.accountClass
    },
    onAddLenderChange(lender) {
      this.add.lendername = lender.lenderName
    },
    //
    async register(row) {
      if (!row) {
        for (let key in this.add) {
          this.add[key] = undefined
        }
        this.add.lenderId = this.form.lenderId
        this.add.lenderName = this.form.lenderName
      } else {
        this.add = {...row}
      }
      this.registerVisible = true
    },
    //
    async detail(row) {
      await this.$router.push({
        path: '/nbi/borrower/detail',
        query: {
          id: row.id,
        }
      })
    },
    //删除
    async del(row) {
      this.$confirm({
        title: '是否确认删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.api.borrowerDeleteApi(row.id).then((res) => {
            if (res.code !== 200) {
              this.$message.warning(res.info)
            } else {
              this.$message.success(res.info)
              this.fetchData()
            }
          })
        },
        onCancel() {
        },
      });
    },
    // 获取table列表
    async fetchData() {
      // 检查必填项
      const res = await this.api.borrowerPageApi({...this.form, ...this.pagination})
      if (res.code !== 200) {
        this.$message.error(res.info)
        return false
      }
      this.total = res.data.total
      this.tableData = res.data.records
      return true
    },

    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>

<style lang="less" scoped>
.top-title {
  .left {
    float: left;
  }

  .right {
    float: right;
  }
}

.search-box {
  display: flex;
  flex-wrap: wrap;
}

.action-box {
  padding-top: 10px;
  margin-bottom: 20px;
}
</style>
