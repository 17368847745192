<template>
  <div>
    <a-card style="margin: 20px">
      <template #title>
        <div class="title-box">
          <div class="title-box-right">
            订单支付>发起支付
          </div>
          <div class="title-box-right">
            <a-button @click="$router.go(-1)">取消支付</a-button>
          </div>
        </div>
      </template>
      <div class="form-box">
        <!-- 电商名称 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4"><span>付款人</span></a-col>
            <a-col :span="20">
              <MchChooseDialog style="width: 300px;" v-model="paramsData.mchName" @chooseDone="onMchChosen" mchType="1">
              </MchChooseDialog>
            </a-col>
          </a-row>
        </div>

        <!-- 支付方式 -->
        <div v-if="paramsData.mchName">
          <div class="form-box-item">
            <a-row>
              <a-col :span="4">
                <span>付款方式</span>
              </a-col>
              <a-col :span="20">
                <a-radio-group v-model="paramsData.payWay" @change="changePayMode">
                  <a-radio-button value="2" v-if="isShowLoanPay">融资支付</a-radio-button>
                  <!-- 注：余额支付字段由3变成了1 -->
                  <a-radio-button value="1">余额支付</a-radio-button>
                  <a-radio-button value="4" v-if="isShowClearPay && clearAccount[0]?.accountNo"> 清分支付
                  </a-radio-button>
                </a-radio-group>
              </a-col>
            </a-row>
          </div>
        </div>

        <!--  融资支付  -->
        <div v-if="paramsData.payWay == '2'">
          <div class="form-box-item">
            <a-row>
              <a-col :span="4">
                <span>可贷款额度</span>
              </a-col>
              <a-col :span="20">
                <span class="link_area">
                  <Balance :amount="currentLoan" suffix="元" :dcflag="currentLoan?'d':'c'"
                           :align-right="false"></Balance>
                </span>

                <a-button type="link" size="small" @click="genOrder">追加订单</a-button>
                <a-button type="link" size="small" @click="showDetail = !showDetail">
                  {{ showDetail ? "收起" : "详细" }}
                  <a-icon type="down-circle" theme="twoTone" :class="showDetail ? 'up' : 'down'"/>
                </a-button>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4"></a-col>
              <a-col :span="8">
                <a-alert v-if="isShowWaring" type="error" :message="waringText" closable @close="isShowWaring = false"/>
              </a-col>
            </a-row>
          </div>
          <!-- 清算协议中待清算金额 -->
          <div class="form-box-item" v-if="showDetail && isShowClearPay">
            <a-row>
              <a-col :span="4">
                <span>待清算金额</span>
              </a-col>
              <a-col :span="20">
                <span class="link_area">
                  <Balance :amount="toSettle" dcflag="d" prefix="￥" suffix="元" :align-right="false"></Balance>
                </span>
              </a-col>
              <!--  余额支付  -->
            </a-row>
          </div>
          <!--累计已用额度 -->
          <div class="form-box-item" v-if="showDetail">
            <a-row>
              <a-col :span="4">
                <span>累计已用额度</span>
              </a-col>
              <a-col :span="20">
                <span style="display:inline-block;width:150px">
                  <Balance :amount="accumulatedUsed||0" dcflag="c" suffix="元" :align-right="false"></Balance>
                </span>
                <span style="display:inline-block;width:150px;">
                  <Balance :amount="totalLoan||0" prefix="/ ￥" suffix="元"></Balance>
                </span>
                <a-tag color="purple">{{ (Number(accumulatedUsed) / Number(totalLoan) * 100).toFixed(0) }}%</a-tag>
              </a-col>
              <!--  余额支付  -->
            </a-row>
          </div>
          <!-- 贷款申请人户名 -->
          <div class="form-box-item" v-if="showDetail">
            <a-row>
              <a-col :span="4">
                <span>融资人</span>
              </a-col>
              <a-col :span="20">
                <a-input disabled v-model="paramsData.accountNameSq"></a-input>
              </a-col>
            </a-row>
          </div>
        </div>
        <!--  余额支付  -->
        <div v-if="paramsData.payWay == '1'">
          <!-- 钱包账号 -->
          <div class="form-box-item">
            <a-row>
              <a-col :span="4">
                <span>钱包账号</span>
              </a-col>
              <a-col>
                <a-radio-group v-model="paramsData.accountNo" @change="changeAccount($event)">
                  <a-radio-button v-for="(item, ind) in payerWalletList" :key="'payerWalletList' + ind"
                                  :value="item.accountNo"
                                  :disabled="!$authority('A2BalanceVisible') && item.accountClass == '6'">
                    <a-tag :color="item.accountClass == '0' ? 'green' : 'blue'">{{
                        item.accountClass == '0' ? '活期' : '冻结'
                      }}
                    </a-tag>
                    <span class="right">{{ item.accountNo }}</span>
                  </a-radio-button>
                </a-radio-group>
              </a-col>
            </a-row>
          </div>
          <!-- 可使用余额 -->
          <div class="form-box-item">
            <a-row style="display: flex; align-items: center;">
              <a-col :span="4">
                <span>可用余额</span>
              </a-col>
              <a-col :span="4">
                <Balance :amount="avibaleBalanceA2" :align-right="false"></Balance>
              </a-col>
              <a-col>
                <a-button type="link" size="small" @click="showDetail = !showDetail">
                  {{ showDetail ? "收起" : "详细" }}
                  <a-icon type="down-circle" theme="twoTone" :class="showDetail ? 'up' : 'down'"/>
                </a-button>
              </a-col>
            </a-row>
          </div>
          <!-- 付款账户账号 -->
          <div class="form-box-item" v-if="showDetail">
            <a-row>
              <a-col :span="4">
                <span>付款账户账号</span>
              </a-col>
              <a-col :span="20">
                <a-input disabled placeholder="请填写付款账户账号" v-model="paramsData.accountNo">
                </a-input>
              </a-col>
            </a-row>
          </div>
          <!-- 付款账户户名 -->
          <div class="form-box-item" v-if="showDetail">
            <a-row>
              <a-col :span="4">
                <span>付款账户户名</span>
              </a-col>
              <a-col :span="20">
                <a-input disabled placeholder="请填写付款账户户名" v-model="paramsData.accountName">
                </a-input>
              </a-col>
            </a-row>
          </div>
        </div>
        <!--  清分支付  -->
        <div v-if="paramsData.payWay == '4'">
          <!-- 钱包账号 -->
          <div class="form-box-item">
            <a-row>
              <a-col :span="4">
                <span>待清算户</span>
              </a-col>
              <a-col style="display: flex;align-items: center;">
                <a-radio-group v-model="paramsData.accountNoSettling" @change="changeClearAccount">
                  <a-radio-button v-for="(item, ind) in clearAccount" :key="'clearAccount' + ind"
                                  :value="item.accountNo">
                    <a-tag color="cyan">{{ item.grade == "1" ? '一级' : '二级' }}</a-tag>
                    <span class="right">{{ item.accountNo }}</span>
                  </a-radio-button>
                </a-radio-group>
                <!--                <span style="margin-left: 10px; color: red;">*请使用二级待清支付</span>-->
              </a-col>
            </a-row>
          </div>
          <!-- 可使用余额 -->
          <div class="form-box-item">
            <a-row style="display: flex; align-items: center;">
              <a-col :span="4">
                <span>可用余额</span>
              </a-col>
              <a-col :span="4">
                <Balance :amount="avibaleBalanceA2" :align-right="false"></Balance>
              </a-col>
              <a-col>
                <a-button type="link" size="small" @click="showDetail = !showDetail">
                  {{ showDetail ? "收起" : "详细" }}
                  <a-icon type="down-circle" theme="twoTone" :class="showDetail ? 'up' : 'down'"/>
                </a-button>
              </a-col>
            </a-row>
          </div>
          <!-- 付款账户账号 -->
          <div class="form-box-item" v-if="showDetail">
            <a-row>
              <a-col :span="4">
                <span>付款账户账号:</span>
              </a-col>
              <a-col :span="20">
                <a-input disabled placeholder="请填写付款账户账号" v-model="paramsData.accountNoSettling">
                </a-input>
              </a-col>
            </a-row>
          </div>
          <!-- 付款账户户名 -->
          <div class="form-box-item" v-if="showDetail">
            <a-row>
              <a-col :span="4">
                <span>付款账户户名:</span>
              </a-col>
              <a-col :span="20">
                <a-input disabled placeholder="请填写付款账户户名" v-model="paramsData.accountNameSettling">
                </a-input>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 收款对象 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>收款人</span>
            </a-col>
            <a-col :span="20">
              <a-select placeholder="请选择收款人" v-model="paymentValue" @change="paymentChange($event)">
                <a-select-option v-for="item in paymentList" :key="item.id" :value="item.id">
                  <WalletInfo :account-name="item.oppAccountName" :account-no="item.oppAccountNo"
                              :bank-name="item.oppBankName" :bank-no="item.oppBankNo"
                              :show-account-no="false" :show-account-name="true"
                              :is-wallet="item.oppAccountType==='1'"
                              :show-balance="false"
                  ></WalletInfo>
                  <p v-if="item.cgShopName">CG绑定店铺：{{ item.cgShopName }}</p>
                  <a-tag color="red" v-if="item.checkStatus === '0'">待审批</a-tag>
                </a-select-option>
              </a-select>
              <div class="payment-box" v-if="showDetail">
                <a-descriptions :column="1" size="small" bordered>
                  <a-descriptions-item label="收款户名">{{ paramsData.oppAccountName }}</a-descriptions-item>
                  <a-descriptions-item label="收款账号">{{ paramsData.oppAccountNo }}</a-descriptions-item>
                  <a-descriptions-item label="开户银行">{{ paramsData.oppBankName }}</a-descriptions-item>
                  <a-descriptions-item label="开户行号">{{ paramsData.oppBankNo }}</a-descriptions-item>
                </a-descriptions>
              </div>
            </a-col>
          </a-row>
        </div>

        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>支付金额</span>
            </a-col>
            <a-col :span="20">
              <!--              <a-input v-model="paramsData.payAmount" @change="amountChange" @blur="amountBlur"-->
              <!--                       placeholder="请输入支付金额" prefix="￥" suffix="元"-->
              <!--                       style="width: 300px;"-->
              <!--              ></a-input>-->
              <input-balance v-model="paramsData.payAmount"
                             :max="maxPayAmount"
                             @blur="payAmountChange"
                             style="width: 300px;"
                             align-left></input-balance>
            </a-col>
          </a-row>
        </div>
        <div class="form-box-item" v-if="showDetail">
          <a-row >
            <a-col :span="4">
              <span>交易备注（可选）</span>
            </a-col>
            <a-col :span="20">
              <a-textarea v-model="extraRemark" :rows="1" size="small" placeholder="请输入，如“货款”"></a-textarea>
            </a-col>
          </a-row>
        </div>

        <div v-if="paramsData.payFee && paramsData.payFee > 0" class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>预估手续费</span>
            </a-col>
            <a-col :span="20">
              {{ paramsData.payFee }}
            </a-col>
          </a-row>
        </div>
        <!-- 验证码 -->
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">
              <span>验证码</span>
            </a-col>
            <a-col :span="20">
              <payment-sms style="width:300px"
                           :verify-code.sync="paramsData.verifyCode"
                           :account-name="paramsData.accountName"
                           :account-no="paramsData.accountNo"
                           :pay-amount="paramsData.payAmount"
                           :model-no="smsModel"
                           :mobile="mobile"
                           :channel-no="paramsData.channelNo"
                           :disabled="!paramsData.oppAccountNo || Number(paramsData.payAmount)<=0"
              ></payment-sms>
            </a-col>
          </a-row>
        </div>
        <div class="form-box-item">
          <a-row>
            <a-col :span="4">

            </a-col>
            <a-col :span="20">
              <div
                  style="display: flex; flex-direction: column;align-items: start; justify-content: space-between; min-height: 100px;">
                <a-button type="primary"
                          :disabled="!paramsData.payAmount || !paramsData.oppAccountNo || !paramsData.verifyCode"
                          @click="onSubmitPay" icon="property-safety" style="width: 300px;">确认支付
                </a-button>
                <a-space></a-space>
                <a-button type="link" @click="$router.go(-1)" icon="clock-circle" style="width: 300px;">历史交易
                </a-button>
              </div>
            </a-col>
          </a-row>
        </div>

      </div>
      <!-- 确认支付弹框 -->
      <a-modal :visible="isShowConfirmDialog" :centered="true" @ok="onConfirmDialog"
               @cancel="cancelConfirmDialog">
        <p>
          <a-descriptions title="支付信息确认" bordered :column="2">
            <a-descriptions-item label="金额">
              <Balance :amount="paramsData.payAmount" prefix="" suffix="元"></Balance>
            </a-descriptions-item>
            <a-descriptions-item label="方式">
              <a-tag v-if="paramsData.payWay==='1'" color="blue">余额支付</a-tag>
              <a-tag v-else-if="paramsData.payWay==='2'" color="purple">融资支付</a-tag>
              <a-tag v-else-if="paramsData.payWay==='4'" color="cyan">清分支付</a-tag>
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="收款人信息" bordered :column="1">
            <a-descriptions-item label="户名">
              {{ paramsData.oppAccountName }}
            </a-descriptions-item>
            <a-descriptions-item label="账号">
              {{ paramsData.oppAccountNo }}
            </a-descriptions-item>
            <a-descriptions-item label="开户行">
              {{ paramsData.oppBankName }}
            </a-descriptions-item>
            <a-descriptions-item label="交易备注" v-if="extraRemark">
              {{ paramsData.extraRemark }}
            </a-descriptions-item>
          </a-descriptions>
        </p>
      </a-modal>
    </a-card>
  </div>
</template>
<script>
import MchChooseDialog from "@/view/components/ChooseDialog/MchChooseDialog.vue";
import Balance from '@/view/components/Balance.vue'
// import WalletInfo from '@/view/components/WalletInfo.vue'
import CountdownBtn from "@/view/components/CountdownBtn.vue";
import dayjs from "dayjs";
import WalletInfo from "@/view/components/WalletInfo.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import PaymentSms from "@/view/components/PaymentSms.vue";

const paramsData = {
  // 所属机构
  departmentId: "",
  // 渠道编号
  channelNo: "",
  // 应用编号
  appNo: "",
  //	电商名称
  mchName: "",
  //	电商编号
  mchNo: "",
  // 	支付方式
  payWay: "",
  // 订单类型
  orderType: "1",
  //支付协议选择
  agreement: "",
  // 	付款账号/电商A1账号
  accountNo: "",
  // 付款户名/电商户名
  accountName: "",
  // 电商A2账号
  accountNoA2: "",
  // 贷款申请账号
  accountNoSq: "",
  // 贷款申请户名
  accountNameSq: "",
  // 	收款账户类型
  oppAccountType: "",
  // 	收款账号
  oppAccountNo: "",
  // 	收款户名
  oppAccountName: "",
  // 	收款行号
  oppBankNo: "",
  // 收款行名
  oppBankName: "",
  // 	验证码
  verifyCode: "",
  //支付金额
  payAmount: "",
  // 摘要
  remark: "",
  accountNoSettling: '',//待清算账号
  accountNameSettling: '',//待清算户名
};
export default {
  name: "AddPaymentOrders",
  components: {
    PaymentSms,
    InputBalance,
    WalletInfo,
    MchChooseDialog,
    Balance,
    CountdownBtn
  },
  data() {
    return {
      isShowWaring: false,
      orderTypeDict: [
        {label: "支付MCN", value: "1"},
        {label: "支付主播", value: "2"},
        {label: "支付厂家", value: "3"},
        {label: "其他", value: "9"},
      ],
      // 页面参数
      paramsData,
      extraRemark: undefined,
      treeData: [],
      // 是否展示详细信息
      showDetail: false,
      // 表示调电商信息page
      selectType: "1",
      // 是否显示弹框
      isShowTableDialog: false,
      // 可用余额
      avibaleBalanceA1: 0,
      avibaleBalanceA2: 0,
      //贷款总额度
      totalLoan: "",
      // 可贷额度
      currentLoan: 0,
      // 累计已用额度
      accumulatedUsed: "",
      // 待清算金额
      toSettle: 0,
      // 客户编号
      custnum: "",
      // 收款对象数组
      paymentList: [],
      paymentValue: undefined,
      count: 0,
      codeVisible: true,
      timer: null,
      currentCode: "",
      isShowLoanPay: true,
      isShowClearPay: false,
      isShowConfirmDialog: false,
      confirmSuccess: false,
      mobile_mch: "",
      mobile_sq: "",
      payerWalletList: [],
      clearAccount: [],
      clearAccountNum: '',
      royalty: 0,//选中电商的代销提成
      isSelectedControlMch: false
    };
  },

  watch: {},
  computed: {
    mobile() {
      if (this.paramsData.payWay === "2") {
        return this.mobile_sq
      } else {
        return this.mobile_mch
      }
    },
    smsModel() {
      if (this.paramsData.payWay === "2") {
        // 融资支付，换成申请人的手机号， 单独验证码
        return "102105";
      } else if (this.paramsData.payWay === '4') {
        // 清分支付
        return "102112";
      } else if (this.paramsData.oppAccountType === "2") {
        // 对外支付
        // data.modelNo = "102209";
        return "102216";
      } else {
        return "102208";
      }
    },
    waringText() {
      return `当前订单仍有${this.currentLoan}元未融资，全部融资后方可追加`
    },
    maxPayAmount() {
      if (this.paramsData.payWay === '1') { //余额支付
        return this.avibaleBalanceA2
      } else if (this.paramsData.payWay === '2') { //融资支付
        return this.currentLoan
      } else if (this.paramsData.payWay === '4') {// 清分支付
        return undefined
      }
      return undefined
    }
  },
  mounted() {
    this.paramsData.departmentId = this.$session.getItem("companyId");
    //  代表是从融资电商页面跳转过来的，或者是重新加载的
    //if (this.paramsData.mchNo) {
    this.getEcList()
    //}
  },
  methods: {
    async getEcList() {
      let data = {
        pageNo: 1,
        pageSize: 10,
      }
      data.obj = {
        mchNo: this.$route.query.mchNo || this.paramsData.mchNo
      }
      let res = await this.api.getEcListApi(data)
      if (res.code === 200) {
        await this.onMchChosen(res.data.records[0])
      } else {
        this.$message.error(res.info)
      }
    },
    async onMchChosen(mch) {
      //  如果选中的是可控电商，隐藏融资支付按钮，默认选中余额支付
      this.isSelectedControlMch = mch.mchType === '0' ? true : false
      //代销提成
      this.royalty = mch.royalty / 100
      this.clearAccountNum = mch.accountNoA2
      // 电商名称
      this.paramsData.mchName = mch.mchName;
      // 电商编号
      this.paramsData.mchNo = mch.mchNo;
      //付款户名/电商户名
      this.paramsData.accountName = mch.accountName;
      // 	付款账号/电商A1账号
      this.paramsData.accountNo = mch.accountNoA1;
      // this.paramsData.accountNo = mch.accountNoA2;
      // 电商A2账号
      this.paramsData.accountNoA2 = mch.accountNoA2;
      this.paramsData.channelNo = mch.channelNo;
      this.paramsData.appNo = mch.appNo;
      // 客户编号
      this.custnum = mch.custnum;
      let accountA1Data = {
        accountClass: '0',
        accountNo: mch.accountNoA1
      }
      let accountA2Data = {
        accountClass: '6',
        accountNo: mch.accountNoA2
      }
      this.payerWalletList[0] = accountA1Data
      this.payerWalletList[1] = accountA2Data
      // 暂时判断选中电商是否为待清账户
      if (mch.goodsType == '40' || mch.goodsType == '41') {
        // 显示清分支付
        this.isShowClearPay = true
        // 获取清分支付的账号
        this.detailMchContract()
      } else {
        this.isShowClearPay = false
      }
      // 判断当前电商是否有融资
      await this.getLoanAccountList();
      // 查询当前余额
      await this.getAvibaleBalance();
      // 查询协议
      await this.getPaymentList();
    },
    changeAccount(event) {
      // 获取余额
      this.getAvibaleBalance()
    },
    // 支付方式发生改变
    changePayMode() {
      if (this.paramsData.payWay == '4') {
        //清分支付
        this.detailMchContract()
      } else if (this.paramsData.payWay == '1') {
        // 获取余额
        this.getAvibaleBalance()
      }
    },
    // 获取清分账户详情
    async detailMchContract() {
      let res = await this.api.getMchContractApi({
        accountNo: this.clearAccountNum,
        pageNo: 1,
        pageSize: 100
      })
      let data = res.data.records[0]
      let clearAccount1 = {
        grade: '1',
        accountNo: data?.accountNoSettling1,
        accountName: data?.accountNameSettling1,
        balance: data?.settling1Balance || 0
      }
      let clearAccount2 = {
        grade: '2',
        accountNo: data?.accountNoSettling2,
        accountName: data?.accountNameSettling2,
        balance: data?.settling2Balance || 0
      }
      this.clearAccount[0] = clearAccount1
      this.clearAccount[1] = clearAccount2
      // 默认一级待清
      this.paramsData.accountNoSettling = this.clearAccount[0].accountNo
      this.changeClearAccount()
    },
    // 待清button改变事件
    changeClearAccount() {
      this.clearAccount.forEach(item => {
        if (item.accountNo == this.paramsData.accountNoSettling) {
          //可用余额
          this.avibaleBalanceA2 = item.balance
          if (item.grade === '1') {
            // 如果选中的是一级待清，计算出支付默认金额 = 可用余额*代销提成
            this.paramsData.payAmount = (this.avibaleBalanceA2 * this.royalty).toFixed(2)
          }
          this.paramsData.accountNameSettling = item.accountName
        }
      })

    },
    // 筛选贷款通过的企业
    async getLoanAccountList() {
      let res = await this.api.creaditApplyInfoByCust(this.custnum);
      if (res.code === 200) {
        if (res.data?.length > 0) {
          //  如果选中的是可控电商，隐藏融资支付按钮，默认选中余额支付
          if (this.isSelectedControlMch) {
            this.paramsData.payWay = "1";
            this.isShowLoanPay = false;
          } else {
            this.paramsData.payWay = "2"; // 能用融资支付就默认用融资支付
            this.isShowLoanPay = true;
          }
          let data = res.data[0];
          this.paramsData.accountNameSq = data.accountName;
          this.paramsData.accountNoSq = data.accountNo;
          this.totalLoan = data.businessSum;
          let resLoanAvailable = await this.api.getFinacingLimitApi(
              this.paramsData.mchNo
          );

          if (resLoanAvailable.code !== 200) {
            return this.$message.error("查询可用额度失败，请稍后再试");
          }
          this.currentLoan = resLoanAvailable.data.availLimit;
          // 支付金额按可融资金额取整
          this.paramsData.payAmount = resLoanAvailable.data.availLimit.toFixed(2);
          this.accumulatedUsed = resLoanAvailable.data.loanAmountSum;
          this.toSettle = resLoanAvailable.data.settling1Balance;
          // this.totalLoan = resLoanAvailable.data.creditLimit;
          // 查询贷款余额, 申请人不一定是电商自身， 所以要再查一次
          let resBalance = await this.api.avibaleBalanceApi({
            channel_no: this.paramsData.channelNo,
            account_no: data.accountNo,
            relation_acct: 1,
          });
          let acctBalanceA3 = resBalance.data?.detail_list?.find(
              (acct) => acct.account_class === "7"
          ); // 筛选贷款账号
          this.accumulatedUsed = acctBalanceA3?.cur_balance || 0;
          this.mobile_sq = resBalance.data?.mobile || "";
          // this.accumulatedUsed = data.userBalance;
        } else {
          this.isShowLoanPay = false;
          this.paramsData.payWay = "1"; //无贷款，选中余额支付
        }
      }
    },
    // 获取可使用余额
    async getAvibaleBalance() {
      let data = {};
      data.channel_no = this.paramsData.channelNo;
      data.account_no = this.paramsData.accountNo;
      data.relation_acct = 1;
      let res = await this.api.avibaleBalanceApi(data);
      if (res.code == 200) {
        this.mobile_mch = res.data.mobile;
        let acctListA2 = res.data.detail_list.find(
            (acct) => acct.account_no === this.paramsData.accountNo
        );
        this.avibaleBalanceA2 = acctListA2?.cur_balance || 0;
      } else {
        return this.$message.error(res.info);
      }
    },
    // 试算更新
    async genOrder() {
      if (this.isShowClearPay && Number(this.currentLoan) > 0) {
        this.isShowWaring = true
        return
      }
      let today = dayjs().format("YYYY-MM-DD")
      const res = await this.api.collectOrdersGenerateApi({
        orderDate: today,
        mchNo: this.paramsData.mchNo,
        mchName: this.paramsData.mchName,
      })
      if (res.code !== 200) {
        this.$message.error(res.info)
      } else {
        this.$message.success('订单已追加')
        await this.getLoanAccountList();
      }
    },
    // 获取支付协议信息
    async getPaymentList() {
      let data = {};
      data.pageNo = 1;
      data.pageSize = 10000;
      data.obj = {}
      if (this.paramsData.mchNo) {
        data.obj.mchNo = this.paramsData.mchNo;
      } else {
        return this.$message.error("请首先填写商户编号");
      }
      // 协议
      let res = await this.api.getPayProtocolListApi(data);
      if (res.code === 200) {
        this.paymentList = res.data.records;
        // 默认选中第一个已审批的收款人
        let defaultOppAcct = this.paymentList.find((item) => item.checkStatus === '1')
        this.paymentValue = defaultOppAcct?.id
        this.paymentChange(this.paymentValue)
      }
    },
    //  选中付款对象的回调
    paymentChange(event) {
      let value = event;
      let paymentData = this.paymentList.reduce((acc, item) => {
        if (item.id == value) {
          acc = JSON.parse(JSON.stringify(item));
        }
        return acc;
      }, {});
      // 回显收款对象信息
      this.paramsData.oppAccountType = paymentData.oppAccountType;
      this.paramsData.oppAccountNo = paymentData.oppAccountNo;
      this.paramsData.oppAccountName = paymentData.oppAccountName;
      this.paramsData.oppBankNo = paymentData.oppBankNo;
      this.paramsData.oppBankName = paymentData.oppBankName;
      this.paramsData.orderType = paymentData.orderType;
      //支付方式为融资支付，并且选中收款对象为未审批时，给出提示
      if (this.paramsData.payWay === '2' && paymentData.checkStatus === '0') {
        this.$warning({
          title: "提示",
          content: '收款人未审批，请联系核心企业！',
          centered: true

        })
      }

    },
    async payAmountChange() {
      if (this.paramsData.payWay === "1") {
        //计算服务费
        const res = await this.api.getMchPayCalFeeApi({
          mchNo: this.paramsData.mchNo,
          amount: this.paramsData.payAmount
        })
        if (res.code !== 200) {
          this.paramsData.payFee = 0
        } else {
          this.paramsData.payFee = res.data.toFixed(2)
        }
      }
    },
    //余额支付/融资支付/协议支付
    async confirmPay() {
      // 自动添加备注
      this.paramsData.remark = this.paramsData.remark || (this.orderTypeDict.find((i) => i.value == this.paramsData.orderType)?.label)
      this.paramsData.remark = this.paramsData.remark ?? "其他合法款项"
      this.paramsData.remark = {"1": "余额支付", "2": "融资支付", "4": "清分支付"}[this.paramsData.payWay]
      if (this.extraRemark?.trim().length > 0) {
        this.paramsData.remark = this.paramsData.remark + "[" + this.extraRemark + "]"
      }
      this.paramsData.payAmount = Number(this.paramsData.payAmount);
      if (this.paramsData.payWay == '4') {
        // 清分支付
        this.paramsData.accountNo = this.clearAccountNum;
        // this.paramsData.accountNo = this.paramsData.accountNoSettling
        // this.paramsData.accountName = this.paramsData.accountNameSettling
      }
      let data = JSON.parse(JSON.stringify(this.paramsData));
      if (data.payWay === "1") {
        data.payWay = "3" // 余额支付统一换成协议支付
      }
      let res = await this.api.orderPayApi(data);
      if (res.code == 200) {
        this.$message.success(res.info);
        this.$router.push({name: "PaymentOrders"});
      } else {
        // return this.$message.error(res.info);
        return this.$error({
          title: '支付失败',
          content: res.info,
        });
      }
    },
    onSubmitPay() {
      if (!this.paramsData.mchNo) {
        return this.$message.error("请选择商户")
      }
      if (!this.paramsData.payAmount) {
        return this.$message.error("请输入支付金额")
      }
      if (this.paramsData.verifyCode === "") {
        return this.$message.error("请填写验证码");
      }
      this.isShowConfirmDialog = true
    },
    // 弹框确定按钮
    onConfirmDialog() {
      // 暂时验证成功
      this.confirmSuccess = true;
      if (this.confirmSuccess) {
        // 调用支付接口
        this.confirmPay();
      }
      // 关闭弹框
      this.isShowConfirmDialog = false;
      this.confirmSuccess = false;
    },
    // 弹框取消按钮
    cancelConfirmDialog() {
      this.isShowConfirmDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  justify-content: space-between;
}

.form-box {
  padding: 50px;

  .form-box-item {
    margin-bottom: 20px;

    .up,
    .down {
      cursor: pointer;
    }

    .up {
      transform: rotate(-180deg);
    }

    .ant-select {
      min-width: 300px;
    }

    .ant-input {
      max-width: 300px;
    }

    .ant-input-number {
      width: 300px;
    }


    .link_area {
      display: inline-block;
      width: 300px;
    }

    .ant-input-group-wrapper {
      width: 300px;
    }

    .clear-item {
      border: 1px solid #e5e5e5;
      padding: 5px;
      border-radius: 5px;
    }

    .clear-item:first-child {
      border: 1px solid #1890ff;

    }

    .payment-box {
      width: 450px;
      //padding: 10px;
      margin-top: 10px;
      //border-radius: 5px;
      //box-sizing: border-box;

    }
  }

  .form-box-last-item {
    margin-top: 10px;
    padding-left: 300px;
  }
}

.large-icon {
  /deep/ svg {
    font-size: 18px;
  }
}
</style>