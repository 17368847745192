<template>
  <div :class='className'>
    <span>{{ prefix }}</span>
    <span class="balance">{{ formatted }}</span>
    <span>{{ suffix }}</span>
  </div>
</template>
<script>
export default {
  name: "Balance",
  props: {
    prefix: {
      // 前缀
      type: String,
      default: "￥"
    },
    suffix: {
      // 后缀
      type: String,
      // default:"元"
    },
    amount: {
      // 金额
      type: Number,
      default: 0,
    },
    alignRight: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    // 借贷标记
    dcflag: String,
    size: {
      type: String,
      default: "normal",
    },
  },
  computed: {
    formatted() {
      let amountStr = Number(this.amount).toFixed(2);
      return amountStr.replace().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    className() {
      let align = this.alignRight ? "right " : "left "
      let inline = this.inline ? "inline " : " "

      if (this.dcflag === 'c' || this.amount < 0) {
        return this.size + " credit " + align + inline
      } else if (this.dcflag === 'd') {
        return this.size + " debit " + align + inline
      }else{
        return this.size + " normal " + align + inline
      }
    }
  },
  watch: {},
  created() {
    // console.log(this.amount, 'amount');
  }
};
</script>

<style scoped lang="less">
div {
  max-width: 100%;
  min-width: 100px;
  padding-right: 8px;
}

div.left {
  text-align: left;
}

div.right {
  text-align: right;
}
div.small span{
  font-size: 12px;
}
div.middle span{
  font-size: 24px;
}
div.large span{
  font-size: 36px;
}
div.huge span{
  font-size: 48px;
}
.balance {
  color: #002e58;
}

.credit .balance {
  color: red;
}

.debit .balance {
  color: green
}

.inline {
  display: inline-block;
}
</style>
