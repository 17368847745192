<script>
import TransRecord from "@/view/nbi/trans/record.vue";

export default {
  name: "advance",
  components: {TransRecord},
  data() {
    return {
      lenderId: undefined,
      borrowerId: undefined,
    }
  },
  methods: {
    toSettle() {
      // if (!this.borrowerId) {
      //   return this.$message.warn('请选择融资人')
      // }
      this.$router.push({
        name: 'reclaimSettle', params: {
          lenderId: this.lenderId,
          borrowerId: this.borrowerId,
        }
      },)
    }
  }
}
</script>

<template>
  <trans-record trans-type="nbi_reclaim"
                lender-picker :lender-id.sync="lenderId"
                borrower-picker :borrower-id.sync="borrowerId">
    <template v-slot:action-box>
      <a-button type="primary" @click="toSettle" icon="fork">去清算</a-button>
    </template>
  </trans-record>
</template>

<style scoped lang="less">

</style>