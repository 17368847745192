export default {
  PREFIX: "_L_",
  IS_REMEMBER_PER_PHONE_NUM: "IS_REMEMBER_PER_PHONE_NUM",
  IS_REMEMBER_ENT_PHONE_NUM: "IS_REMEMBER_ENT_PHONE_NUM",
  REMEMBERED_PER_PHONE_NUM: "REMEMBERED_PER_PHONE_NUM",
  REMEMBERED_ENT_PHONE_NUM: "REMEMBERED_ENT_PHONE_NUM",
  getBoolOrDefault(key, value) {
    const v = localStorage.getItem(this.PREFIX + key);
    if (v === undefined || v === null) {
      return !!value
    } else {
      return v.trim().toLowerCase() === "true"
    }
  },
  getStringOrDefault(key, value) {
    const v = localStorage.getItem(this.PREFIX + key);
    if (v === undefined || v === null) {
      localStorage.setItem(this.PREFIX + key, value);
      // console.info(key + "not hit:", value)
      return value
    } else {
      // console.info(key + "hit:", v)
      return v
    }
  },
  set(key, value) {
    if(!value){
      this.del(key)
    }else{
      localStorage.setItem(this.PREFIX + key, value);
    }
  },
  del(key) {
    localStorage.removeItem(this.PREFIX + key)
  }
}