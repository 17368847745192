<script>

import {defineComponent} from "vue";
import WalletInfo from "@/view/components/WalletInfo.vue";
import WalletChooseDialog from "@/view/components/ChooseDialog/WalletChooseDialog.vue";
import SettleAccountSelect from "@/view/Wallet/SettleAccounts/settle-account-select.vue";
import Balance from "@/view/components/Balance.vue";

export default defineComponent({
  components: {SettleAccountSelect, WalletChooseDialog, WalletInfo, Balance},
  emits: ['detailModified'],
  props: {
    borrower: {
      type: Object,
      required: true
    }
  },
  data() {
    const columns = [
      {title: '回款账户', dataIndex: 'accountName', key: 'accountName', align: "center"},
      {
        title: '回款账号', dataIndex: 'accountNo', align: 'center', customRender: (text, record) =>
            <WalletInfo accountName={record.accountName} accountNo={record.accountNo}
                        showAccountNo showBalance={false}/>
      },
      {title: '清分协议', dataIndex: 'settleContractNo', align: 'center'},
      {
        title: '待清分金额',
        dataIndex: 'settling1Balance',
        key: 'settling1Balance',
        align: "center",
        customRender: (text, record) => {
          if (record.settleContractNo) {
            return <span> <Balance amount={text} inline></Balance>
                      <a-button type="link" icon="reload" onClick={() => this.getClearBalanceSync(record)}></a-button>
                </span>
          } else {
            return ""
          }
        },
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 250,
        align: 'center',
        scopedSlots: {customRender: 'action'}
      }
    ]
    const editRules = {}
    const editForm = {
      "id": undefined,
      "accountNo": undefined,
      "accountName": undefined,
      "receiverName": undefined,
      "borrowerId": undefined,
      "borrowerName": undefined,
      "lenderId": undefined,
      "lenderName": undefined,
    }
    const auditForm = {
      "repayAccountId": undefined,
      "contractNo": undefined,
      "fileNo": undefined,
      "contractType": undefined,
      "accountNo": undefined,
      "accountName": undefined,
      "accountNoSettling1": undefined,
      "accountNameSettling1": undefined,
      "accountNoSettling2": undefined,
      "accountNameSettling2": undefined,
      "note": undefined,
    }
    const auditRules = {
      contractNo: [
        {required: true, message: '清分协议编号不能为空', trigger: 'blur'},
      ],
      accountNoSettling1: [
        {required: true, message: '请选择待清算账号1', trigger: 'blur'},
        {pattern: /^\d*$/, message: "请检查格式", trigger: "blur",},
      ],
      accountNoSettling2: [
        {required: true, message: '请选择待清算账号2', trigger: 'blur'},
        {pattern: /^\d*$/, message: "请检查格式", trigger: "blur",},
      ],
    }
    return {
      searchForm: {},
      editDialogVisible: false,
      editCGVisible: false,
      columns,
      editForm,
      editRules,
      auditForm,
      auditRules,
      clearAccount: {},
      auditDialogVisible: false,
    }
  },
  methods: {
    onDetailModified() {
      this.$emit('detailModified')
    },
    async getClearBalanceSync(record) {
      let res = await this.api.clearBalanceSyncApi({
        id: record.settlingContractid
      })
      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
    },
    onSettleAccount1Change(settleAccount) {
      this.auditForm.accountNameSettling1 = settleAccount.accountNameSettling1;
      this.auditForm.accountNoSettling1 = settleAccount.accountNoSettling1;
    },
    onSettleAccount2Change(settleAccount) {
      this.auditForm.accountNameSettling2 = settleAccount.accountNameSettling1;
      this.auditForm.accountNoSettling2 = settleAccount.accountNoSettling1;
    },

    async onAudit(record) {
      for (let key in this.auditForm) {
        this.auditForm[key] = undefined
      }
      this.auditForm.repayAccountId = record.id
      this.auditForm.accountNo = record.accountNo
      this.auditForm.accountName = record.accountName
      this.auditForm.contractType = '1' //协议类型 1、代销协议

      this.auditDialogVisible = true
    },

    async onAuditSave() {
      this.$refs.auditForm.validate(async valid => {
        if (valid) {
          this.$confirm({
            title: '请确认清分协议',
            content: '一个回款账号只能添加一个请分协议，添加后无法删除，是否确认！！',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk: async () => {
              const params = {...this.auditForm}
              params.contractNo = `AGENT-${params.contractNo}-KK`
              let res = await this.api.repayAccountAddContractApi(params)
              if (res.code !== 200) {
                return this.$message.error(res.info)
              }
              this.auditDialogVisible = false
              this.onDetailModified()
            },
            onCancel() {
            },
          });
        }
      })
    },
    onAuditCancel() {
      this.auditDialogVisible = false
    },

    async repayAccountDel(record) {
      this.$confirm({
        title: '是否确认删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: async () => {
          let res = await this.api.repayAccountDeleteApi(record.id)
          if (res.code !== 200) {
            return this.$message.error(res.info)
          }
          this.$message.success("删除成功")
          this.onDetailModified()
        },
        onCancel() {
        },
      });
    },
    repayAccountWalletChosen: async function (wallet) {
      this.editForm.accountNo = wallet.accountNo
      this.editForm.accountName = wallet.accountName
      this.editForm.receiverName = wallet.accountName
      this.editForm.accountClass = wallet.accountClass
    },
    async onSave() {
      let res = await this.api.repayAccountAddOrEditApi(this.editForm)

      if (res.code !== 200) {
        return this.$message.error(res.info)
      }
      this.onDetailModified()
      this.editDialogVisible = false
    },
    onCancel() {
      this.editDialogVisible = false
    },
    async onAddOrEdit(record) {
      if (record) {// edit
        // TODO: not implemented
        return
      }
      for (let key in this.editForm) {
        this.editForm[key] = undefined
      }
      this.editForm.borrowerId = this.borrower.id
      this.editForm.borrowerName = this.borrower.borrowerName
      this.editForm.lenderId = this.borrower.lenderId
      this.editForm.lenderName = this.borrower.lenderName
      this.editDialogVisible = true
    },
  }
})
</script>

<template>
  <div>
    <p>
      <a-button @click="onAddOrEdit(undefined)" type="primary">新增</a-button>
    </p>
    <a-table :columns="columns" :data-source="borrower.repayAccountList" :row-key="(record) => record.id" size="small">
      <template slot="action" slot-scope="text, record">
        <a-button type="link" v-if="!record.settleContractNo" @click=onAudit(record) icon="cloud-upload">协议开通
        </a-button>
        <a-button type="link" size="small" @click="repayAccountDel(record)">
          <a-icon type="delete" theme="twoTone" two-tone-color="red"/>
        </a-button>
      </template>
    </a-table>
    <a-modal
        title="回款账户新增"
        :visible="editDialogVisible"
        @ok="onSave"
        @cancel="onCancel"
        width="600px"
        :body-style="{ height: 'auto', overflow: 'auto' }"
    >
      <a-form-model :model="editForm" ref="editForm" :rules="editRules" :label-col="{span:6}" :wrapper-col="{span:10}">
        <a-form-model-item ref="account_name" class="account_name" label="回款账户" prop="account_name">
          <WalletChooseDialog v-model="editForm.accountName" :searchable="true" account-class="6"
                              @chooseDone="repayAccountWalletChosen"/>
          <WalletInfo v-if="editForm.accountNo"
                      :account-class="editForm.accountClass"
                      :account-no="editForm.accountNo"
                      :account-name="editForm.accountName"/>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea placeholder="请输入备注" :rows="2" v-model="editForm.note"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
        title="清分协议开通"
        :visible="auditDialogVisible"
        @ok="onAuditSave"
        @cancel="onAuditCancel"
        width="700px"
        :body-style="{ height: 'auto', overflow: 'auto' }"
    >
      <a-form-model :model="auditForm" ref="auditForm" :rules="auditRules" :label-col="{span:5}"
                    :wrapper-col="{span:16}">
        <a-form-model-item label="回款账户">
          <WalletInfo :account-no="auditForm.accountNo" :account-name="auditForm.accountName"
                      :show-balance="false"></WalletInfo>
        </a-form-model-item>
        <a-form-model-item label="协议编号" prop="contractNo">
          <a-input v-model="auditForm.contractNo" addon-before="AGENT-" addon-after="-KK"/>
        </a-form-model-item>
        <a-form-model-item label="待清户1">
          <settle-account-select @change="onSettleAccount1Change"></settle-account-select>
        </a-form-model-item>
        <a-form-model-item label="待清户2">
          <settle-account-select @change="onSettleAccount2Change"></settle-account-select>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="auditForm.note" placeholder="请输入备注" :rows="2"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<style scoped lang="less">

</style>