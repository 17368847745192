<script>
import PaymentSms from "@/view/components/PaymentSms.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import Balance from "@/view/components/Balance.vue";

export default {
  name: "GuaranteeWithdrawBtn",
  components: {Balance, InputBalance, PaymentSms},
  props: {
    channelNo: {type: String, required: true},
    appNo: {type: String, required: true},
    accountNoA6: {type: String, required: true},
    accountNoA1: {type: String, required: true}
  },
  emits: ['cancel', 'ok'],
  data() {
    return {
      visible: false,
      confirmVisible: false,
      accountDetail: undefined,
      form: {
        channel_no: undefined,
        app_no: undefined,
        account_no: undefined,
        account_name: undefined,
        trade_amount: undefined,
        verify_code: undefined,
        remark: '',
      }
    }
  },

  methods: {
    async fetchAccountInfo() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.channelNo,
        account_no: this.accountNoA6,
      })
      if (res.code === 200) {
        this.accountDetail = res.data
        this.form.account_name = this.accountDetail.account_name
        this.form.account_no = this.accountNoA1
        this.form.channel_no = this.channelNo
        this.form.app_no = this.appNo
      }
    },
    async toWithdraw() {
      this.accountDetail = undefined
      await this.fetchAccountInfo()
      this.visible = true
    },
    toConfirm() {
      this.confirmVisible = true
    },
    cancel() {
      this.visible = false
      this.$emit('cancel')
    },
    async withdraw() {
      const res = await this.api.bailRepayApi(this.form)
      if (res.code !== 200) {
        this.$message.error(res.info)
        return
      }
      this.$message.success('释放成功')
      this.confirmVisible = false
      this.visible = false
      this.$emit('ok')
    }
  },
  watch: {},
  computed: {
    maxBalance() {
      if (this.accountDetail?.detail_list?.length > 0) {
        return this.accountDetail?.detail_list[0]?.cur_balance
      }
      return 0
    }
  }
}

</script>

<template>
    <span @click="toWithdraw" v-if="$authority('marginRelease')">
    <slot>
      <a-button class="collapse" type=link>释放</a-button>
    </slot>
    <a-modal v-model="visible" @cancel="cancel" title="保证金释放" v-if="accountDetail" :zIndex="100">
      <a-form :label-col="{span:7}" :wrapper-col="{span:12}">
        <a-form-item label="保证金账户"> {{ accountDetail.account_name }} </a-form-item>
        <a-form-item label="保证金账号">
            <a-tag color="yellow">保证A6</a-tag> {{ accountNoA6 }}
        </a-form-item>
        <a-form-item label="释放到钱包" v-if="accountNoA1">
          <a-tag color="green">活期A1</a-tag>{{ accountNoA1 }} </a-form-item>
        <a-form-item label="释放金额">
          <input-balance v-model="form.trade_amount" :max="maxBalance"></input-balance>
          <Balance prefix="保证金余额：￥" dcflag="d" :amount="maxBalance"/>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" @click="toConfirm" :disabled="!Number(form.trade_amount)>0">确定
        </a-button>
      </template>
    </a-modal>
    <a-modal v-model="confirmVisible" :maskClosable="false" :zIndex="200">
      <a-descriptions :column="1" title="请确认交易信息" bordered>
        <a-descriptions-item label="保证金账户">
          {{ form.account_name }}
        </a-descriptions-item>
        <a-descriptions-item label="活期账号">
          {{ form.account_no }}
        </a-descriptions-item>
        <a-descriptions-item label="释放金额">
          <Balance :amount="Number(form.trade_amount)" :align-right="false"></Balance>
        </a-descriptions-item>

      </a-descriptions>
      <a-form style="margin-top:20px" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-item label="短信验证">
      <payment-sms v-if="confirmVisible"
                   :verifyCode.sync="form.verify_code"
                   :channel-no="form.channel_no"
                   :app-no="form.app_no"
                   account-no=""
                   account-name=""
                   :pay-amount="form.trade_amount"
                   :mobile="accountDetail.mobile"
                   :disabled="!Number(form.trade_amount)>0"
                   model-no="102011"></payment-sms>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button @click="confirmVisible=false">取消</a-button>
        <a-button type="primary" @click="withdraw" :disabled="form.verify_code?.length!==6">释放
        </a-button>
      </template>
    </a-modal>
  </span>
</template>

<style scoped lang="less">

/deep/ .ant-select-selection-selected-value {
  width: 100%;
}

/deep/ .ant-form-item-control {
  text-align: end;
}
.collapse.ant-btn{
  margin-right: 0;
  padding: 0 10px;
}
</style>