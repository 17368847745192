<template>
  <div style="margin: 0 20px;">
    <a-card title="垫资支付">
      <div slot="extra">
        <a-button @click="historicalTransactions">取消支付</a-button>
      </div>
      <a-form-model :model="paramsData" :label-col="labelCol" :wrapper-col="wrapperCol"
                    style="width: 660px; padding: 0 80px;">
        <a-form-model-item label="出资人">
          <lender-select :value.sync="lenderId"></lender-select>
        </a-form-model-item>
        <!-- 融资人选择 -->
        <a-form-model-item label="融资人">
          <borrower-select
              :value.sync="paramsData.borrowerId" @change="onBorrowerChange" :lender-id="lenderId"
              :default-first="false"
              :showLender="false"></borrower-select>
        </a-form-model-item>

        <!-- 最终收款人选择 -->
        <a-form-model-item label="最终收款人">
          <a-select v-model="paramsData.receiverId" placeholder="请选择货盘单位" @change="onReceiverChange">
            <a-select-option v-for="item in borrowerDetail.receiverList" :key="item.id" :value="item.id"
                             :disabled="item.status !== 1">
              <span class="stretch">
               <a-tag color="blue">货</a-tag>
                {{ item.receiverName || item.accountName }}</span>
              <span class="float-right">
                <span class="second">{{ item.cgShopName }}</span>
              </span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 出资人显示 -->
        <a-form-model-item label="出资账号" v-if="paramsData.lenderAccountNo">
          <WalletInfo inline :accountName="paramsData.lenderAccountName" :accountNo="paramsData.lenderAccountNo"
                      :show-balance="false"/>

          <Balance class="float-right" v-if="lenderBalance!==undefined" inline align-right :amount="lenderBalance"
                   dcflag="d" suffix="元"
                   prefix="可用: "></Balance>
        </a-form-model-item>

        <!-- 融资金额输入 -->
        <a-form-model-item label="融资金额">
          <input-balance v-model="paramsData.payAmount" :max="lenderBalance"></input-balance>

        </a-form-model-item>
        <!-- 验证码输入 -->
        <a-form-model-item label="验证码">
          <paymentSms :verify-code.sync="paramsData.verifyCode"
                      :mobile="paramsData.lenderMobile" model-no="102216"
                      :channel-no="paramsData.channelNo"
                      :account-name="paramsData.lenderAccountName"
                      :account-no="paramsData.lenderAccountNo"
                      :pay-amount="paramsData.payAmount"
                      :disabled="!smsEnable"
          ></paymentSms>
          <a-alert v-if="invalidMsg!==undefined" :message="invalidMsg" banner/>

        </a-form-model-item>
        <a-row>
          <a-col span="5"></a-col>
          <a-col span="19">
            <div
                style="display: flex; flex-direction: column;align-items: start; justify-content: space-between; min-height: 100px;">

              <a-button type="primary" block
                        :disabled="!btnEnable"
                        @click="onSubmitPay" icon="property-safety">确认支付
              </a-button>
              <a-space></a-space>
              <a-button type="link" block @click="historicalTransactions" icon="history">历史交易</a-button>
            </div>
          </a-col>
        </a-row>

      </a-form-model>

      <!-- 确认支付弹框 -->
      <a-modal :width='700' :visible="isShowConfirmDialog" :centered="true" @ok="onConfirmDialog"
               @cancel="cancelConfirmDialog">
        <p>
          <a-descriptions title="支付信息确认" bordered :column="2" size="small">
            <a-descriptions-item label="出资人" :span="2">{{ paramsData.lenderName }}</a-descriptions-item>
            <a-descriptions-item label="融资人" :span="2">{{ paramsData.borrowerName }}</a-descriptions-item>
            <a-descriptions-item label="收款人">{{ paramsData.receiverAccountName }}</a-descriptions-item>
            <a-descriptions-item label="账号">{{ paramsData.receiverAccountNo }}</a-descriptions-item>
            <a-descriptions-item label="金额" :span="2">
              <Balance :amount="Number(paramsData.payAmount)" prefix="￥" suffix="" :align-right="false"/>
            </a-descriptions-item>
          </a-descriptions>
        </p>
      </a-modal>

    </a-card>
  </div>
</template>

<script>
import WalletInfo from "../../components/WalletInfo.vue";
import CountdownBtn from "../../components/CountdownBtn.vue";
import Balance from "../../components/Balance.vue";
import BorrowerSelect from "@/view/nbi/borrower/borrower-select.vue";
import InputBalance from "@/view/components/InputBalance.vue";
import PaymentSms from "@/view/components/PaymentSms.vue";
import {Modal} from "ant-design-vue";
import LenderSelect from "@/view/nbi/lender/lender-select.vue";

export default {
  name: "advance-add",
  components: {LenderSelect, PaymentSms, InputBalance, BorrowerSelect, Balance, CountdownBtn, WalletInfo},
  data() {
    const lenderId = Number(this.$route.params.lenderId) || undefined
    const borrowerId = Number(this.$route.params.borrowerId) || undefined
    const receiverId = Number(this.$route.params.receiverId) || undefined
    return {
      lenderId,
      borrowerId,
      receiverId,
      lenderBalance: undefined,
      paramsData: {
        channelNo: "",
        borrowerId: borrowerId,
        borrowerName: "",

        lenderName: "",
        lenderAccountNo: "",
        lenderAccountName: "",
        lenderMobile: "",

        receiverAccountName: "",
        receiverAccountNo: "",

        receiverId: "",
        receiverName: "",

        payAmount: undefined,
        verifyCode: undefined,
        payWay: "",

      },
      isShowConfirmDialog: false,
      labelCol: {span: 5},
      wrapperCol: {span: 19},
      borrowerList: [],
      borrowerDetail: {
        receiverList: [],
        repayAccountList: [],
      },
    };
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.lenderId = Number(this.$route.params.lenderId) || undefined
      this.borrowerId = Number(this.$route.params.borrowerId) || undefined
      this.receiverId = Number(this.$route.params.receiverId) || undefined
    },
    historicalTransactions() {
      this.$router.go(-1)
    },

    // 获取融资人详细信息
    async fetchBorrowerDetail() {
      const res = await this.api.borrowerDetailApi(this.paramsData.borrowerId);
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.borrowerDetail = {...res.data};
      this.paramsData.lenderMobile = res.data.lender.mobile;
      this.paramsData.lenderAccountNo = res.data.lender.accountNo;
      this.paramsData.lenderAccountName = res.data.lender.accountName;
    },
    async fetchLenderBalance() {
      const res = await this.api.accountqueryBalancedApi({
        channel_no: this.paramsData.channelNo,
        account_no: this.paramsData.lenderAccountNo,
      });
      if (res.code !== 200) {
        this.$message.error(res.info);
        return;
      }
      this.lenderBalance = res.data.detail_list[0].cur_balance;
    },

    // 处理融资人选择
    async onBorrowerChange(selectedBorrower) {
      if (selectedBorrower) {
        // this.paramsData.borrowerId = selectedBorrower.id
        this.paramsData.channelNo = selectedBorrower.channelNo
        this.paramsData.borrowerName = selectedBorrower.borrowerName
        this.paramsData.borrowerAccountNo = selectedBorrower.accountNo
        this.paramsData.borrowerAccountName = selectedBorrower.accountName
        this.paramsData.lenderName = selectedBorrower.lenderName
        this.paramsData.lenderId = selectedBorrower.lenderId
        await this.fetchBorrowerDetail()
        await this.fetchLenderBalance()
      }
    },

    // 处理收款人选择
    onReceiverChange(event) {
      const selectedReceiver = this.borrowerDetail.receiverList.find(item => item.id === event);
      if (selectedReceiver) {
        this.paramsData.receiverId = selectedReceiver.id
        this.paramsData.receiverAccountName = selectedReceiver.accountName
        this.paramsData.receiverAccountNo = selectedReceiver.accountNo
      }
    },

    // 提交支付
    async onSubmitPay() {
      if (!this.paramsData.payAmount) {
        return this.$message.error("请输入支付金额");
      }
      if (!this.paramsData.verifyCode) {
        return this.$message.error("请填写验证码");
      }
      this.isShowConfirmDialog = true;
    },

    // 弹框确认按钮
    async onConfirmDialog() {
      this.isShowConfirmDialog = false;
      const res = await this.api.transactionFinancingPayApi(this.paramsData);
      if (res.code !== 200) { // 接口报错
        Modal.error({
          title: "支付异常", content: res.info,
          okText: "确定",
          onOk: () => {
            // this.historicalTransactions()
          },
        })
      } else {
        if (res.data?.payStatus !== '1') {
          Modal.error({
            title: "支付失败", content: res.info,
            okText: "返回",
            onOk: () => {
              this.historicalTransactions()
            },
          })
        } else {
          Modal.success({
            title: "支付成功",
            onOk: () => {
              this.historicalTransactions()
            },
          })
        }
      }
    },

    // 弹框取消按钮
    cancelConfirmDialog() {
      this.isShowConfirmDialog = false;
    }
  },

  computed: {
    smsEnable() {
      return Number(this.paramsData.payAmount) > 0 &&
          Number(this.paramsData.payAmount) <= this.lenderBalance &&
          this.paramsData.receiverId && this.paramsData.borrowerId
    },
    invalidMsg() {
      if (!Number(this.paramsData.payAmount) > 0) {
        return "请输入融资金额"
      }
      if (!this.paramsData.receiverId) {
        return "请选择收款人"
      }
      return undefined
    },

    btnEnable() {
      return this.invalidMsg === undefined && this.paramsData.verifyCode?.length === 6
    },
  }
};
</script>
<style scoped lang="less">
/deep/ .ant-form-item {
  margin-bottom: 12px;
}

.float-right {
  float: right;
}


/deep/ .ant-select {
  width: 100%;
}

/deep/ .ant-select-selection-selected-value {
  width: 100% !important;
}

.second {
  font-size: 0.9em;
  color: #2c2522;
}
</style>