<script>
import BankChoose from "@/view/components/BankChoose.vue";

export default {
  name: "BankSearch",
  emits: ['update:bankNo', 'update:bankName', 'change'],
  components: {BankChoose},
  props: {
    bankNo: String,
    bankName: String,
    disabled: Boolean,
    simpleMode: Boolean
  },
  data() {
    return {
      value: this.bankNo,
      bankCodeList: [],
      bankSearchHandler: undefined,
      bankSearchIndicating: false,
      bankSearchInput: undefined,
      bankSearchMinLen: 2,
      simple: this.simpleMode,
    }
  },
  methods: {
    onBankChoose(obj) {
      console.info(obj.value, obj['option'].componentOptions.children[0].text)
      this.$emit('update:bankName', obj['option'].componentOptions.children[0].text)
      this.$emit('update:bankNo', obj.value)
      this.$emit('change', obj.value)
    },
    handleBankChange(bankNo) {
      const selectedBank = this.bankCodeList.find(bank => bank.bankNo === bankNo);
      if (selectedBank) {
        this.$emit('update:bankName', selectedBank.bankName)
        this.$emit('update:bankNo', selectedBank.bankNo)
        this.$emit('change', selectedBank.bankNo)
      }
    },
    handleBankSearch(input) {
      this.bankSearchInput = input
      this.bankCodeList = []
      if (this.bankSearchInput?.trim()?.length < this.bankSearchMinLen) return

      if (this.bankSearchHandler) {
        clearTimeout(this.bankSearchHandler);
        this.bankSearchHandler = null;
      }
      this.bankSearchHandler = setTimeout(async () => {
        this.bankSearchIndicating = true
        this.bankCodeList = []
        let bankNameSeg = this.bankSearchInput.trim().replace(/(.*?银行|.*?农村)(.*股份有限公司|.*有限责任公司|.*合作联社)?(.*)/,"+$1 $3")
        const res = await this.api.bankCodeQuery(encodeURIComponent(bankNameSeg));
        if (res.code !== 200) {
          // this.$error({title: "暂无对应行号"})
        } else {
          this.bankCodeList = res.data
        }
        this.bankSearchIndicating = false
      }, 800)
    },

  },
  computed: {
    bankSearchEmptyContent() {
      if (this.bankSearchIndicating) return '搜索中...'
      if (this.bankSearchInput?.trim()?.length >= this.bankSearchMinLen) return "若检索不到，可选择同城其他网点"
      return `请输入至少前${this.bankSearchMinLen}个汉字`
    }
  },
  watch:{
    bankNo(val, oldVal){
      this.value=val
    }
  }
}
</script>

<template>
  <div>
    <bank-choose v-if=simple :disabled="disabled" :name="bankName" @changeBank="onBankChoose"></bank-choose>
    <a-select v-else
        placeholder="请输入开户网点名称"
        show-search
        v-model="value"
        :filter-option="false"
        :show-arrow="bankSearchIndicating"
        :not-found-content="bankSearchEmptyContent"
        :dropdownMatchSelectWidth="false"
        @search="handleBankSearch"
        @change="handleBankChange"
        :loading="bankSearchIndicating"
        :disabled="disabled"
    >
      <a-select-option v-if="bankCodeList.length === 0 && bankNo && bankName && !bankSearchInput" :value="bankNo">
        <span>{{ bankName }}</span>
      </a-select-option>
      <a-select-option v-if="bankSearchInput" disabled value="">
        <span style="font-size:12px" v-if="bankSearchInput?.length<bankSearchMinLen">输入至少{{bankSearchMinLen}}个汉字</span>
        <span style="font-size:12px" v-else>若检索不到，可选同银行同城其他网点</span>
      </a-select-option>
      <a-select-option v-for="bank in bankCodeList" :key="bank.bankNo" :value="bank.bankNo">
        <span>{{ bank.bankName }}</span>
      </a-select-option>
    </a-select>
    <a-switch v-model="simple" checked-children="快速模式" un-checked-children="精准模式"></a-switch>
    <a-popover>
      <a-icon type="info-circle" theme="twoTone"/>
      <template #content>
        单笔转账100万以上的请使用详细模式
      </template>
    </a-popover>
  </div>
</template>

<style scoped lang="less">

</style>