ChannelInfo<script>
export default {
  name: "DeptInfo",
  components: {},
  props: {
    deptId: Number,
    deptCode: String,
  },
  data(){
    return {
      deptIdCodeMapping: {},
      deptInfoMapping:{}
    }
  },
  created(){
    this.deptInfoMapping = JSON.parse(this.$session.getItem("deptInfoMapping"))
    this.deptIdCodeMapping = JSON.parse(this.$session.getItem("departIdCodeMapping"))
  },
  computed: {
    departmentInfo() {
      try{
        if(this.deptCode){
          return this.deptInfoMapping[this.deptCode]
        }else if(this.deptId){
          return this.deptInfoMapping[this.deptIdCodeMapping[this.deptId]]
        }
        return {}
      }catch(e){
        console.error(e)
        return {}
      }
    }
  }
}
</script>

<template>
  <span v-if="!deptCode && !deptId" class="text-danger">未认领</span>
  <span v-else-if="departmentInfo && departmentInfo.name">{{departmentInfo.name}}</span>
  <span v-else class="text-danger">机构不存在</span>
</template>

<style scoped lang="less">
.text-danger{
  color: #dadada
}

</style>