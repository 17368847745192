<script>
import ReclaimSettleChoose from "@/view/nbi/reclaim/settle/settle-choose.vue";
import ReclaimSettleConfirm from "@/view/nbi/reclaim/settle/settle-confirm.vue";
import ReclaimSettleResult from "@/view/nbi/reclaim/settle/settle-result.vue";

export default {
  name: 'ReclaimSettle',
  components: {ReclaimSettleChoose, ReclaimSettleConfirm, ReclaimSettleResult},
  data() {
    const lenderId = Number(this.$route.params.lenderId) || undefined
    const borrowerId = Number(this.$route.params.borrowerId) || undefined
    const receiverId = Number(this.$route.params.receiverId) || undefined
    const repayAccountId = Number(this.$route.params.repayAccountId) || undefined
    return {
      step: 0,
      lenderId: Number(lenderId) || undefined,
      borrowerId: Number(borrowerId) || undefined,
      receiverId: Number(receiverId) || undefined,
      repayAccountId: Number(repayAccountId) || undefined,
      estimate: undefined,
      payResult: undefined,
    }
  },
  methods: {
    onBack() {
      this.step = 0
    },
    async onEstimate(estimate) {
      this.estimate = {...estimate}
      this.step = 1
    },
    onSettle(result) {
      this.payResult = result
      this.step = 2
    },
    onQuit() {
      this.$router.go(-1)
    }
  }
}
</script>

<template>
  <div style="margin: 0 20px;">
    <a-card style="min-width: 780px; margin: 0 auto" title="清算还款">
      <template v-slot:extra>
        <a-button @click="onQuit">取消清算</a-button>
      </template>
      <div class="steps-wrapper">
        <a-steps :current="step">
          <a-step title="录入">
            <a-icon slot="icon" type="user"/>
          </a-step>
          <a-step title="确认">
            <a-icon slot="icon" type="solution"/>
          </a-step>
          <a-step title="结果">
            <a-icon slot="icon" type="check"/>
          </a-step>
        </a-steps>
      </div>

      <div class="settle-wrapper">
        <div @click="step=0" :class="step===0?'forward':'backward'" v-show="step<2">
          <reclaim-settle-choose :borrower-id="borrowerId"
                                 :receiver-id="receiverId" :repay-account-id="repayAccountId"
                                 @estimate="onEstimate"></reclaim-settle-choose>
        </div>
        <div @click="step=1" :class="step===1?'forward':'backward'" v-show="step<2 && estimate">
          <reclaim-settle-confirm :estimate="estimate"
                                  @back="onBack" @settle="onSettle"></reclaim-settle-confirm>
        </div>
        <reclaim-settle-result v-if="step===2" :result="payResult"
                               @quit="onQuit"></reclaim-settle-result>
      </div>
    </a-card>
  </div>

</template>

<style scoped lang="less">
.steps-wrapper {
  width: 100%;

  .ant-steps {
    max-width: 800px;
    min-width: 400px;
    margin: 0 auto 20px auto;
  }
}

.settle-wrapper {
  justify-content: center;
  display: flex;
  align-items: start;
  gap: 15px;

  > div:not(:first-child) {
    margin-left: -300px;
  }
}

.ant-steps {
  /deep/ .anticon svg {
    font-size: 24px;
  }
}

.forward {
  transform: translate3d(-5px, -5px, 10px);
  box-shadow: 7px 7px 14px 2px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.backward {
  z-index: 0;
  position: relative;
  filter: blur(2px);
  user-select: none;
  cursor: pointer;
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
}

.backward::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  background: rgba(255, 253, 253, 0.2);
}
</style>